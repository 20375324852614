import React, { memo } from "react";
import { connect } from "react-redux";
import DefaultView from "../components/DefaultView";
import EmailLeftbar from "../components/EmailLeftbar";
import FaxCoverForm from "../components/FaxCoverForm";
import { actionGetReceivedMessages } from "../store/actions/inboxAction";

const CreateFaxCover = memo(() => {
  return (
    <div className="page-content">
      <div className="container-fluid">
        {/* start page title */}
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 main_heading">Fax Cover</h4>
              <div className="page-title-right">
                <DefaultView />
              </div>
            </div>
          </div>
        </div>
        {/* end page title */}
        <div className="row">
          <div className="col-12">
            {/* Left sidebar */}
            <EmailLeftbar />
            {/* End Left sidebar */}

            {/* Right Sidebar */}
            <FaxCoverForm />

            {/* end  Right Sidebar Col-9 */}
          </div>
        </div>
      </div>
    </div>
  );
});

const mapStateToProps = (state) => {
  return {
    getReceivedMessagesLoader: state.inbox.getReceivedMessagesLoader,
    receivedMessages: state.inbox.receivedMessages,
  };
};

export default connect(mapStateToProps, {
  actionGetReceivedMessages,
})(CreateFaxCover);
