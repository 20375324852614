import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

const EmailListItem = ({
  isUnread,
  isStarred,
  senderName,
  senderPhone,
  noOfPages,
  date,
  isChecked,
  index,
  handleSelectMessage,
  handleStarred,
  detailId,
  setShowEmailDetailID,
}) => {
  return (
    <li className={isUnread == 0 ? "unread" : "read"}>
      <div className="col-mail col-mail-1">
        <div className="checkbox-wrapper-mail">
          <input
            type="checkbox"
            id={index}
            checked={isChecked}
            //defaultChecked={isChecked}
            onChange={() => handleSelectMessage(index)}
          />
          <label htmlFor={index} className="toggle" />
        </div>
        {isStarred == 1 ? (
          <i
            className="star-toggle fa fa-star text-warning"
            aria-hidden="true"
            style={{ cursor: "pointer" }}
            onClick={() => handleStarred(index, "starRemove")}
          ></i>
        ) : isStarred == 0 ? (
          <i
          className="star-toggle fa fa-star-o"
            aria-hidden="true"
            style={{ cursor: "pointer" }}
            onClick={() => handleStarred(index, "starAdd")}
          ></i>
        ) : (
          ""
        )}
        {/* <Link to={`/read-mail/${detailId}`} className="title"> */}
        <div className="title" onClick={() => setShowEmailDetailID(detailId)}>
          <span className="senderName">{senderName}</span>
          <span className="ms-2">
            <i className="mdi mdi-fax me-1" />
            {senderPhone}
          </span>
        </div>
        {/* </Link> */}
      </div>
      <div className="col-mail col-mail-2">
        {`${noOfPages} Pages`}
        <div className="date" style={{ paddingLeft: 40 }}>
          {moment(date).format("DD MMM - hh:mm A")}
        </div>
      </div>
    </li>
  );
};

export default EmailListItem;
