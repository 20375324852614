import axios from "axios";
import * as actionTypes from "../action";
import { setAuthToken } from "../../utils/helper";
import { toast as message } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const AUTH_BASE_URL = process.env.REACT_APP_AUTH_BASE_URL;
const TWILLIO_BASE_URL = process.env.REACT_APP_TWILLIO_BASE_URL;
const VERIFIED_NUMBER_EMAIL_BASE_URL =
  process.env.REACT_APP_VERIFIED_NUMBER_EMAIL_BASE_URL;

/* export const actionLogin =
  (userData, navigate) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.LOGIN_LOADING, payload: true });
    try {
      let result = await axios.post(`${BASE_URL}/auth/login`, userData);
      dispatch({ type: actionTypes.LOGIN_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        const { access_token } = result.data.data;
        setAuthToken(access_token);
        dispatch({
          type: actionTypes.USER_PROFILE_DATA,
          payload: result.data.data.user,
        });
        message.success(result.data.message, 5);
        navigate && setTimeout(() => navigate(`/`), 1000);
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.response.data.message, 5);
      dispatch({ type: actionTypes.LOGIN_LOADING, payload: false });
    }
  }; */

export const actionDashboard = () => async (dispatch, getState) => {
  console.log("actionDashboard calling!!!");
  dispatch({ type: actionTypes.GET_DASHBORD_DATA_LOADER, payload: true });
  try {
    let res = await axios.get(`${BASE_URL}/user/dashboard`);
    dispatch({ type: actionTypes.GET_DASHBORD_DATA_LOADER, payload: false });
    if (parseInt(res.data.http_status_code) === 200) {
      dispatch({
        type: actionTypes.DASHBOARD_DATA,
        payload: res.data.result.data,
      });
    }
  } catch (error) {
    console.log(error.response.data.message, 5);
    dispatch({ type: actionTypes.GET_DASHBORD_DATA_LOADER, payload: false });
  }
};

export const actionFaxStats =
  (filter = "1M") =>
  async (dispatch, getState) => {
    console.log("actionFaxStats calling!!!");
    dispatch({ type: actionTypes.GET_FAX_STATS_LOADER, payload: true });
    try {
      const res = await axios.get(`${BASE_URL}/user/fax-stats`, {
        params: { filter },
      });
      dispatch({ type: actionTypes.GET_FAX_STATS_LOADER, payload: false });
      if (parseInt(res.status) === 200) {
        dispatch({
          type: actionTypes.FAX_STATS_DATA,
          payload: res.data.result.data,
        });
      }
    } catch (error) {
      console.log(error.response?.data?.message || error.message, 5);
      dispatch({ type: actionTypes.GET_FAX_STATS_LOADER, payload: false });
    }
  };

export const actionMe = () => async (dispatch, getState) => {
  console.log("actionMe calling!!!");
  dispatch({ type: actionTypes.PROFILE_DATA_LOADER, payload: true });
  try {
    let res = await axios.get(`${BASE_URL}/me`);
    dispatch({ type: actionTypes.PROFILE_DATA_LOADER, payload: false });
    console.log({ res });
    if (parseInt(res.data.http_status_code) === 200) {
      dispatch({
        type: actionTypes.PROFILE_DATA,
        payload: res.data.result,
      });
    } else {
      // TODO: need to redirect to login service
      console.log(res.data.error.error_message);
      window.location.href = process.env.REACT_APP_LOGIN_URL;
    }
  } catch (error) {
    console.log(error.response.data.message, 5);
    dispatch({ type: actionTypes.PROFILE_DATA_LOADER, payload: false });
    window.location.href = process.env.REACT_APP_LOGIN_URL;
  }
};
export const logoutUser = () => (dispatch) => {
  // Remove Token & UserId from localStorage
  localStorage.removeItem("authToken");
  localStorage.removeItem("authUserId");
  setAuthToken(false);
  // Set current user to null
  dispatch({ type: actionTypes.PROFILE_DATA, payload: null });
  window.location.href = process.env.REACT_APP_LOGIN_URL;
};

export const actionUpdateSetting = (data) => async (dispatch, getState) => {
  console.log("actionUpdateSetting calling");
  dispatch({ type: actionTypes.UPDATE_USER_SETTING_LOADER, payload: true });

  try {
    let res = await axios.patch(`${BASE_URL}/user/update_settings`, data);
    dispatch({
      type: actionTypes.UPDATE_USER_SETTING_LOADER,
      payload: false,
    });
    if (parseInt(res.data.http_status_code) == 201) {
      console.log(res.data.result.data.user_info[0], "actionUpdateSetting");
      dispatch({
        type: actionTypes.UPDATE_USER_SETTING_DATA,
        payload: res.data.result.data.user_info[0],
      });
      message.success("Your Setting was updated successfully");
    } else {
      console.log(res.data.error.error_message);
      message.error(res.data.error.error_message);
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      message.error(error.response.data.message);
    } else {
      message.error("An error occurred");
    }
    dispatch({
      type: actionTypes.UPDATE_USER_SETTING_LOADER,
      payload: false,
    });
  }
};

export const actionUpdateUserProfile =
  (userId, userData, navigate) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.UPDATE_USER_PROFILE_LOADING, payload: true });
    try {
      let result = await axios.patch(
        `${AUTH_BASE_URL}/user/update_info/${userId}`,
        userData
      );
      dispatch({
        type: actionTypes.UPDATE_USER_PROFILE_LOADING,
        payload: false,
      });
      if (parseInt(result.data.http_status_code) === 200) {
        dispatch({
          type: actionTypes.UPDATE_USER_PROFILE_DATA,
          payload: userData,
        });
        message.success(result.data.result.message, 5);
        dispatch(actionMe());
      } else message.error(result.data.result.message, 5);
    } catch (error) {
      message.error(error.response.data.message, 5);
      dispatch({
        type: actionTypes.UPDATE_USER_PROFILE_LOADING,
        payload: false,
      });
    }
  };

export const actionUploadProfilePic =
  (data, userauthId, userauthToken) => async (dispatch, getState) => {
    console.log("actionUploadProfilePic");
    dispatch({ type: actionTypes.UPLOAD_PROFILE_PIC_LOADER, payload: true });
    try {
      let res = await axios.patch(
        `${AUTH_BASE_URL}/user/update_profile_pic/${userauthId}/${userauthToken}`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      dispatch({ type: actionTypes.UPLOAD_PROFILE_PIC_LOADER, payload: false });
      if (parseInt(res.data.http_status_code) === 200) {
        console.log("res.data.result", res.data.result);
        message.success("Upload Sucessfully");
        return res.data.result;
      } else {
        console.log(res.data.error.error_message);
        message.error(res.data.error.error_message);
      }
    } catch (error) {
      console.log(error.response.data.message);
      message.error(error.response.data.message);
      dispatch({ type: actionTypes.UPLOAD_PROFILE_PIC_LOADER, payload: false });
      throw error;
    }
  };

export const actionGetMyNumbers = (userId) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_MY_NUMBERS_LOADER, payload: true });
  try {
    let res = await axios.get(
      `${TWILLIO_BASE_URL}/numbers/get_number/${userId}`
    );
    dispatch({ type: actionTypes.GET_MY_NUMBERS_LOADER, payload: false });
    dispatch({ type: actionTypes.GET_MY_NUMBERS, payload: res.data });
  } catch (error) {
    message.error("Someting went wrong! Please try again later.");
    dispatch({ type: actionTypes.GET_MY_NUMBERS_LOADER, payload: false });
  }
};

export const actionGetVerifiedPhoneFaxNumbers =
  (userId) => async (dispatch, getState) => {
    dispatch({
      type: actionTypes.GET_VERIFIED_PHONE_FAX_NUMBERS_LOADER,
      payload: true,
    });
    try {
      let res = await axios.get(
        `${VERIFIED_NUMBER_EMAIL_BASE_URL}/verified-number/get-verified-phone-fax-numbers/${userId}`
      );
      console.log({ res });
      dispatch({
        type: actionTypes.GET_VERIFIED_PHONE_FAX_NUMBERS_LOADER,
        payload: false,
      });
      dispatch({
        type: actionTypes.GET_VERIFIED_PHONE_FAX_NUMBERS,
        payload: res.data.result.data.number,
      });
    } catch (error) {
      message.error("Someting went wrong! Please try again later.");
      dispatch({
        type: actionTypes.GET_VERIFIED_PHONE_FAX_NUMBERS_LOADER,
        payload: false,
      });
    }
  };

export const actionEnableNumber = (number) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.ENABLE_NUMBER_LOADER, payload: true });
  try {
    const res = await axios.patch(`${TWILLIO_BASE_URL}/numbers/enable_number`, {
      number,
    });
    dispatch({ type: actionTypes.ENABLE_NUMBER_LOADER, payload: false });
    // TODO: Handle local redux data
    if (res.data.status == "ok") {
      dispatch({ type: actionTypes.ENABLE_NUMBER_SUCCESS, payload: number });
      // message.success("Number enabled successfully.");
    }
  } catch (error) {
    message.error("Something went wrong! Please try again later.");
    dispatch({ type: actionTypes.ENABLE_NUMBER_LOADER, payload: false });
  }
};

export const actionDisableNumber = (number) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.DISABLE_NUMBER_LOADER, payload: true });
  try {
    const res = await axios.patch(
      `${TWILLIO_BASE_URL}/numbers/disable_number`,
      { number }
    );
    dispatch({ type: actionTypes.DISABLE_NUMBER_LOADER, payload: false });
    // TODO: Handle local redux data
    dispatch({ type: actionTypes.DISABLE_NUMBER_SUCCESS, payload: number });
    // message.success("Number disabled successfully.");
  } catch (error) {
    message.error("Something went wrong! Please try again later.");
    dispatch({ type: actionTypes.DISABLE_NUMBER_LOADER, payload: false });
  }
};

export const actionCancelNumber = (number) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.CANCEL_NUMBER_LOADER, payload: true });
  try {
    const res = await axios.patch(`${TWILLIO_BASE_URL}/numbers/cancel_number`, {
      number,
    });
    dispatch({ type: actionTypes.CANCEL_NUMBER_LOADER, payload: false });
    // TODO: handle local redux data
    dispatch({ type: actionTypes.CANCEL_NUMBER_SUCCESS, payload: number });
    message.success("Number cancelled successfully.");
  } catch (error) {
    message.error("Something went wrong! Please try again later.");
    dispatch({ type: actionTypes.CANCEL_NUMBER_LOADER, payload: false });
  }
};

export const actionGetNumberRoute = (faxNumberId) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_NUMBER_ROUTE_LOADER, payload: true });
  try {
    const res = await axios.get(
      `${TWILLIO_BASE_URL}/numbers/number-route/${faxNumberId}`
    );
    dispatch({ type: actionTypes.GET_NUMBER_ROUTE_LOADER, payload: false });
    dispatch({ type: actionTypes.GET_NUMBER_ROUTE, payload: res.data.data });
  } catch (error) {
    // message.error("Something went wrong! Please try again later.");
    dispatch({ type: actionTypes.GET_NUMBER_ROUTE_LOADER, payload: false });
  }
};

export const actionDeleteVerifiedNumber =
  (userId, number) => async (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_VERIFIED_NUMBER_LOADER,
      payload: true,
    });
    try {
      const res = await axios.post(
        `${VERIFIED_NUMBER_EMAIL_BASE_URL}/verified-number/delete-verified-number`,
        {
          user_ID: userId,
          number: number,
        }
      );
      dispatch({
        type: actionTypes.DELETE_VERIFIED_NUMBER_LOADER,
        payload: false,
      });
      dispatch({
        type: actionTypes.DELETE_VERIFIED_NUMBER,
        payload: number,
      });
      message.success("Number deleted successfully!");
    } catch (error) {
      // handle error
      dispatch({
        type: actionTypes.DELETE_VERIFIED_NUMBER_LOADER,
        payload: false,
      });
      message.error("Something went wrong! Please try again later.");
    }
  };

export const actionCreateNumberRouteConfig = (request) => async (dispatch) => {
  dispatch({
    type: actionTypes.CREATE_NUMBER_ROUTE_CONFIG_LOADER,
    payload: true,
  });
  try {
    const res = await axios.post(
      `${TWILLIO_BASE_URL}/numbers/number-route-config`,
      request
    );
    dispatch({
      type: actionTypes.CREATE_NUMBER_ROUTE_CONFIG_LOADER,
      payload: false,
    });
    // Dispatch any other actions if needed, such as updating the state with the response data
    message.success("Number route configuration created successfully");
  } catch (error) {
    dispatch({
      type: actionTypes.CREATE_NUMBER_ROUTE_CONFIG_LOADER,
      payload: false,
    });
    message.error(
      "Failed to create number route configuration. Please try again later."
    );
    // Handle errors here, such as displaying error messages or logging errors
    console.error("Error creating number route configuration:", error);
  }
};

export const actionAddVerifiedNumber =
  (userId, type, number, nextTab) => async (dispatch) => {
    dispatch({ type: actionTypes.ADD_VERIFIED_NUMBER_LOADER, payload: true });
    try {
      const res = await axios.post(
        `${VERIFIED_NUMBER_EMAIL_BASE_URL}/verified-number/add-number`,
        {
          userId: userId,
          type: type,
          number: number,
        }
      );
      dispatch({
        type: actionTypes.ADD_VERIFIED_NUMBER_LOADER,
        payload: false,
      });
      console.log(res.data);
      if (res.data.result.data.msgs.status == "ok") {
        dispatch({ type: actionTypes.ADD_VERIFIED_NUMBER, payload: res.data });
        nextTab && nextTab();
        nextTab && message.success("OTP Successfully sent to your number.");
      } else {
        message.success(res.data.result.data["Error Message"]);
      }
    } catch (error) {
      // handle error
      dispatch({
        type: actionTypes.ADD_VERIFIED_NUMBER_LOADER,
        payload: false,
      });
      message.error(error.response.data.result.data["Error Message"]);
    }
  };

export const actionVerifyNumber =
  (userId, number, authKey, nextTab) => async (dispatch) => {
    dispatch({ type: actionTypes.VERIFY_NUMBER_LOADER, payload: true });
    let res = "";
    try {
      res = await axios.post(
        `${VERIFIED_NUMBER_EMAIL_BASE_URL}/verified-number/verify-number`,
        {
          userId: userId,
          number: number,
          auth_key: authKey,
        }
      );
      dispatch({ type: actionTypes.VERIFY_NUMBER_LOADER, payload: false });
      if (res.data.result.data.msgs.status == "ok") {
        dispatch({ type: actionTypes.VERIFY_NUMBER, payload: res.data });

        nextTab && nextTab();
      }
    } catch (error) {
      // handle error
      dispatch({ type: actionTypes.VERIFY_NUMBER_LOADER, payload: false });
      message.error(error.response.data.result.data["Error Message"]);
    }
  };

export const actionGetVerifiedEmails =
  (userId) => async (dispatch, getState) => {
    console.log({ VERIFIED_NUMBER_EMAIL_BASE_URL });
    dispatch({
      type: actionTypes.GET_VERIFIED_EMAIL_LOADER,
      payload: true,
    });
    try {
      let res = await axios.get(
        `${VERIFIED_NUMBER_EMAIL_BASE_URL}/verified-email/get-verified-emails/${userId}`
      );
      console.log({ res });
      dispatch({
        type: actionTypes.GET_VERIFIED_EMAIL_LOADER,
        payload: false,
      });
      dispatch({
        type: actionTypes.GET_VERIFIED_EMAIL,
        payload: res.data.result.data.emailsData,
      });
    } catch (error) {
      message.error("Someting went wrong! Please try again later.");
      dispatch({
        type: actionTypes.GET_VERIFIED_EMAIL_LOADER,
        payload: false,
      });
    }
  };

export const actionDeleteVerifiedEmail =
  (userId, email) => async (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_VERIFIED_EMAIL_LOADER,
      payload: true,
    });
    try {
      const res = await axios.post(
        `${VERIFIED_NUMBER_EMAIL_BASE_URL}/verified-email/delete-verified-email`,
        {
          user_ID: userId,
          email: email,
        }
      );
      dispatch({
        type: actionTypes.DELETE_VERIFIED_EMAIL_LOADER,
        payload: false,
      });
      dispatch({
        type: actionTypes.DELETE_VERIFIED_EMAIL,
        payload: email,
      });
      message.success("Email deleted successfully!");
    } catch (error) {
      // handle error
      dispatch({
        type: actionTypes.DELETE_VERIFIED_EMAIL_LOADER,
        payload: false,
      });
      message.error("Something went wrong! Please try again later.");
    }
  };

export const actionAddVerifiedEmail =
  (userId, email, nextTab) => async (dispatch) => {
    dispatch({ type: actionTypes.ADD_VERIFIED_EMAIL_LOADER, payload: true });
    try {
      const res = await axios.post(
        `${VERIFIED_NUMBER_EMAIL_BASE_URL}/verified-email/add-email`,
        {
          userId: userId,
          email,
        }
      );
      dispatch({
        type: actionTypes.ADD_VERIFIED_EMAIL_LOADER,
        payload: false,
      });
      console.log(res.data);
      if (res.data.result.data.msgs.status == "ok") {
        dispatch({ type: actionTypes.ADD_VERIFIED_EMAIL, payload: res.data });
        nextTab && nextTab();
        nextTab && message.success("OTP Successfully sent to your email.");
      } else {
        message.success(res.data.result.data["Error Message"]);
      }
    } catch (error) {
      // handle error
      dispatch({
        type: actionTypes.ADD_VERIFIED_EMAIL_LOADER,
        payload: false,
      });
      message.error(error.response.data.result.data["Error Message"]);
    }
  };

export const actionVerifyEmail =
  (userId, email, authKey, nextTab) => async (dispatch) => {
    dispatch({ type: actionTypes.VERIFY_EMAIL_LOADER, payload: true });
    let res = "";
    try {
      res = await axios.post(
        `${VERIFIED_NUMBER_EMAIL_BASE_URL}/verified-email/verify-email`,
        {
          userId: userId,
          email: email,
          auth_key: authKey,
        }
      );
      dispatch({ type: actionTypes.VERIFY_EMAIL_LOADER, payload: false });
      if (res.data.result.data.msgs.status == "ok") {
        dispatch({ type: actionTypes.VERIFY_EMAIL, payload: res.data });

        nextTab && nextTab();
      }
    } catch (error) {
      // handle error
      dispatch({ type: actionTypes.VERIFY_EMAIL_LOADER, payload: false });
      message.error(error.response.data.result.data["Error Message"]);
    }
  };

// Contacts Actions
export const actionGetContacts = () => async (dispatch, getState) => {
  console.log("actionGetContacts ...");
  dispatch({
    type: actionTypes.GET_CONTACTS_LOADER,
    payload: true,
  });
  try {
    let res = await axios.get(`${BASE_URL}/contact/`);
    console.log({ res });
    dispatch({
      type: actionTypes.GET_CONTACTS_LOADER,
      payload: false,
    });
    dispatch({
      type: actionTypes.GET_CONTACTS,
      payload: res.data.result.data.contacts,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: actionTypes.GET_CONTACTS_LOADER,
      payload: false,
    });
  }
};

export const actionUpdateContact =
  (contactId, contactData, setIsEditing) => async (dispatch, getState) => {
    dispatch({
      type: actionTypes.UPDATE_CONTACT_LOADER,
      payload: contactId,
    });

    try {
      const res = await axios.put(
        `${BASE_URL}/contact/${contactId}`,
        contactData
      );
      dispatch({
        type: actionTypes.UPDATE_CONTACT_LOADER,
        payload: false,
      });
      setIsEditing && setIsEditing(false);

      dispatch({
        type: actionTypes.UPDATE_CONTACT_SUCCESS,
        payload: res.data.result.data.contact,
      });
    } catch (error) {
      // Handle error case
      setIsEditing && setIsEditing(false);
      console.log(error);
      dispatch({
        type: actionTypes.UPDATE_CONTACT_LOADER,
        payload: false,
      });
    }
  };

export const actionDeleteContact =
  (contactId) => async (dispatch, getState) => {
    dispatch({
      type: actionTypes.DELETE_CONTACT_LOADER,
      payload: contactId,
    });

    try {
      // Sending DELETE request
      await axios.delete(`${BASE_URL}/contact/${contactId}`);

      // Dispatch success action
      dispatch({
        type: actionTypes.DELETE_CONTACT_SUCCESS,
        payload: contactId,
      });

      // Turn off the loader in the success case
      dispatch({
        type: actionTypes.DELETE_CONTACT_LOADER,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: actionTypes.DELETE_CONTACT_LOADER,
        payload: false,
      });
    }
  };

export const actionGetList = (authToken) => async (dispatch, getState) => {
  dispatch({
    type: actionTypes.GET_LIST_LOADER,
    payload: true,
  });

  try {
    // Sending GET request to fetch the list
    const response = await axios.get(`${BASE_URL}/list`);

    // Dispatch the success action with the received data
    dispatch({
      type: actionTypes.GET_LIST_SUCCESS,
      payload: response.data.result.data,
    });

    // Turn off the loader after success
    dispatch({
      type: actionTypes.GET_LIST_LOADER,
      payload: false,
    });
  } catch (error) {
    // Turn off the loader in case of error
    dispatch({
      type: actionTypes.GET_LIST_LOADER,
      payload: false,
    });
  }
};

export const actionCreateContact =
  (contactData, setFormData) => async (dispatch, getState) => {
    dispatch({
      type: actionTypes.CREATE_CONTACT_LOADER,
      payload: true,
    });

    try {
      // Sending POST request to create a new contact
      const response = await axios.post(`${BASE_URL}/contact/`, contactData);

      // Dispatch success action with the received data
      dispatch({
        type: actionTypes.CREATE_CONTACT_SUCCESS,
        payload: response.data.result.data.contact,
      });

      dispatch({
        type: actionTypes.CREATE_CONTACT_LOADER,
        payload: false,
      });

      setFormData &&
        setFormData({
          contactName: "",
          contactNumber: "",
          contactEmail: "",
          list_id: "",
        });

      // Notify the user on success
      message.success("Contact created successfully!");
    } catch (error) {
      console.log(error);
      dispatch({
        type: actionTypes.CREATE_CONTACT_LOADER,
        payload: false,
      });
    }
  };

export const actionCreateList = (requestData) => async (dispatch) => {
  dispatch({
    type: actionTypes.CREATE_LIST_LOADER,
    payload: true,
  });

  try {
    const response = await axios.post(`${BASE_URL}/list/create`, requestData);

    // Dispatch success action with the created list data
    dispatch({
      type: actionTypes.CREATE_LIST_LOADER,
      payload: false,
    });

    if (parseInt(response.data.http_status_code) === 200) {
      // Notify the user of success
      message.success(`List "${requestData.list_name}" created successfully!`);
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: actionTypes.CREATE_LIST_LOADER,
      payload: false,
    });
  }
};

export const actionUpdateList = (requestData, listId) => async (dispatch) => {
  // Start loader before making the API call
  dispatch({
    type: actionTypes.UPDATE_LIST_LOADER,
    payload: true,
  });

  try {
    // Send the PUT request to update the list status
    const response = await axios.put(
      `${BASE_URL}/list/update/${listId}`,
      requestData
    );

    // Turn off loader after success
    dispatch({
      type: actionTypes.UPDATE_LIST_LOADER,
      payload: false,
    });
  } catch (error) {
    console.log({ error });
    // Turn off loader after error
    dispatch({
      type: actionTypes.UPDATE_LIST_LOADER,
      payload: false,
    });
  }
};

export const actionDeleteList = (listId) => async (dispatch) => {
  // Start loader before making the API call
  dispatch({
    type: actionTypes.DELETE_LIST_LOADER,
    payload: listId,
  });

  try {
    // Send the DELETE request to remove the list
    const response = await axios.delete(`${BASE_URL}/list/delete/${listId}`);

    // Turn off loader after success
    dispatch({
      type: actionTypes.DELETE_LIST_LOADER,
      payload: false,
    });

    // Dispatch success action with deleted list ID or confirmation
    dispatch({
      type: actionTypes.DELETE_LIST_SUCCESS,
      payload: listId,
    });
  } catch (error) {
    console.log({ error });

    // Turn off loader after error
    dispatch({
      type: actionTypes.DELETE_LIST_LOADER,
      payload: false,
    });
  }
};
