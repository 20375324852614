import React from "react";

function DeviceCallReportTableRow({
  index,
  callid,
  from,
  to,
  datetime,
  pages,
  duration,
  direction,
}) {
  return (
    <tr className={index % 2 === 0 ? "even" : "odd"}>
      <td className="dtr-control sorting_1" tabIndex={0}>
        {index + 1}
      </td>
      <td>{callid}</td>
      <td>{from}</td>
      <td>{to}</td>
      <td>{datetime}</td>
      <td className="text-center">{pages}</td>
      <td className="text-center">{duration}</td>
      <td className="text-center">{direction}</td>
    </tr>
  );
}

export default DeviceCallReportTableRow;
