import React from "react";
import { Link } from "react-router-dom";
import DefaultView from "../components/DefaultView";

function DeviceConfiguration() {
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-top justify-content-between">
                <h4 className="mb-sm-0 main_heading">Device Configuration</h4>
                <div className="page-title-right">
                  <DefaultView />
                  <div className="d-flex justify-content-end pt-20">
                    <div className="right-side-btns">
                      <div className="btn-group1">
                        <button
                          type="button"
                          className="btn btn-primary waves-light waves-effect "
                          style={{ marginRight: 5 }}
                        >
                          Save
                        </button>
                        <Link
                          to="lists.html"
                          className="btn btn-primary waves-light waves-effect back_button"
                        >
                          Back
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end page title */}
          <div className="row">
            <div className="col-12">
              {/* Right Sidebar */}
              <div className="mb-3 compose-section">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Display Info</h4>
                    <p className="card-title-desc">Display Info</p>
                  </div>
                  <div className="card-body">
                    <div className="mb-3 compose-section">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="form-label"
                            >
                              Number of channels
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Number of channels"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="example-date-input"
                              className="form-label"
                            >
                              Device Name
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Device Name"
                              id="example-date-input"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <div className="row">
                              <div className="col-6">
                                <label
                                  htmlFor="example-text-input"
                                  className="form-label"
                                >
                                  Number of Incoming Calls
                                </label>
                              </div>
                              <div className="col-6">
                                <p className="d-flex justify-content-end mb-0">
                                  100
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-6">
                              <label
                                htmlFor="example-text-input"
                                className="form-label"
                              >
                                Number of Outgoing Calls
                              </label>
                            </div>
                            <div className="col-6">
                              <p className="d-flex justify-content-end mb-0">
                                100
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-6">
                              <label
                                htmlFor="example-text-input"
                                className="form-label"
                              >
                                Show SIP URI
                              </label>
                            </div>
                            <div className="col-6">
                              <p className="d-flex justify-content-end mb-0">
                                sip:bob.smith@nokia.com
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-6">
                              <label
                                htmlFor="example-text-input"
                                className="form-label"
                              >
                                Status
                              </label>
                            </div>
                            <div className="col-6">
                              <p className="d-flex justify-content-end mb-0">
                                ACTIVE
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* card */}
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Incoming form</h4>
                    <p className="card-title-desc">Incoming form</p>
                  </div>
                  <div className="card-body">
                    <div className="mb-3 compose-section">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label>Routing options: </label>
                            <select className="form-select">
                              <option>Select Route</option>
                              <option>Email</option>
                              <option>Dialout</option>
                              <option>Map Phone Number</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label>Select Phone Number: </label>
                            <select
                              id="query_task"
                              className="form-control choices-multiple"
                              multiple=""
                            ></select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* card */}
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Outgoing form</h4>
                    <p className="card-title-desc">Outgoing Set Caller form </p>
                  </div>
                  <div className="card-body">
                    <div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="form-label"
                            >
                              Set CallerID
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Set CallerID"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="form-label"
                            >
                              Set Header | Cover
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Set Header | Cover"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex justify-content-end">
                      <div className="right-side-btns">
                        <div className="btn-group1">
                          <button
                            type="button"
                            className="btn btn-primary waves-light waves-effect"
                            style={{ marginRight: 5 }}
                          >
                            Save
                          </button>
                          <Link
                            to="lists.html"
                            className="btn btn-primary waves-light waves-effect back_button"
                          >
                            Back
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                  {/* end col */}
                </div>{" "}
                {/* end row */}
              </div>
              {/* end Col */}
            </div>
            {/* end row */}
          </div>
          {/* container-fluid */}
        </div>
        {/* End Page-content */}
      </div>{" "}
      {/* container-fluid */}
    </div>
  );
}

export default DeviceConfiguration;
