import * as actionTypes from "../action";

const initialState = {
  loginLoader: false,
  profileDataLoader: false,
  user: null,
  userSettingLoader: false,
  userUpdateLoader: false,
  profilePicUploadLoader: false,
  getMyNumbersLoader: false,
  enableNumberLoader: false,
  disableNumberLoader: false,
  cancelNumberLoader: false,
  getNumberRouteConfigLoader: false,
  createNumberRouteConfigLoader: false,

  myNumbers: [], // Fax Numbers bought from twillio
  numberRouteConfig: null,
  getVerifiedPhoneFaxNumbersLoader: false,
  verifiedPhoneFaxNumbers: [],
  deleteVerifiedNumberLoader: false,
  addVerifiedNumberLoader: false,
  verifyNumberLoader: false,
  getVerifiedEmailLoader: false,
  deleteVerifiedEmail: false,
  verifiedEmails: [],
  addVerifiedEmailLoader: false,
  getFromNumberLoader: false,
  fromNumbers: [],
  getDashboardLoader: false,
  dashboard: "",
  getFaxStatsLoader: false,
  faxStats: "",
  getContactLoader: false,
  contacts: "",
  updateContactLoader: false,
  deleteContactLoader: false,
  getListLoader: false,
  list: "",
  createContactLoader: false,
  updateListLoader: false,
  deleteListLoader: false,
  createListLoader: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_LOADING:
      return { ...state, loginLoader: action.payload };

    case actionTypes.PROFILE_DATA_LOADER:
      return { ...state, profileDataLoader: action.payload };

    case actionTypes.UPLOAD_PROFILE_PIC_LOADER:
      return { ...state, profilePicUploadLoader: action.payload };

    case actionTypes.PROFILE_DATA:
      return { ...state, user: action.payload };

    case actionTypes.UPDATE_USER_SETTING_LOADER:
      return { ...state, userSettingLoader: action.payload };

    case actionTypes.UPDATE_USER_SETTING_DATA:
      return {
        ...state,
        user: { ...state.user, user_settings: action.payload },
      };

    case actionTypes.UPDATE_USER_PROFILE_LOADING:
      return { ...state, userUpdateLoader: action.payload };

    case actionTypes.GET_MY_NUMBERS_LOADER:
      return { ...state, getMyNumbersLoader: action.payload };

    case actionTypes.GET_MY_NUMBERS:
      return { ...state, myNumbers: action.payload };

    case actionTypes.ENABLE_NUMBER_LOADER:
      return { ...state, enableNumberLoader: action.payload };

    case actionTypes.ENABLE_NUMBER_SUCCESS:
      return {
        ...state,
        myNumbers: state.myNumbers.map((n) =>
          n.number == action.payload ? { ...n, number_status: "enabled" } : n
        ),
      };

    case actionTypes.DISABLE_NUMBER_LOADER:
      return { ...state, disableNumberLoader: action.payload };

    case actionTypes.CANCEL_NUMBER_LOADER:
      return { ...state, cancelNumberLoader: action.payload };

    case actionTypes.GET_NUMBER_ROUTE_LOADER:
      return { ...state, getNumberRouteConfigLoader: action.payload };

    case actionTypes.DISABLE_NUMBER_SUCCESS:
      return {
        ...state,
        myNumbers: state.myNumbers.map((n) =>
          n.number == action.payload ? { ...n, number_status: "disabled" } : n
        ),
      };
    case actionTypes.CANCEL_NUMBER_SUCCESS:
      return {
        ...state,
        myNumbers: state.myNumbers.filter(
          (n) => n.number != action.payload && n
        ),
      };

    case actionTypes.UPDATE_USER_PROFILE_DATA:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };

    case actionTypes.GET_NUMBER_ROUTE:
      return { ...state, numberRouteConfig: action.payload };

    case actionTypes.CREATE_NUMBER_ROUTE_CONFIG_LOADER:
      return { ...state, createNumberRouteConfigLoader: action.payload };

    case actionTypes.GET_VERIFIED_PHONE_FAX_NUMBERS_LOADER:
      return { ...state, getVerifiedPhoneFaxNumbersLoader: action.payload };

    case actionTypes.GET_VERIFIED_PHONE_FAX_NUMBERS:
      return { ...state, verifiedPhoneFaxNumbers: action.payload };

    case actionTypes.DELETE_VERIFIED_NUMBER_LOADER:
      return { ...state, deleteVerifiedNumberLoader: action.payload };

    case actionTypes.ADD_VERIFIED_NUMBER_LOADER:
      return { ...state, addVerifiedNumberLoader: action.payload };

    case actionTypes.VERIFY_NUMBER_LOADER:
      return { ...state, verifyNumberLoader: action.payload };

    case actionTypes.DELETE_VERIFIED_NUMBER:
      return {
        ...state,
        verifiedPhoneFaxNumbers: state.verifiedPhoneFaxNumbers.filter(
          (n) => n.number != action.payload && n
        ),
      };
    case actionTypes.DELETE_VERIFIED_EMAIL:
      return {
        ...state,
        verifiedEmails: state.verifiedEmails.filter(
          (n) => n.email != action.payload && n
        ),
      };
    case actionTypes.GET_VERIFIED_EMAIL_LOADER:
      return { ...state, getVerifiedEmailLoader: action.payload };

    case actionTypes.GET_VERIFIED_EMAIL:
      return { ...state, verifiedEmails: action.payload };

    case actionTypes.ADD_VERIFIED_EMAIL_LOADER:
      return { ...state, addVerifiedEmailLoader: action.payload };

    case actionTypes.VERIFY_EMAIL_LOADER:
      return { ...state, verifyEmailLoader: action.payload };

    case actionTypes.GET_FROM_NUMBERS_LOADER:
      return { ...state, getFromNumberLoader: action.payload };

    case actionTypes.GET_FROM_NUMBERS_DATA:
      return { ...state, fromNumbers: action.payload };

    case actionTypes.GET_DASHBORD_DATA_LOADER:
      return { ...state, getDashboardLoader: action.payload };

    case actionTypes.DASHBOARD_DATA:
      return { ...state, dashboard: action.payload };

    case actionTypes.GET_FAX_STATS_LOADER:
      return { ...state, getFaxStatsLoader: action.payload };

    case actionTypes.FAX_STATS_DATA:
      return { ...state, faxStats: action.payload };

    case actionTypes.GET_CONTACTS_LOADER:
      return { ...state, getContactLoader: action.payload };

    case actionTypes.GET_CONTACTS:
      return { ...state, contacts: action.payload };

    case actionTypes.UPDATE_CONTACT_LOADER:
      return { ...state, updateContactLoader: action.payload };

    case actionTypes.UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: state.contacts.map((c) =>
          c.contact_id == action.payload.contact_id ? action.payload : c
        ),
      };

    case actionTypes.DELETE_CONTACT_LOADER:
      return { ...state, deleteContactLoader: action.payload };

    case actionTypes.DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: state.contacts.filter(
          (c) => c.contact_id != action.payload && c
        ),
      };

    case actionTypes.GET_LIST_LOADER:
      return { ...state, getListLoader: action.payload };

    case actionTypes.GET_LIST_SUCCESS:
      return { ...state, list: action.payload };

    case actionTypes.CREATE_CONTACT_LOADER:
      return { ...state, createContactLoader: action.payload };

    case actionTypes.CREATE_CONTACT_SUCCESS:
      return { ...state, contacts: [...state.contacts, action.payload] };

    case actionTypes.UPDATE_LIST_LOADER:
      return { ...state, updateListLoader: action.payload };

    case actionTypes.DELETE_LIST_LOADER:
      return { ...state, deleteListLoader: action.payload };

    case actionTypes.DELETE_LIST_SUCCESS:
      return {
        ...state,
        list: state.list.filter(
          (ldata) => parseInt(ldata.list_id) !== parseInt(action.payload)
        ),
      };

    case actionTypes.CREATE_LIST_LOADER:
      return { ...state, createListLoader: action.payload };

    default:
      return state;
  }
};
export default authReducer;
