import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import DefaultView from "../components/DefaultView";
import {
  actionGetNumberRoute,
  actionCreateNumberRouteConfig,
  actionGetVerifiedPhoneFaxNumbers,
  actionGetVerifiedEmails,
} from "../store/actions/authAction";
import { connect } from "react-redux";

function PhoneListPage({
  actionGetNumberRoute,
  numberRouteConfig,
  getNumberRouteConfigLoader,
  actionCreateNumberRouteConfig,
  createNumberRouteConfigLoader,
  actionGetVerifiedPhoneFaxNumbers,
  actionGetVerifiedEmails,
  verifiedPhoneFaxNumbers,
  verifiedEmails,
  user,
}) {
  const [selectedRoute, setSelectedRoute] = useState("");
  const [selectedFaxNumber, setSelectedFaxNumber] = useState("");
  const [selectedAlertType, setSelectedAlertType] = useState("EMAIL");
  const [emailAddress, setEmailAddress] = useState("");
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("");
  const [selectedRouteDestination, setSelectedRouteDestination] = useState("");
  const [errors, setErrors] = useState({});
  const { numberId } = useParams();

  const handleRouteDestinationChange = (event) => {
    setSelectedRouteDestination(event.target.value); // Update the selected route when it changes
  };
  const handleRouteChange = (event) => {
    setSelectedRoute(event.target.value); // Update the selected route when it changes
  };

  const handleAlertTypeChange = (event) => {
    setSelectedAlertType(event.target.value); // Update the selected alert type when it changes
  };

  const handleEmailChange = (event) => {
    setEmailAddress(event.target.value); // Update the email address state
  };

  const handlePhoneNumberChange = (event) => {
    setSelectedPhoneNumber(event.target.value); // Update the selected phone number state
  };

  useEffect(() => {
    if (numberId) {
      actionGetNumberRoute(numberId);
    }
  }, [numberId]);

  useEffect(() => {
    console.log({ numberRouteConfig });
    if (numberRouteConfig) {
      setSelectedRoute(numberRouteConfig.route_to);
      setSelectedAlertType(numberRouteConfig.alert_type);
      setSelectedRouteDestination(numberRouteConfig.route_destination);
      if (numberRouteConfig.alert_type == "EMAIL") {
        setSelectedAlertType(numberRouteConfig.alert_to);
        setEmailAddress(emailAddress);
      } else {
        setSelectedPhoneNumber(numberRouteConfig.alert_to);
        setEmailAddress(selectedPhoneNumber);
      }
    }
  }, [numberRouteConfig]);

  const handleSubmit = () => {
    const newErrors = {};

    // Perform validation checks
    if (selectedRoute === "") {
      newErrors.route = "Please select a route.";
    }
    if (selectedRouteDestination === "") {
      newErrors.selectedRouteDestination = "Please select a route destination.";
    }
    if (selectedAlertType === "") {
      newErrors.alertType = "Please select an alert type.";
    }
    if (selectedAlertType === "SMS" && selectedPhoneNumber === "") {
      newErrors.selectedPhoneNumber =
        "Please select a phone number for SMS alert.";
    }
    if (selectedAlertType === "EMAIL" && !emailAddress) {
      newErrors.emailAddress = "Please enter an email address for Email alert.";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    }

    // If all validation checks pass, proceed to submit the form
    const request = {
      userId: user.user_ID,
      route_to: selectedRoute,
      route_destination: selectedRouteDestination,
      fax_number: numberId,
      alert_type: selectedAlertType,
      alert_to:
        selectedAlertType === "SMS" ? selectedPhoneNumber : emailAddress,
    };
    actionCreateNumberRouteConfig(request);
  };

  useEffect(() => {
    if (verifiedPhoneFaxNumbers.length < 1) {
      user && actionGetVerifiedPhoneFaxNumbers(user.user_ID);
    }
  }, [user]);

  useEffect(() => {
    if (verifiedEmails.length < 1) {
      user && actionGetVerifiedEmails(user.user_ID);
    }
  }, [user]);

  const phoneNumbers = ["1234567890", "2345678901", "3456789012"];
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-top justify-content-between">
                <h4 className="mb-sm-0 main_heading">Configure Number Route</h4>
                <div className="page-title-right">
                  <DefaultView />
                </div>
              </div>
            </div>
          </div>
          {/* end page title */}
          <div className="row">
            <div className="col-lg-12">
              {/* start card */}
              <div className="card invoiceCard">
                <div className="card-header">
                  <p className="card-title-desc">
                    You can route your fax number to your desired Email, Fax or
                    Phone and get alert.
                  </p>
                </div>

                <div className="card-body">
                  <div
                    id="datatable-buttons_wrapper"
                    className="dataTables_wrapper dt-bootstrap4 no-footer"
                  >
                    <div className="row">
                      <div className="col-sm-12 col-md-12">
                        <div className="mb-3">
                          <label>Select Route: </label>
                          <select
                            className={`form-select ${
                              errors.route ? "is-invalid" : ""
                            }`}
                            value={selectedRoute}
                            onChange={handleRouteChange}
                          >
                            <option value="">Select Route</option>
                            <option value="email">Route to Email</option>
                            <option value="phone">Route to Phone Number</option>
                            <option value="fax">Route to Fax Number</option>
                            <option value="fax-device">
                              Route to Fax Device
                            </option>
                          </select>
                          {errors.route && (
                            <span className="text-danger">{errors.route}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12">
                        <div className="mb-3">
                          <label>Select Route Destination: </label>
                          <select
                            className={`form-select ${
                              errors.selectedRouteDestination
                                ? "is-invalid"
                                : ""
                            }`}
                            value={selectedRouteDestination}
                            onChange={handleRouteDestinationChange}
                          >
                            <option value="">Select Route Destination</option>
                            {selectedRoute === "email" &&
                              verifiedEmails &&
                              verifiedEmails.length > 0 &&
                              verifiedEmails.map((n, i) => (
                                <option value={n.email} key={i}>
                                  {n.email}
                                </option>
                              ))}
                            {selectedRoute === "phone" &&
                              verifiedPhoneFaxNumbers &&
                              verifiedPhoneFaxNumbers.length > 0 &&
                              verifiedPhoneFaxNumbers
                                .filter((fn) => fn.type == "PHONE")
                                .map((n, i) => (
                                  <option value={n.number} key={i}>
                                    {n.number}
                                  </option>
                                ))}

                            {selectedRoute === "fax" &&
                              verifiedPhoneFaxNumbers &&
                              verifiedPhoneFaxNumbers.length > 0 &&
                              verifiedPhoneFaxNumbers
                                .filter((fn) => fn.type == "FAX")
                                .map((n, i) => (
                                  <option value={n.number} key={i}>
                                    {n.number}
                                  </option>
                                ))}
                          </select>
                          {errors.selectedRouteDestination && (
                            <span className="text-danger">
                              {errors.selectedRouteDestination}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="col-sm-12 col-md-12">
                          <div className="mb-3">
                            <label>Set Alert: </label>
                            <select
                              className={`form-select ${
                                errors.selectedAlertType ? "is-invalid" : ""
                              }`}
                              value={selectedAlertType}
                              onChange={handleAlertTypeChange}
                            >
                              <option value="EMAIL">Email</option>
                              <option value="SMS">SMS</option>
                            </select>
                            {errors.alertType && (
                              <span className="text-danger">
                                {errors.alertType}
                              </span>
                            )}
                          </div>
                          <div className="mb-3">
                            <label>
                              Select{" "}
                              {selectedAlertType === "SMS"
                                ? "Phone Number"
                                : "Email"}
                              :{" "}
                            </label>
                            {selectedAlertType === "SMS" ? (
                              <>
                                <input
                                  type="number"
                                  className={`form-control ${
                                    errors.selectedPhoneNumber
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  placeholder="Enter phone e.g +19854..."
                                  value={selectedPhoneNumber}
                                  onChange={handlePhoneNumberChange}
                                />
                                {errors.selectedPhoneNumber && (
                                  <span className="text-danger">
                                    {errors.selectedPhoneNumber}
                                  </span>
                                )}
                              </>
                            ) : (
                              <>
                                <input
                                  type="email"
                                  className={`form-control ${
                                    errors.emailAddress ? "is-invalid" : ""
                                  }`}
                                  placeholder="Enter email address"
                                  value={emailAddress}
                                  onChange={handleEmailChange}
                                />
                                {errors.emailAddress && (
                                  <span className="text-danger">
                                    {errors.emailAddress}
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end card */}
              <div className="row my-2">
                <div className="col-md-12">
                  <div className="d-flex justify-content-sm-end">
                    <button
                      className="btn btn-primary"
                      onClick={() =>
                        !createNumberRouteConfigLoader && handleSubmit()
                      }
                    >
                      {createNumberRouteConfigLoader ? (
                        <i
                          className="fas fa-spinner fa-spin"
                          title="Loading"
                        ></i>
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* end col */}
          </div>
        </div>
        {/* container-fluid */}
      </div>
      {/* End Page-content */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    getNumberRouteConfigLoader: state.auth.getNumberRouteConfigLoader,
    numberRouteConfig: state.auth.numberRouteConfig,
    createNumberRouteConfigLoader: state.auth.createNumberRouteConfigLoader,
    user: state.auth.user,
    verifiedEmails: state.auth.verifiedEmails,
    verifiedPhoneFaxNumbers: state.auth.verifiedPhoneFaxNumbers,
  };
};

export default connect(mapStateToProps, {
  actionGetNumberRoute,
  actionCreateNumberRouteConfig,
  actionGetVerifiedEmails,
  actionGetVerifiedPhoneFaxNumbers,
})(PhoneListPage);
