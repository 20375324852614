import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { actionUpdateSetting } from "../store/actions/authAction";

const DefaultView = ({ user, actionUpdateSetting, userSettingLoader }) => {
  console.log({ user });

  let updatedSettings = {
    user_view: "DASHBOARD",
    user_language: "EN",
    theme_mode: "LIGHT",
  };

  // Check if user_settings is not empty
  if (
    user &&
    user.user_settings &&
    Object.keys(user.user_settings).length > 0
  ) {
    updatedSettings = {
      user_view: user.user_settings.user_view,
      user_language: user.user_settings.user_language,
      theme_mode: user.user_settings.theme_mode,
    };
  }

  return (
    <div
      className="btn-group"
      role="group"
      aria-label="Basic radio toggle button group"
    >
      <button
        disabled={userSettingLoader}
        className={`btn ${
          user && user.user_settings.user_view === "DASHBOARD"
            ? "btn-success"
            : "btn-outline-secondary"
        }`}
        onClick={() =>
          user &&
          user.user_settings.user_view !== "DASHBOARD" &&
          actionUpdateSetting({ ...updatedSettings, user_view: "DASHBOARD" })
        }
      >
        Default View
      </button>
      <button
        disabled={userSettingLoader}
        className={`btn ${
          user && user.user_settings.user_view === "INBOX"
            ? "btn-success"
            : "btn-outline-secondary"
        }`}
        onClick={() =>
          user &&
          user.user_settings.user_view !== "INBOX" &&
          actionUpdateSetting({ ...updatedSettings, user_view: "INBOX" })
        }
      >
        Inbox View
      </button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    userSettingLoader: state.auth.userSettingLoader,
  };
};

export default connect(mapStateToProps, { actionUpdateSetting })(DefaultView);
