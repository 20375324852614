import moment from "moment";
import React from "react";

function ReferralTableRow({
  referral_ID,
  name,
  email,
  number,
  date,
  status,
  resendReferralLoader,
  onReinviteClick,
}) {
  return (
    <tr>
      <td>{name}</td>
      <td>{email}</td>
      <td>{number}</td>
      <td>
        <button
          type="button"
          className={`btn ${
            status ? "btn-soft-success" : "btn-soft-primary"
          } waves-effect waves-light`}
        >
          {status ? (
            <i className="bx bx-check-double font-size-16 align-middle" />
          ) : (
            <i className="bx bx-time font-size-16 align-middle" />
          )}
        </button>
      </td>
      <td>{moment(date).format("DD MMM YYYY - hh:mm A")}</td>
      <td>
        {resendReferralLoader ? (
          <button
            type="button"
            className="btn btn-primary waves-light waves-effect"
          >
            <i className="fas fa-spinner fa-spin" title="Loading"></i>
          </button>
        ) : (
          <button
            className="btn btn-primary"
            onClick={() => onReinviteClick(referral_ID)}
          >
            Reinvite
          </button>
        )}
      </td>
    </tr>
  );
}

export default ReferralTableRow;
