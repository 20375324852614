import React, { memo, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DefaultView from "../components/DefaultView";
import EmailLeftbar from "../components/EmailLeftbar";
import FaxBlackListTableRow from "../components/FaxBlackListTableRow";
import { actionGetFaxBlacklist } from "./../store/actions/settingAction";
import { actionGetReceivedMessages } from "../store/actions/inboxAction";

const FaxBlackList = memo(
  ({ actionGetFaxBlacklist, faxBlackListLoader, faxBlackListData }) => {
    useEffect(() => {
      actionGetFaxBlacklist();
    }, [actionGetFaxBlacklist]);

    console.log({ faxBlackListData });
    // const dummyData = [
    //   {
    //     id: "1",
    //     name: "Geek",
    //     description: "56056072",
    //     date: "11:00 am 28-Dec-2022",
    //   },
    //   {
    //     id: "2",
    //     name: "Meeting",
    //     description: "56056072",
    //     date: "11:00 am 28-Dec-2022",
    //   },
    // ];
    return (
      <div className="page-content">
        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 main_heading">Fax Cover</h4>
                <div className="page-title-right">
                  <DefaultView />
                </div>
              </div>
              {/*<div className="d-flex justify-content-end mb-3">
                <div className="btn-group float-end">
                  <button
                    type="button"
                    className="btn btn-sm btn-success waves-effect"
                  >
                    <i className="fa fa-chevron-left" />
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-success waves-effect"
                  >
                    <i className="fa fa-chevron-right" />
                  </button>
                </div>
              </div>*/}
            </div>
          </div>
          {/* end page title */}
          <div className="row">
            <div className="col-12">
              {/* Left sidebar */}
              <EmailLeftbar />
              {/* End Left sidebar */}

              {/* Right Sidebar */}
              <div className="email-rightbar mb-3 compose-section">
                <div className="card">
                  <div
                    className="btn-toolbar gap-2 p-3 justify-content-between"
                    role="toolbar"
                  >
                    <div className="right-side-btns">
                      <div className="btn-group">
                        <Link
                          to="/fax-blacklist/create"
                          className="btn btn-primary waves-light waves-effect"
                        >
                          Add
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    {faxBlackListLoader ? (
                      <p className="text-center">Loading... Please wait</p>
                    ) : (
                      <div className="table-responsive">
                        <table className="table table-editable table-nowrap align-middle table-edits">
                          <thead>
                            <tr style={{ cursor: "pointer" }}>
                              <th>Name</th>
                              <th>Number</th>
                              <th>Created At</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!faxBlackListLoader &&
                              faxBlackListData &&
                              faxBlackListData.length > 0 &&
                              faxBlackListData.map((item) => (
                                <FaxBlackListTableRow
                                  key={item.blacklist_ID}
                                  id={item.blacklist_ID}
                                  name={item.blacklist_name}
                                  number={item.blacklist_number}
                                  date={item.blacklist_addTime}
                                />
                              ))}
                            {!faxBlackListLoader &&
                              faxBlackListData &&
                              faxBlackListData.length === 0 && (
                                <tr style={{ height: 100 }}>
                                  <td colSpan="5">
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "100%",
                                      }}
                                    >
                                      <p>Fax Blacklist Data Not Found.</p>
                                    </div>
                                  </td>
                                </tr>
                              )}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
                {/* card */}
              </div>
              {/* end  Right Sidebar Col-9 */}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

const mapStateToProps = (state) => {
  return {
    faxBlackListLoader: state.setting.faxBlackListLoader,
    faxBlackListData: state.setting.faxBlackListData,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {
  actionGetFaxBlacklist,
})(FaxBlackList);
