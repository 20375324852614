import * as actionTypes from "../action";

const initialState = {
  faxCoverData: [],
  faxHeaderData: [],
  faxCoverloder: false,
  faxHeaderloder: false,
  faxCoverDeleteloder: false,
  faxHeaderDeleteloder: 0,
  addfaxCoverloder: false,
  addfaxHeaderloder: false,
  alertLoader: false,
  alertDeleteloder: false,
  updateAlertLoader: false,
  updatefaxHeaderLoader: 0,
  updateFaxBlackListLoader: 0,
  updateAlertData: "",
  createAlertLoader: false,
  faxBlackListLoader: false,
  deleteFaxBlackListLoader: 0,
  addfaxBlackListLoader: false,
  alertData: [],
  faxBlackListData: [],
};

const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FAX_COVER_DATA:
      return { ...state, faxCoverData: action.payload };

    case actionTypes.FAX_COVER_DATA_LOADER:
      return { ...state, faxCoverloder: action.payload };

    case actionTypes.ADD_FAX_COVER_LOADER:
      return { ...state, addfaxCoverloder: action.payload };

    case actionTypes.ADD_FAX_HEADER_LOADER:
      return { ...state, addfaxHeaderloder: action.payload };

    case actionTypes.FAX_COVER_DELETE:
      return {
        ...state,
        faxCoverData: state.faxCoverData.filter(
          (a) => a.cover_ID != action.payload
        ),
      };

    case actionTypes.FAX_COVER_DELETE_LOADER:
      return { ...state, faxCoverDeleteloder: action.payload };

    case actionTypes.FAX_HEADER_DELETE:
      return {
        ...state,
        faxHeaderData: state.faxHeaderData.filter(
          (a) => a.header_ID != action.payload
        ),
      };

    case actionTypes.FAX_HEADER_DELETE_LOADER:
      return { ...state, faxHeaderDeleteloder: action.payload };

    case actionTypes.FAX_HEADER_DATA:
      return { ...state, faxHeaderData: action.payload };

    case actionTypes.FAX_HEADER_DATA_LOADER:
      return { ...state, faxHeaderloder: action.payload };

    case actionTypes.ADD_FAX_BLACKLIST_LOADER:
      return { ...state, addfaxBlackListLoader: action.payload };

    case actionTypes.GET_ALERTS_LOADER:
      return { ...state, alertLoader: action.payload };

    case actionTypes.GET_ALERTS_DATA:
      return { ...state, alertData: action.payload };

    case actionTypes.CREATE_ALERT_LOADER:
      return { ...state, createAlertLoader: action.payload };

    case actionTypes.CREATE_ALERT_DATA:
      return { ...state, alertData: [...state.alertData, action.payload] };

    case actionTypes.GET_ALERTS_DATA:
      return { ...state, alertData: action.payload };

    case actionTypes.ALERT_DELETE:
      return {
        ...state,
        alertData: state.alertData.filter((a) => a.alert_ID != action.payload),
      };

    case actionTypes.ALERT_DELETE_LOADER:
      return { ...state, alertDeleteloder: action.payload };

    case actionTypes.UPDATE_ALERT_LOADER:
      return { ...state, updateAlertLoader: action.payload };

    case actionTypes.UPDATE_FAX_HEADER_LOADER:
      return { ...state, updatefaxHeaderLoader: action.payload };

    case actionTypes.UPDATE_FAX_HEADER_DATA:
      return {
        ...state,
        faxHeaderData: state.faxHeaderData.map((item) => {
          if (item.header_ID == action.payload.id) {
            return {
              ...item,
              header_text: action.payload.data.header_text,
              header_fromNumber: action.payload.data.header_fromNumber,
              company_address: action.payload.data.company_address,
              // header_addTime: new Date(),
            };
          }
          return item;
        }),
      };
    case actionTypes.UPDATE_ALERT_DATA:
      return {
        ...state,
        alertData: state.alertData.map((a) => {
          if (a.alert_ID == action.payload.id) {
            return {
              ...a,
              alert_value: action.payload.alert_value,
              alert_for: action.payload.alert_for,
              alert_type: action.payload.alert_type,
              // alert_added: new Date(),
            };
          }
          return a;
        }),
      };

    // Blaclist start Here

    case actionTypes.FAX_BLACKLIST_DATA_LOADER:
      return { ...state, faxBlackListLoader: action.payload };

    case actionTypes.FAX_BLACKLIST_DATA:
      return { ...state, faxBlackListData: action.payload };

    case actionTypes.FAX_BLACKLIST_DELETE_LOADER:
      return { ...state, deleteFaxBlackListLoader: action.payload };

    case actionTypes.FAX_BLACKLIST_DELETE:
      return {
        ...state,
        faxBlackListData: state.faxBlackListData.filter(
          (a) => a.blacklist_ID != action.payload
        ),
      };
    case actionTypes.UPDATE_FAX_BLACKLIST_LOADER:
      return { ...state, updateFaxBlackListLoader: action.payload };

    case actionTypes.UPDATE_FAX_BLACKLIST_DATA:
      console.log(action.payload);
      return {
        ...state,
        faxBlackListData: state.faxBlackListData.map((item) => {
          if (item.blacklist_ID == action.payload.id) {
            return {
              ...item,
              blacklist_number: action.payload.data.blacklist_number,
              blacklist_name: action.payload.data.blacklist_name,
              blacklist_desc: action.payload.data.blacklist_desc,
              // blacklist_addTime: new Date(),
            };
          }
          return item;
        }),
      };

    default:
      return state;
  }
};
export default settingReducer;
