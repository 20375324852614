import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DefaultView from "../components/DefaultView";
import ReferralTableRow from "../components/ReferralTableRow";
import {
  actionGetReferrals,
  actionResendReferral,
} from "../store/actions/referralAction";
import { connect } from "react-redux";

const records = [
  {
    id: 1,
    name: "Abdul Rehman",
    email: "admin@gmail.com",
    number: "554455",
    date: "27-01-2023",
  },
];

const Referral = ({
  actionGetReferrals,
  actionResendReferral,
  getReferralLoader,
  referrals,
  resendReferralLoader,
}) => {
  const [filteredRecords, setFilteredRecords] = useState(records);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const filtered = records.filter((record) =>
      record.name.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredRecords(filtered);
  }, [searchText]);

  const handleCheckClick = () => {};
  const handleReinviteClick = () => {};

  useEffect(() => {
    actionGetReferrals();
  }, []);

  console.log({ referrals });

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-top justify-content-between">
                <h4 className="mb-sm-0 main_heading">Referrals</h4>
                <div className="page-title-right">
                  <DefaultView />
                  <div className="d-flex justify-content-sm-end pt-20">
                    <Link
                      to="/referral-invitation-form"
                      className="btn btn-primary"
                    >
                      Add Invite
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end page title */}
          <div className="row">
            <div className="col-12">
              {/* Right Sidebar */}
              <div className="mb-3 compose-section">
                <div className="card marginExtra">
                  <div className="card-header ">
                    <div className="row responsSearch">
                      <div className="col-md-6">
                        <h4 className="card-title">List View</h4>
                        <p className="card-title-desc">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </p>
                      </div>
                      <div
                        id="datatable-buttons_filter"
                        className="col-sm-4 dataTables_filter"
                      >
                        <div className="d-flex align-items-start flex-column">
                          <label>Search:</label>
                          <input
                            type="search"
                            className="form-control"
                            placeholder="Search item"
                            aria-controls="datatable-buttons"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-editable table-nowrap align-middle table-edits">
                        <thead>
                          <tr style={{ cursor: "pointer" }}>
                            <th>Referral Name</th>
                            <th>Email</th>
                            <th>Fax Number</th>
                            <th>Status</th>
                            <th>Invite Datetime</th>
                            <th>Referral Signup</th>
                          </tr>
                        </thead>
                        <tbody>
                          {referrals &&
                            referrals.length > 0 &&
                            referrals.map((record, index) => (
                              <ReferralTableRow
                                key={record.referral_ID}
                                referral_ID={record.referral_ID}
                                name={record.name}
                                email={record.email_address}
                                number={record.phone_number}
                                date={record.created_at}
                                status={record.status}
                                resendReferralLoader={
                                  resendReferralLoader == record.referral_ID
                                }
                                onReinviteClick={actionResendReferral}
                              />
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-5">
                    <div
                      className="dataTables_info"
                      id="datatable-buttons_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing 1 to 10 of 57 entries
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="datatable-buttons_paginate"
                    >
                      <ul className="pagination">
                        <li
                          className="paginate_button page-item previous disabled"
                          id="datatable-buttons_previous"
                        >
                          <Link
                            to="#"
                            aria-controls="datatable-buttons"
                            data-dt-idx={0}
                            tabIndex={0}
                            className="page-link"
                          >
                            Previous
                          </Link>
                        </li>
                        <li className="paginate_button page-item active">
                          <Link
                            to="#"
                            aria-controls="datatable-buttons"
                            data-dt-idx={1}
                            tabIndex={0}
                            className="page-link"
                          >
                            1
                          </Link>
                        </li>
                        {/* Add more pagination links here */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        {/* container-fluid */}
      </div>
      {/* End Page-content */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getReferralLoader: state.referral.getReferralLoader,
    referrals: state.referral.referrals,
    resendReferralLoader: state.referral.resendReferralLoader,
  };
};

export default connect(mapStateToProps, {
  actionGetReferrals,
  actionResendReferral,
})(Referral);
