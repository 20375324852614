import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { actionGetOcrSearchResults } from "../store/actions/inboxAction";
import { connect } from "react-redux";

function SearchRequestTableRow({
  requestID,
  requestResultFilePath,
  startTime,
  endTime,
  searchString,
  searchEngine,
  status,
  actionGetOcrSearchResults,
  getOcrResultLoader,
}) {
  // Format the dates using moment.js
  const formattedStartTime = moment(startTime).format("YYYY-MM-DD hh:mm A");
  const formattedEndTime = moment(endTime).format("YYYY-MM-DD hh:mm A");

  return (
    <tr className="odd">
      <td className="dtr-control sorting_1" tabIndex="0">
        <strong>{requestID}</strong>
      </td>
      <td>{formattedStartTime}</td>
      <td>{formattedEndTime}</td>
      <td>{searchString}</td>
      <td className="text-center">{searchEngine}</td>
      <td className="text-center">{status}</td>
      <td className="text-center h-12 flex items-center justify-center">
        {status === "Completed" ? (
          getOcrResultLoader === requestID ? (
            <i className="fas fa-spinner fa-spin text-xl" title="Loading" />
          ) : (
            <button
              className="btn btn-outline-secondary btn-sm pointer-event"
              title="Show Data"
              onClick={() =>
                actionGetOcrSearchResults(requestResultFilePath, requestID)
              }
            >
              Show Data
            </button>
          )
        ) : (
          <span>Processing</span>
        )}
      </td>
    </tr>
  );
}

const mapStateToProps = (state) => {
  return {
    getOcrResultLoader: state.inbox.getOcrResultLoader,
  };
};
export default connect(mapStateToProps, {
  actionGetOcrSearchResults,
})(SearchRequestTableRow);
