import React, { useState } from "react";
import { connect } from "react-redux";
import {
  actionDeleteList,
  actionUpdateList,
} from "../store/actions/authAction";
import Swal from "sweetalert2";

const ListsTableRow = ({
  index,
  name,
  description,
  status,
  listId,
  actionUpdateList,
  updateListLoader,
  actionDeleteList,
  deleteListLoader,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [data, setData] = useState({
    name,
    description,
    status,
  });

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleSaveClick = (e) => {
    e.preventDefault();
    console.log("Updated Data:", data);
    const request = {
      list_name: data.name,
      description: data.description,
    };
    actionUpdateList(request, listId);
    setIsEditing(false);
  };

  const handleSwitchChange = (e) => {
    const newStatus = e.target.checked;
    setData({ ...data, status: newStatus });

    // Call actionUpdateList with only the `is_action` param
    const request = {
      is_action: newStatus, // You can rename this field based on your API requirement
    };
    actionUpdateList(request, listId);
  };

  const handleRowDoubleClick = () => {
    setIsEditing(!isEditing);
  };

  const handleDeleteClick = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "All the contacts related to this list will be removed along with this list.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Delete Action Call Here
        actionDeleteList(listId);
      }
    });
  };

  return (
    <tr style={{ cursor: "pointer" }} onDoubleClick={handleRowDoubleClick}>
      <td width={"10%"}>{index + 1}</td>
      <td width={"10%"}>
        {isEditing ? (
          <input
            className="form-control"
            type="text"
            value={data.name}
            onChange={(e) => setData({ ...data, name: e.target.value })}
          />
        ) : (
          data.name
        )}
      </td>
      <td width={"10%"}>
        {isEditing ? (
          <input
            className="form-control"
            type="text"
            value={data.description}
            onChange={(e) => setData({ ...data, description: e.target.value })}
          />
        ) : (
          data.description
        )}
      </td>

      <td width={"10%"}>
        <div className="square-switch">
          <input
            type="checkbox"
            id={`status-${listId}`}
            switch="bool"
            checked={data.status}
            onChange={handleSwitchChange} // Call the new handler here
          />
          <label
            htmlFor={`status-${listId}`}
            data-on-label="Yes"
            data-off-label="No"
          />
        </div>
      </td>
      <td width={"10%"}>
        {isEditing ? (
          <button
            className="btn btn-outline-secondary btn-sm edit"
            title="Save"
            onClick={handleSaveClick}
          >
            <i className="fas fa-save" title="Save" />
          </button>
        ) : (
          <button
            className="btn btn-outline-secondary btn-sm "
            title="Edit"
            onClick={handleEditClick}
          >
            <i className="fas fa-pencil-alt" title="Edit" />
          </button>
        )}
        <button
          className="btn btn-outline-secondary btn-sm delete"
          title="Delete"
          style={{ marginLeft: 5 }}
          onClick={handleDeleteClick}
        >
          {deleteListLoader && deleteListLoader == listId ? (
            <i className="fas fa-spinner fa-spin" title="Loading"></i>
          ) : (
            <i className="fas fa-trash-alt" title="Delete" />
          )}
        </button>
      </td>
    </tr>
  );
};

// Map the state to props
const mapStateToProps = (state) => {
  return {
    updateListLoader: state.auth.updateListLoader,
    deleteListLoader: state.auth.deleteListLoader,
  };
};

export default connect(mapStateToProps, {
  actionUpdateList,
  actionDeleteList,
})(ListsTableRow);
