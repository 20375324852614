import * as actionTypes from "../action";

const initialState = {
  getReceivedMessagesLoader: false,
  receivedMessages: [],
  receivedMessagesCount: 0,
  addImportantLoader: false,
  removeImportantLoader: false,
  markReadLoader: false,
  markUnreadLoader: false,
  markTrashLoader: false,
  getTrashedMessagesLoader: false,
  trashedMessages: [],
  trashedMessagesCount: 0,
  removeFromTrashLoader: false,
  messageDetailLoader: false,
  composeFaxLoader: false,
  messageDetailData: [],
  searchDocumentLoader: false,
  getOcrRequestAvailabilityLoader: false,
  ocrRequestsData: [],
  getOcrResultLoader: false,
  getOcrResultData: [],
};

const inboxReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_RECEIVED_MESSAGES_LOADER:
      return { ...state, getReceivedMessagesLoader: action.payload };

    case actionTypes.RECEIVED_MESSAGES_DATA:
      return {
        ...state,
        receivedMessages: action.payload.messages,
        receivedMessagesCount: parseFloat(action.payload.totalCount),
      };

    case actionTypes.MARK_READ_MESSAGES_LOADER:
      return { ...state, markReadLoader: action.payload };

    case actionTypes.MARK_READ_MESSAGES:
      return {
        ...state,
        receivedMessages: state.receivedMessages.map((m) => {
          if (action.payload.includes(m.msg.recvd_msg_ID)) {
            return { ...m, msg: { ...m.msg, is_read: "1" } };
          } else {
            return m;
          }
        }),
      };
    case actionTypes.MARK_UNREAD_MESSAGES_LOADER:
      return { ...state, markUnreadLoader: action.payload };

    case actionTypes.MARK_UNREAD_MESSAGES:
      return {
        ...state,
        receivedMessages: state.receivedMessages.map((m) => {
          if (action.payload.includes(m.msg.recvd_msg_ID)) {
            return { ...m, msg: { ...m.msg, is_read: "0" } };
          } else {
            return m;
          }
        }),
      };

    case actionTypes.MARK_ADD_IMPORTANT_LOADER:
      return { ...state, addImportantLoader: action.payload };

    case actionTypes.MARK_ADD_IMPORTANT_MESSAGES:
      return {
        ...state,
        receivedMessages: state.receivedMessages.map((m) => {
          if (action.payload.includes(m.msg.recvd_msg_ID)) {
            return { ...m, isSelected: false };
          } else {
            return m;
          }
        }),
      };

    case actionTypes.MARK_REMOVE_IMPORTANT_LOADER:
      return { ...state, removeImportantLoader: action.payload };

    case actionTypes.MARK_REMOVE_IMPORTANT_MESSAGES:
      return {
        ...state,
        receivedMessages: state.receivedMessages.map((m) => {
          if (action.payload.includes(m.msg.recvd_msg_ID)) {
            return { ...m, isSelected: false };
          } else {
            return m;
          }
        }),
      };

    case actionTypes.MARK_ADD_STARRED_MESSAGES:
      return {
        ...state,
        receivedMessages: state.receivedMessages.map((m) => {
          if (action.payload.recvd_msg_ID === m.msg.recvd_msg_ID) {
            return { ...m, msg: { ...m.msg, is_starred: "1" } };
          } else {
            return m;
          }
        }),
      };

    case actionTypes.MARK_REMOVE_STARRED_MESSAGES:
      return {
        ...state,
        receivedMessages: state.receivedMessages.map((m) => {
          if (action.payload.recvd_msg_ID === m.msg.recvd_msg_ID) {
            return { ...m, msg: { ...m.msg, is_starred: "0" } };
          } else {
            return m;
          }
        }),
      };

    case actionTypes.MARK_TRASH_LOADER:
      return { ...state, markTrashLoader: action.payload };

    case actionTypes.GET_TRASHED_MESSAGES_LOADER:
      return { ...state, getTrashedMessagesLoader: action.payload };

    case actionTypes.TRASHED_MESSAGES_DATA:
      return {
        ...state,
        trashedMessages: action.payload.messages,
        trashedMessagesCount: parseFloat(action.payload.totalCount),
      };

    case actionTypes.REMOVE_FROM_TRASH_LOADER:
      return { ...state, removeFromTrashLoader: action.payload };

    case actionTypes.GET_DETAIL_MESSAGES_LOADER:
      return { ...state, messageDetailLoader: action.payload };

    case actionTypes.DETAIL_MESSAGES_DATA:
      return { ...state, messageDetailData: action.payload };

    case actionTypes.COMPOSE_FAX_LOADER:
      return { ...state, composeFaxLoader: action.payload };

    case actionTypes.GET_OCR_REQUEST_AVAILABILITY_LOADER:
      return { ...state, getOcrRequestAvailabilityLoader: action.payload };

    case actionTypes.OCR_REQUEST_AVAILABILITY_DATA:
      return { ...state, ocrRequestsData: action.payload };

    case actionTypes.SEARCH_FAX_LOADER:
      return { ...state, searchDocumentLoader: action.payload };

    case actionTypes.GET_OCR_SEARCH_RESULTS_LOADER:
      return { ...state, getOcrResultLoader: action.payload };

    case actionTypes.OCR_SEARCH_RESULTS_DATA:
      return { ...state, getOcrResultData: action.payload };

    default:
      return state;
  }
};
export default inboxReducer;
