import React, { memo } from "react";
import { BrowserRouter } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppRoutes from "./components/AppRoutes";

const App = memo(() => {
  return (
    <BrowserRouter>
      <div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          transition={Slide}
        />
        <React.Suspense>
          <AppRoutes />
        </React.Suspense>
      </div>
    </BrowserRouter>
  );
});
export default App;
