import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import DefaultView from "../components/DefaultView";
import EmailLeftbar from "../components/EmailLeftbar";
import EmailListItem from "../components/EmailListItem";
import {
  actionGetSentMessages,
  actionMarkAddImportantSentMessage,
  actionMarkRemoveImportantSentMessage,
  actionMarkAddStarredSentMessage,
  actionMarkRemoveStarredSentMessage,
  actionMarkTrashSentMessage,
} from "../store/actions/sentFaxesAction";
import ReadEmail from "./ReadEmail";

const SentFaxes = memo(
  ({
    actionGetSentMessages,
    getSentMessagesLoader,
    sentMessages,
    sentMessagesCount,
    actionMarkAddImportantSentMessage,
    actionMarkRemoveImportantSentMessage,
    addSentMessageImportantLoader,
    removeSentMessageImportantLoader,
    markSendTrashMessageLoader,
    actionMarkAddStarredSentMessage,
    actionMarkRemoveStarredSentMessage,
    actionMarkTrashSentMessage,
  }) => {
    const [searchText, setSearchText] = useState("");
    const [type, setType] = useState("");
    const [isImportant, setIsImportant] = useState(false);
    const [msgType, setMsgType] = useState("SENT");
    const [filteredEmailListItems, setFilteredEmailListItems] = useState([]);
    const [showEmailDetailID, setShowEmailDetailID] = useState("");

    //const [offset, setOffset] = useState(0);

    const offset = useRef(0);
    const [itemsPerPage] = useState(20);

    const { pathname } = useLocation();
    useEffect(() => {
      switch (pathname) {
        case "/sent-faxes":
          offset.current = 0;
          setMsgType("SENT");
          actionGetSentMessages(offset.current, "SENT", isImportant);
          break;
        case "/outbox":
          offset.current = 0;
          setMsgType("PENDING");
          actionGetSentMessages(offset.current, "PENDING", isImportant);
          break;
        case "/failed":
          offset.current = 0;
          setMsgType("FAILED");
          actionGetSentMessages(offset.current, "FAILED", isImportant);
          break;
        case "/scheduled":
          offset.current = 0;
          setMsgType("SCHEDULED");
          actionGetSentMessages(offset.current, "SCHEDULED", isImportant);
          break;
        default:
          break;
      }
    }, [pathname, actionGetSentMessages]);

    const handleSearch = () => {
      // Implement your search logic here using 'searchText' state
      actionGetSentMessages(0, msgType, isImportant, searchText);
      offset.current = 0;
    };

    const handleRefresh = () => {
      offset.current = 0;
      setSearchText("");
      //setMsgType("");
      actionGetSentMessages(offset.current, msgType, isImportant);
    };

    useEffect(() => {
      const filtered =
        sentMessages &&
        sentMessages.filter((record) => {
          return record.msg.msg_senderName
            .toLowerCase()
            .includes(searchText.toLowerCase());
        });

      setFilteredEmailListItems(filtered);
    }, [searchText]);

    useMemo(() => {
      setFilteredEmailListItems(
        sentMessages.map((msg) => ({ ...msg, isSelected: false }))
      );
      if (sentMessages.length < 1) {
        // setOffset(offset);
      }
    }, [sentMessages]);

    const handleNextClick = () => {
      offset.current += 1;
      actionGetSentMessages(
        offset.current,
        msgType,
        isImportant,
        searchText ? searchText : ""
      );
    };

    const handleBackClick = () => {
      const newOffset = offset.current - 1;
      console.log({ newOffset, offset: offset.current });
      actionGetSentMessages(
        newOffset,
        msgType,
        isImportant,
        searchText ? searchText : ""
      );
      offset.current = newOffset;
    };

    const handleSelectThisPage = (importantType) => {
      const pageData = filteredEmailListItems.map((m) => ({
        ...m,
        isSelected: true,
      }));
      setFilteredEmailListItems(pageData);
      importantType && importantType == "selectAll"
        ? setType("selectAll")
        : importantType == "selectThisPage"
        ? setType("selectThisPage")
        : setType("");
    };

    const handleDeSelectAll = () => {
      setType("");
      setFilteredEmailListItems(
        filteredEmailListItems.map((m) => ({
          ...m,
          isSelected: false,
        }))
      );
    };
    const handleSelectAll = () => {
      handleSelectThisPage("selectAll");
      // TODO: logic for server data change
    };

    const handleSelectMessage = (index) => {
      setType("");
      console.log("handleSelectMessage called", index);
      const newFilteredEmailListItems = filteredEmailListItems.map((m, i) => {
        if (index === i) {
          return { ...m, isSelected: !m.isSelected };
        } else {
          return m;
        }
      });
      setFilteredEmailListItems(newFilteredEmailListItems);
    };
    const handleStarred = (index, type) => {
      const selectedMsg = filteredEmailListItems[index];

      if (selectedMsg) {
        const sent_msg_ID = selectedMsg.msg.sent_msg_ID;
        console.log({ sent_msg_ID });
        type == "starAdd"
          ? actionMarkAddStarredSentMessage({ sent_msg_ID })
          : actionMarkRemoveStarredSentMessage({ sent_msg_ID });
      }
    };

    const handleMarkAddImportant = () => {
      const selectedIds = filteredEmailListItems
        .filter((m) => m.isSelected)
        .map((m) => m.msg.sent_msg_ID);

      type && type == "selectAll"
        ? actionMarkAddImportantSentMessage(
            {
              sent_msg_ID: [0],
              select_all: "YES",
            },
            filteredEmailListItems
          )
        : actionMarkAddImportantSentMessage(
            {
              sent_msg_ID: selectedIds,
              select_all: "NO",
            },
            filteredEmailListItems
          );
    };
    const handleMarkRemoveImportant = () => {
      const selectedIds = filteredEmailListItems
        .filter((m) => m.isSelected)
        .map((m) => m.msg.sent_msg_ID);
      console.log({ selectedIds });
      const getMessageParams = {
        offset: offset.current,
        msgType,
        isImportant,
        searchText,
      };

      type && type == "selectAll"
        ? actionMarkRemoveImportantSentMessage(
            {
              sent_msg_ID: [0],
              select_all: "YES",
            },
            filteredEmailListItems,
            getMessageParams
          )
        : actionMarkRemoveImportantSentMessage(
            {
              sent_msg_ID: selectedIds,
              select_all: "NO",
            },
            filteredEmailListItems,
            getMessageParams
          );
    };

    const handleSentMessageMarkTrash = () => {
      const selectedIds = filteredEmailListItems
        .filter((m) => m.isSelected)
        .map((m) => m.msg.sent_msg_ID);
      console.log({ selectedIds });
      const getMessageParams = {
        offset: offset.current,
        msgType,
        isImportant,
        searchText,
      };

      actionMarkTrashSentMessage(
        {
          sent_msg_ID: selectedIds,
        },
        getMessageParams
      );
    };
    const showingFrom = offset.current * itemsPerPage + 1;
    const showingTo = Math.min(
      (offset.current + 1) * itemsPerPage,
      sentMessagesCount
    );

    const BackAndNextButtonsGroup = () => (
      <div className="btn-group float-end">
        <button
          type="button"
          className="btn btn-sm btn-success waves-effect"
          onClick={handleBackClick}
          disabled={offset.current === 0}
        >
          <i className="fa fa-chevron-left" />
        </button>
        <button
          type="button"
          className="btn btn-sm btn-success waves-effect"
          onClick={handleNextClick}
          disabled={
            (offset.current + 1) * itemsPerPage >= sentMessagesCount ||
            !sentMessagesCount ||
            !filteredEmailListItems
          }
        >
          <i className="fa fa-chevron-right" />
        </button>
      </div>
    );

    return showEmailDetailID ? (
      <ReadEmail
        detailId={showEmailDetailID}
        setShowEmailDetailID={setShowEmailDetailID}
        type="SENT-FAX"
        getMessageParams={{
          offset: offset.current,
          msgType,
          isImportant,
          searchText,
        }}
      />
    ) : (
      <div className="page-content">
        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 main_heading">
                  {pathname == "/sent-faxes"
                    ? "Sent Faxes"
                    : pathname == "/outbox"
                    ? "Outbox"
                    : pathname == "/failed"
                    ? "Failed"
                    : pathname == "/scheduled"
                    ? "Scheduled"
                    : "Faxes"}
                </h4>
                <div className="page-title-right">
                  <DefaultView />
                </div>
              </div>
              <div className="d-flex justify-content-end mb-3">
                <BackAndNextButtonsGroup />
              </div>
            </div>
          </div>
          {/* end page title */}
          <div className="row">
            <div className="col-12">
              {/* Left sidebar */}
              <EmailLeftbar />
              {/* End Left sidebar */}

              {/* Right Sidebar */}
              <div className="email-rightbar mb-3">
                <div className="card">
                  <div
                    className="btn-toolbar gap-2 p-3 d-flex justify-content-between"
                    role="toolbar"
                  >
                    <div>
                      <div className="btn-group" style={{ marginRight: 3 }}>
                        <button
                          type="button"
                          className="btn btn-primary waves-light waves-effect dropdown-toggle d-flex"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div className="checkbox-wrapper-mail">
                            <input type="checkbox" id={`chk_select`} />
                            <label htmlFor={`chk_select`} className="toggle" />
                          </div>{" "}
                          <i className="mdi mdi-chevron-down ms-1" />
                        </button>
                        <div className="dropdown-menu">
                          <div
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleSelectThisPage("selectThisPage")
                            }
                          >
                            Select This Page
                          </div>
                          <div
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                            onClick={handleSelectAll}
                          >
                            Select All
                          </div>
                          <div
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                            onClick={handleDeSelectAll}
                          >
                            None
                          </div>
                        </div>
                      </div>
                      <div className="btn-group">
                        {filteredEmailListItems.filter((m) => m.isSelected)
                          .length > 0 && (
                          <>
                            {!isImportant && (
                              <button
                                onClick={handleMarkAddImportant}
                                title="Mark Important"
                                type="button"
                                className="btn btn-primary waves-light waves-effect"
                              >
                                {addSentMessageImportantLoader ? (
                                  <i
                                    className="fas fa-spinner fa-spin"
                                    title="Loading"
                                  ></i>
                                ) : (
                                  <i className="fa fa-exclamation-circle" />
                                )}
                              </button>
                            )}
                            <button
                              onClick={handleMarkRemoveImportant}
                              title="Mark Not Important"
                              type="button"
                              className="btn btn-primary waves-light waves-effect"
                            >
                              {removeSentMessageImportantLoader ? (
                                <i
                                  className="fas fa-spinner fa-spin"
                                  title="Loading"
                                ></i>
                              ) : (
                                <i className="fa fa-times" />
                              )}
                            </button>
                            {type !== "selectAll" && (
                              <button
                                onClick={handleSentMessageMarkTrash}
                                title="Move to trash"
                                type="button"
                                className="btn btn-primary waves-light waves-effect"
                              >
                                {markSendTrashMessageLoader ? (
                                  <i
                                    className="fas fa-spinner fa-spin"
                                    title="Loading"
                                  ></i>
                                ) : (
                                  <i className="far fa-trash-alt" />
                                )}
                              </button>
                            )}
                          </>
                        )}
                      </div>
                      <div
                        className="btn btn-primary"
                        style={{ cursor: "pointer", marginLeft: 10 }}
                        onClick={() => handleRefresh()}
                      >
                        <i className="fa fa-refresh" aria-hidden="true"></i>
                      </div>
                      {/* <div class="btn-group">
                                        <button type="button" class="btn btn-primary waves-light waves-effect dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i class="fa fa-tag"></i> <i class="mdi mdi-chevron-down ms-1"></i>
                                        </button>
                                        <div class="dropdown-menu">
                                            <Link class="dropdown-item" to="#">Updates</Link>
                                            <Link class="dropdown-item" to="#">Social</Link>
                                            <Link class="dropdown-item" to="#">Team Manage</Link>
                                        </div>
                                    </div>
                                    
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-primary waves-light waves-effect dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                            More <i class="mdi mdi-dots-vertical ms-2"></i>
                                        </button>
                                        <div class="dropdown-menu">
                                            <Link class="dropdown-item" to="#">Mark as Unread</Link>
                                            <Link class="dropdown-item" to="#">Mark as Important</Link>
                                            <Link class="dropdown-item" to="#">Add to Tasks</Link>
                                            <Link class="dropdown-item" to="#">Add Star</Link>
                                            <Link class="dropdown-item" to="#">Mute</Link>
                                        </div>
                                    </div> */}
                    </div>
                    {/* <div class="md-3" style="padding-right:35%;"> </div> */}
                    <div className="d-flex">
                      <div
                        className="btn-group"
                        style={{ marginRight: 10 }}
                        role="group"
                        aria-label="Basic radio toggle button group"
                      >
                        <input
                          type="radio"
                          className="btn-check"
                          name="btnradio"
                          id="btnradio4"
                        />
                        <button
                          onClick={() => {
                            if (isImportant) {
                              offset.current = 0;
                              setIsImportant(false);
                              actionGetSentMessages(
                                offset.current,
                                msgType,
                                "NO",
                                searchText ? searchText : ""
                              );
                            }
                          }}
                          style={{ minWidth: "80px" }}
                          className={`btn ${
                            isImportant
                              ? "btn-outline-secondary"
                              : "btn-primary"
                          }`}
                          htmlFor="btnradio4"
                        >
                          All
                        </button>
                        <input
                          type="radio"
                          className="btn-check"
                          name="btnradio"
                          id="btnradio5"
                          autoComplete="on"
                          defaultChecked=""
                        />
                        <button
                          onClick={() => {
                            if (!isImportant) {
                              offset.current = 0;
                              setIsImportant(true);
                              actionGetSentMessages(
                                offset.current,
                                msgType,
                                "YES",
                                searchText ? searchText : ""
                              );
                            }
                          }}
                          style={{ minWidth: "80px" }}
                          className={`btn ${
                            isImportant
                              ? "btn-primary"
                              : "btn-outline-secondary"
                          }`}
                          htmlFor="btnradio5"
                        >
                          Important
                        </button>
                      </div>
                      <form
                        className="app-search d-none d-lg-block"
                        style={{ float: "left" }}
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSearch();
                        }}
                      >
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            value={searchText}
                            onChange={(e) => {
                              setSearchText(e.target.value);
                              if (e.target.value.trim() === "") {
                                actionGetSentMessages(
                                  offset.current,
                                  msgType,
                                  isImportant
                                );
                              }
                            }}
                          />
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={handleSearch}
                          >
                            <i className="bx bx-search-alt align-middle" />
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <ul className="message-list faxMessage">
                    {!getSentMessagesLoader &&
                      filteredEmailListItems.length < 1 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <h5>No Data Found!</h5>
                        </div>
                      )}
                    {getSentMessagesLoader && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h5>Loading data..</h5>
                      </div>
                    )}
                    {!getSentMessagesLoader &&
                      filteredEmailListItems.map((item, index) => (
                        <EmailListItem
                          key={index}
                          index={index}
                          senderName={item.msg.msg_senderName}
                          senderPhone={item.msg.msg_fromNumber}
                          noOfPages={
                            item.msg.msg_totalPages ? item.msg.msg_totalPages : 0
                          }
                          date={item.msg.msg_time}
                          //isUnread={parseInt(item.msg.is_read)}
                          isUnread={1}
                          isStarred={parseInt(item.msg.is_starred)}
                          isChecked={item.isSelected}
                          handleSelectMessage={handleSelectMessage}
                          handleStarred={handleStarred}
                          detailId={item.msg.sent_msg_ID}
                          setShowEmailDetailID={setShowEmailDetailID}
                        />
                      ))}
                  </ul>
                </div>{" "}
                {/* card */}
                <div className="row">
                  <div className="col-7">
                    Showing {sentMessagesCount > 0 ? showingFrom : 0} -{" "}
                    {sentMessagesCount > 0 ? showingTo : 0} of{" "}
                    {sentMessagesCount ? sentMessagesCount : 0}
                  </div>
                  <div className="col-5">
                    <BackAndNextButtonsGroup />
                  </div>
                </div>
              </div>
              {/* end  Right Sidebar Col-9 */}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

const mapStateToProps = (state) => {
  return {
    getSentMessagesLoader: state.sentFax.getSentMessagesLoader,
    sentMessages: state.sentFax.sentMessages,
    sentMessagesCount: state.sentFax.sentMessagesCount,
    addSentMessageImportantLoader: state.sentFax.addSentMessageImportantLoader,
    removeSentMessageImportantLoader:
      state.sentFax.removeSentMessageImportantLoader,
    markSendTrashMessageLoader: state.sentFax.markSendTrashMessageLoader,
  };
};

export default connect(mapStateToProps, {
  actionGetSentMessages,
  actionMarkAddImportantSentMessage,
  actionMarkRemoveImportantSentMessage,
  actionMarkAddStarredSentMessage,
  actionMarkRemoveStarredSentMessage,
  actionMarkTrashSentMessage,
})(SentFaxes);
