import React, { useState } from "react";
import { Link } from "react-router-dom";

function PhoneNumberAccordion({
  phoneNumber,
  subscriptionDate,
  nextBillingDate,
  status,
  index,
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="accordion-item">
      <h2 className="accordion-header">
        <button
          className={`accordion-button fw-medium ${
            isExpanded ? "" : "collapsed"
          }`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapse_${index}`}
          aria-expanded={isExpanded}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {phoneNumber}
        </button>
      </h2>
      <div
        id={`collapse_${index}`}
        className={`accordion-collapse collapse ${isExpanded ? "show" : ""}`}
      >
        <div className="accordion-body">
          <div className="text-muted">
            <div className="light">
              <p className="light_text">Subscription Date</p>
            </div>
            <div className="dark">
              <p className="dark_text">{subscriptionDate}</p>
            </div>
          </div>
          <div className="text-muted">
            <div className="light">
              <p className="light_text">Next Billing Date</p>
            </div>
            <div className="dark">
              <p className="dark_text">{nextBillingDate}</p>
            </div>
          </div>
          <div className="text-muted">
            <div className="light">
              <p className="light_text">Status</p>
            </div>
            <div className="dark">
              <p
                className={`green_text ${
                  status === "enabled" ? "active" : "inactive"
                }`}
              >
                {status === "enabled" ? "Active" : "Inactive"}
              </p>
            </div>
          </div>
          <div className="text-muted">
            <div className="light"></div>
            <div className="dark">
              <Link to={"/phone-numbers-list"} style={{ fontWeight: "bolder" }}>
                <u>Details</u>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhoneNumberAccordion;
