import React from "react";
import { Link } from "react-router-dom";
import {
  actionEnableNumber,
  actionDisableNumber,
  actionCancelNumber,
} from "../store/actions/authAction";
import { connect } from "react-redux";

const FaxPhoneNumberTableRow = ({
  index,
  id,
  faxNumber,
  activationDate,
  billingDate,
  status,
  onButtonClick,
  number_ID,
  routeTo,
  number_status,
  actionEnableNumber,
  actionDisableNumber,
  actionCancelNumber,
  enableNumberLoader,
  disableNumberLoader,
  cancelNumberLoader,
}) => {
  const isOdd = index % 2 === 0 ? "odd" : "even";

  const handleCancelNumber = () => {
    if (window.confirm("Are you sure you want to cancel this number?")) {
      actionCancelNumber(faxNumber);
    }
  };

  return (
    <tr className={isOdd}>
      <td className="dtr-control sorting_1" tabIndex={0}>
        {index + 1}
      </td>
      <td>{faxNumber}</td>
      <td>{routeTo}</td>
      <td>{activationDate}</td>
      <td>{billingDate}</td>
      <td className="text-center">
        <button
          type="button"
          className={`btn ${
            status === "Active" ? "btn-soft-success" : "btn-soft-danger"
          } waves-effect waves-light`}
          onClick={() => onButtonClick(id)}
        >
          {status}
        </button>
      </td>
      <td className="text-center">
        {number_status === "disabled" && (
          <button
            onClick={() => actionEnableNumber(faxNumber)}
            className="btn btn-outline btn-success btn-sm mx-1"
            title="Enable this number"
            disabled={enableNumberLoader}
          >
            {enableNumberLoader ? (
              <i className="fas fa-spinner fa-spin" title="Loading"></i>
            ) : (
              <i className="fas fa-check" title="Enable this number" />
            )}
          </button>
        )}

        {number_status === "enabled" && (
          <button
            onClick={() => actionDisableNumber(faxNumber)}
            className="btn btn-outline btn-danger btn-sm mx-1"
            title="Disable this number"
            disabled={disableNumberLoader}
          >
            {disableNumberLoader ? (
              <i className="fas fa-spinner fa-spin" title="Loading"></i>
            ) : (
              <i className="fas fa-ban" title="Disable this number" />
            )}
          </button>
        )}

        <Link
          to={`/phone-number-set-route/${number_ID}`}
          className="btn btn-outline btn-primary btn-sm mx-1"
          title="Configure Route"
        >
          <i className="fas fa-gear" title="Configure Route" />
        </Link>

        {number_status !== "cancelled" && (
          <button
            onClick={handleCancelNumber}
            className="btn btn-outline btn-danger btn-sm mx-1"
            title="Cancel this number"
            disabled={cancelNumberLoader}
          >
            {cancelNumberLoader ? (
              <i className="fas fa-spinner fa-spin" title="Loading"></i>
            ) : (
              <i className="fas fa-close" title="Cancel this number" />
            )}
          </button>
        )}
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => {
  return {
    enableNumberLoader: state.auth.enableNumberLoader,
    disableNumberLoader: state.auth.disableNumberLoader,
    cancelNumberLoader: state.auth.cancelNumberLoader,
  };
};

export default connect(mapStateToProps, {
  actionEnableNumber,
  actionDisableNumber,
  actionCancelNumber,
})(FaxPhoneNumberTableRow);
