import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import NavBar from "./NavBar";

// This is the layout HOC
const withLayout = (WrappedComponent) => {
  return class WithLayout extends React.Component {
    render() {
      return (
        <div id="layout-wrapper">
          {/* Place your common layout components here */}
          <Header />
          <NavBar />
          <nav>Navigation</nav>
          <div className="main-content">
            <WrappedComponent {...this.props} />
          </div>
          <Footer />
        </div>
      );
    }
  };
};

export default withLayout;
