import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DefaultView from "../components/DefaultView";
import { connect } from "react-redux";
import { actionAddReferral } from "../store/actions/referralAction";

const ReferralInviteForm = ({ actionAddReferral, addReferralLoader }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    faxNumber: "",
    emailAddress: "",
    phoneNumber: "",
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = {};

    // Name is required
    if (!formData.name || formData.name.trim() === "") {
      validationErrors.name = "Name is required";
    }

    // Email is required with proper validation
    if (!formData.emailAddress) {
      validationErrors.emailAddress = "Email Address is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.emailAddress)) {
      validationErrors.emailAddress = "Enter a valid email address";
    }

    // Phone number and fax number are optional, no strict validation
    if (formData.phoneNumber && isNaN(formData.phoneNumber)) {
      validationErrors.phoneNumber = "Phone number must be numeric";
    }
    if (formData.faxNumber && isNaN(formData.faxNumber)) {
      validationErrors.faxNumber = "Fax number must be numeric";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      console.log("Form submitted:", formData);

      const requestData = {
        name: formData.name,
        email_address: formData.emailAddress,
        phone_number: formData.phoneNumber || null,
        fax_number: formData.faxNumber || null,
      };

      actionAddReferral(requestData, navigate);
    }
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-top justify-content-between">
                <h4 className="mb-sm-0 main_heading">Invite Form</h4>
                <div className="page-title-right">
                  <DefaultView />
                </div>
              </div>
            </div>
          </div>
          {/* end page title */}
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3 compose-section">
                  <div className="card">
                    <div className="card-body">
                      <div className="mb-3">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label htmlFor="name" className="form-label">
                                Name
                              </label>
                              <input
                                className={`form-control ${
                                  errors.name ? "is-invalid" : ""
                                }`}
                                type="text"
                                placeholder="Enter Name"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                              />
                              {errors.name && (
                                <div className="invalid-feedback">
                                  {errors.name}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="emailAddress"
                                className="form-label"
                              >
                                Email Address
                              </label>
                              <input
                                className={`form-control ${
                                  errors.emailAddress ? "is-invalid" : ""
                                }`}
                                type="text"
                                placeholder="Email Address"
                                name="emailAddress"
                                value={formData.emailAddress}
                                onChange={handleInputChange}
                              />
                              {errors.emailAddress && (
                                <div className="invalid-feedback">
                                  {errors.emailAddress}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label htmlFor="faxNumber" className="form-label">
                                Fax Number
                              </label>
                              <input
                                className={`form-control ${
                                  errors.faxNumber ? "is-invalid" : ""
                                }`}
                                type="text"
                                placeholder="Enter Fax Number"
                                name="faxNumber"
                                value={formData.faxNumber}
                                onChange={handleInputChange}
                              />
                              {errors.faxNumber && (
                                <div className="invalid-feedback">
                                  {errors.faxNumber}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="phoneNumber"
                                className="form-label"
                              >
                                Phone Number
                              </label>
                              <input
                                className={`form-control ${
                                  errors.phoneNumber ? "is-invalid" : ""
                                }`}
                                type="text"
                                placeholder="Enter Phone Number"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleInputChange}
                              />
                              {errors.phoneNumber && (
                                <div className="invalid-feedback">
                                  {errors.phoneNumber}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                      {/* end row */}
                    </div>
                  </div>
                </div>
                {/* card */}
              </div>
            </div>
            <div className="row" style={{ marginBottom: "1em" }}>
              <div className="col-md-12">
                <div className="d-flex justify-content-sm-end">
                  {addReferralLoader ? (
                    <button
                      type="button"
                      className="btn btn-primary waves-light waves-effect"
                    >
                      <i className="fas fa-spinner fa-spin" title="Loading"></i>
                    </button>
                  ) : (
                    <button className="btn btn-primary">Save</button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
        {/* container-fluid */}
      </div>
      {/* End Page-content */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    addReferralLoader: state.referral.addReferralLoader,
  };
};

export default connect(mapStateToProps, {
  actionAddReferral,
})(ReferralInviteForm);
