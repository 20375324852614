import React, { memo, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DefaultView from "../components/DefaultView";
import EmailLeftbar from "../components/EmailLeftbar";
import FaxCoverTableRow from "../components/FaxCoverTableRow";
import { actionGetReceivedMessages } from "../store/actions/inboxAction";
import { actionGetFaxCover } from "../store/actions/settingAction";

const FaxCovers = ({
  actionGetFaxCover,
  user,
  faxCoverData,
  faxCoverloder,
}) => {
  useEffect(() => {
    actionGetFaxCover();
  }, [actionGetFaxCover]);

  return (
    <div className="page-content">
      <div className="container-fluid">
        {/* start page title */}
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 main_heading">Fax Cover</h4>
              <div className="page-title-right">
                <DefaultView />
              </div>
            </div>
            {/* <div className="d-flex justify-content-end mb-3">
              <div className="btn-group float-end">
                <button
                  type="button"
                  className="btn btn-sm btn-success waves-effect"
                >
                  <i className="fa fa-chevron-left" />
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-success waves-effect"
                >
                  <i className="fa fa-chevron-right" />
                </button>
              </div>
            </div> */}
          </div>
        </div>
        {/* end page title */}
        <div className="row">
          <div className="col-12">
            {/* Left sidebar */}
            <EmailLeftbar />
            {/* End Left sidebar */}

            {/* Right Sidebar */}
            <div className="email-rightbar mb-3 compose-section">
              <div className="card">
                <div
                  className="btn-toolbar gap-2 p-3 justify-content-between"
                  role="toolbar"
                >
                  <div className="btn-group">
                    <div className="alert alert-info" role="alert">
                      <strong>Please Note!</strong> To update your cover data,
                      kindly remove the existing record and create a new one.
                    </div>
                  </div>
                  <div className="right-side-btns">
                    <div className="btn-group">
                      <Link
                        to="/fax-covers/create"
                        className="btn btn-primary waves-light waves-effect"
                      >
                        Add
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  {faxCoverloder ? (
                    <p className="text-center">Loading... Please wait</p>
                  ) : (
                    <div className="table-responsive">
                      <table className="table table-editable table-nowrap align-middle table-edits">
                        <thead>
                          <tr style={{ cursor: "pointer" }}>
                            <th>Company Name</th>
                            <th>Company Number</th>
                            <th>Created At</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {faxCoverData &&
                            faxCoverData.map((data, index) => (
                              <FaxCoverTableRow
                                key={index}
                                id={data.cover_ID}
                                date={data.cover_addTime}
                                name={data.company_name}
                                number={data.company_number}
                              />
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {!faxCoverloder &&
                    faxCoverData &&
                    faxCoverData.length == 0 && (
                      <p className="text-center">Fax Cover Data Not Found.</p>
                    )}
                </div>
              </div>
              {/* card */}
            </div>
            {/* end  Right Sidebar Col-9 */}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    getReceivedMessagesLoader: state.inbox.getReceivedMessagesLoader,
    receivedMessages: state.inbox.receivedMessages,
    user: state.auth.user,
    faxCoverData: state.setting.faxCoverData,
    faxCoverloder: state.setting.faxCoverloder,
  };
};

export default connect(mapStateToProps, {
  actionGetReceivedMessages,
  actionGetFaxCover,
})(FaxCovers);
