import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import DefaultView from "../components/DefaultView";
import {
  actionSearchFaxDocument,
  actionGetOcrRequestAvailability,
} from "../store/actions/inboxAction";
import SearchRequestTableRow from "../components/SearchRequestTableRow";
import moment from "moment";

const records = [
  // Add the 20 records here
  // ... records from the initial data
];

const SearchDocument = ({
  actionSearchFaxDocument,
  actionGetOcrRequestAvailability,
  getOcrRequestAvailabilityLoader,
  ocrRequestsData,
  getOcrResultData,
}) => {
  const datepickerRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [selectedEngine, setSelectedEngine] = useState("");
  const [incomingOutgoing, setIncomingOutgoing] = useState("");
  const [dateRange, setDateRange] = useState("");
  const [errors, setErrors] = useState({
    searchText: "",
    selectedEngine: "",
    dateRange: "",
    incomingOutgoing: "",
  });

  useEffect(() => {
    // Initialize Flatpickr after the component has mounted
    flatpickr(datepickerRef.current, {
      mode: "range",
      enableTime: false,
      dateFormat: "Y-m-d",
      onChange: (selectedDates, dateStr) => setDateRange(dateStr),
    });
  }, []);
  useEffect(() => {
    actionGetOcrRequestAvailability();
  }, [actionGetOcrRequestAvailability]);

  const validateInputs = () => {
    const newErrors = {
      searchText: "",
      selectedEngine: "",
      dateRange: "",
      incomingOutgoing: "",
    };
    if (!searchText) newErrors.searchText = "Search text is required";
    if (!selectedEngine) newErrors.selectedEngine = "Select engine is required";
    if (!dateRange) newErrors.dateRange = "Date range is required";
    if (!incomingOutgoing)
      newErrors.incomingOutgoing = "Incoming/Outgoing selection is required";
    setErrors(newErrors);

    return (
      !newErrors.searchText &&
      !newErrors.selectedEngine &&
      !newErrors.dateRange &&
      !newErrors.incomingOutgoing
    );
  };

  const handleSearch = () => {
    if (validateInputs()) {
      // Show the modal for confirmation
      const modal = new bootstrap.Modal(
        document.getElementById("confirmationModal")
      );
      modal.show();
    }
  };

  const confirmSearch = () => {
    // Reformat the dateRange
    const formattedDateRange = dateRange.replace(" to ", " - ");

    // Prepare the request data
    const requestData = {
      seach_query: searchText,
      dateRange: formattedDateRange, // Use the formatted date range
      engine: selectedEngine,
      faxType: incomingOutgoing === "OUTGOING" ? "Outgoing" : "Incoming",
    };

    // Log the search request data
    console.log("Search initiated with:", requestData);

    actionSearchFaxDocument(requestData);

    // Close the modal after confirming the search
    const modal = bootstrap.Modal.getInstance(
      document.getElementById("confirmationModal")
    );
    modal.hide();
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 main_heading">Search Document</h4>
                <div className="page-title-right">
                  <DefaultView />
                </div>
              </div>
            </div>
          </div>
          {/* end page title */}
          <div className="row">
            <div className="col-lg-12">
              <div className="alert alert-info" role="alert">
                Each search request with a standard engine will cost you 1 cent.
                Premium will cost 2 cents.
              </div>
              <div className="border p-3 rounded">
                <div className="row mb-3">
                  <div className="col-md-12">
                    <input
                      type="search"
                      className={`form-control ${
                        errors.searchText ? "is-invalid" : ""
                      }`}
                      placeholder="Search..."
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                    {errors.searchText && (
                      <div className="text-danger">{errors.searchText}</div>
                    )}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-3">
                    <label className="form-label">Select Engine</label>
                    <select
                      className={`form-select ${
                        errors.selectedEngine ? "is-invalid" : ""
                      }`}
                      value={selectedEngine}
                      onChange={(e) => setSelectedEngine(e.target.value)}
                    >
                      <option value="">Select Engine</option>
                      <option value="STANDARD">Standard</option>
                      <option value="PREMIUM">Premium</option>
                    </select>
                    {errors.selectedEngine && (
                      <div className="text-danger">{errors.selectedEngine}</div>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label className="form-label">Incoming/Outgoing</label>
                    <select
                      className={`form-select ${
                        errors.incomingOutgoing ? "is-invalid" : ""
                      }`}
                      value={incomingOutgoing}
                      onChange={(e) => setIncomingOutgoing(e.target.value)}
                    >
                      <option value="">Select Type</option>
                      <option value="INCOMING">Incoming</option>
                      <option value="OUTGOING">Outgoing</option>
                    </select>
                    {errors.incomingOutgoing && (
                      <div className="text-danger">
                        {errors.incomingOutgoing}
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex flex-column">
                      <label className="form-label">Date Range:</label>
                      <input
                        type="text"
                        className={`form-control flatpickr-input ${
                          errors.dateRange ? "is-invalid" : ""
                        }`}
                        id="datepicker-range"
                        readOnly="readonly"
                        ref={datepickerRef}
                      />
                      {errors.dateRange && (
                        <div className="text-danger">{errors.dateRange}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-2 d-flex align-items-end justify-content-end">
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={handleSearch}
                    >
                      Search <i className="bx bx-search-alt align-middle"></i>
                    </button>
                  </div>
                </div>
              </div>

              {!getOcrRequestAvailabilityLoader &&
                ocrRequestsData &&
                ocrRequestsData.length > 0 && (
                  <div className="table-responsive mt-5">
                    <div className="d-flex align-items-center gap-3 mb-2">
                      <b>Requests</b>
                      <button
                        onClick={() => actionGetOcrRequestAvailability()}
                        className="btn p-1 btn-outline-info"
                        // style={{ background: "red" }}
                      >
                        <i
                          className="bx bx-refresh align-middle"
                          style={{ fontSize: 20 }}
                        />
                      </button>
                    </div>
                    <table className="invoiceTable table table-bordered table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline">
                      <thead className="table-light">
                        <tr role="row">
                          <th>Request ID</th>
                          <th>Start Time</th>
                          <th>End Time</th>
                          <th>Search String</th>
                          <th>Search Engine</th>
                          <th>Processing</th>
                          <th>Preparing</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ocrRequestsData.map((record, index) => (
                          <SearchRequestTableRow
                            key={index}
                            requestResultFilePath={
                              record.request_result_file_path
                            }
                            requestID={record.request_ID}
                            startTime={record.request_start_time}
                            endTime={record.request_completion_time}
                            searchString={record.request_search_string}
                            searchEngine={record.request_engine}
                            status={record.request_status}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}

              {getOcrRequestAvailabilityLoader && (
                <div className="table-responsive mt-5">
                  <div>Loading Requests...</div>
                </div>
              )}

              {getOcrResultData && getOcrResultData.length > 0 && (
                <div className="table-responsive mt-5">
                  <table className="invoiceTable table table-bordered table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline">
                    <thead className="table-light">
                      <tr role="row">
                        <th>Document</th>
                        <th>Matches</th>
                        <th>Date</th>
                        <th>From Number</th>
                        <th>To Number</th>
                        <th>Fax Type</th>
                        <th>Download</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Render table rows here */}
                      {getOcrResultData.map((record, index) => (
                        <tr role="row">
                          <td>{record.doc_direction.split("/").pop()}</td>
                          <td className="text-center">{record.numOfMatches}</td>
                          <td className="text-center">
                            {moment(record.date).format("YYYY-MM-DD hh:mm A")}
                          </td>
                          <td className="text-center">{record.from_number}</td>
                          <td className="text-center">{record.to_number}</td>
                          <td className="text-center">{record.fax_type}</td>
                          <td className="text-center">
                            <a
                              className="btn btn-outline-secondary btn-sm delete"
                              href={`${process.env.REACT_APP_BASE_URL}/files${record.doc_direction}`}
                              download
                              target="_blank"
                            >
                              <i
                                className="fas fa-cloud-download-alt"
                                title="download"
                              ></i>
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            {/* end col */}
          </div>
        </div>
        {/* container-fluid */}
      </div>
      {/* End Page-content */}

      {/* Confirmation Modal */}
      <div
        className="modal fade"
        id="confirmationModal"
        tabIndex="-1"
        aria-labelledby="confirmationModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="confirmationModalLabel">
                Confirm Search
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>
                {selectedEngine === "STANDARD" && (
                  <>
                    <div
                      className="alert alert-primary text-center"
                      role="alert"
                    >
                      <i className="bi bi-info-circle"></i> You have selected
                      the
                      <strong> Standard engine</strong>. This will cost you{" "}
                      <strong>1 cent</strong>.
                    </div>
                    <strong>Benefits:</strong>
                    <ul className="list-unstyled">
                      <li>
                        <i className="bi bi-check-circle-fill text-success"></i>{" "}
                        Basic search capabilities
                      </li>
                      <li>
                        <i className="bi bi-check-circle-fill text-success"></i>{" "}
                        Quick results
                      </li>
                    </ul>
                  </>
                )}
                {selectedEngine === "PREMIUM" && (
                  <>
                    <div
                      className="alert alert-warning text-center"
                      role="alert"
                    >
                      <i className="bi bi-info-circle"></i> You have selected
                      the
                      <strong> Premium engine</strong>. This will cost you{" "}
                      <strong>2 cents</strong>.
                    </div>
                    <strong>Benefits:</strong>
                    <ul className="list-unstyled">
                      <li>
                        <i className="bi bi-check-circle-fill text-success"></i>{" "}
                        Advanced search capabilities
                      </li>
                      <li>
                        <i className="bi bi-check-circle-fill text-success"></i>{" "}
                        More accurate results
                      </li>
                      <li>
                        <i className="bi bi-check-circle-fill text-success"></i>{" "}
                        Priority processing
                      </li>
                    </ul>
                  </>
                )}
              </p>
            </div>
            <div className="modal-footer bg-light">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={confirmSearch}
              >
                Confirm Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    searchDocumentLoader: state.inbox.searchDocumentLoader,
    getOcrRequestAvailabilityLoader:
      state.inbox.getOcrRequestAvailabilityLoader,
    ocrRequestsData: state.inbox.ocrRequestsData,
    getOcrResultData: state.inbox.getOcrResultData,
  };
};

export default connect(mapStateToProps, {
  actionSearchFaxDocument,
  actionGetOcrRequestAvailability,
})(SearchDocument);
