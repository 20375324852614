import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  actionDeleteFaxHeader,
  actionUpdateFaxHeader,
} from "../store/actions/settingAction";
import { connect } from "react-redux";
import LoaderGif from "../assets/loader/Rolling.gif";
import moment from "moment";

const FaxHeaderTableRow = ({
  actionDeleteFaxHeader,
  faxHeaderDeleteloder,
  actionUpdateFaxHeader,
  updatefaxHeaderLoader,
  user,
  id,
  name,
  number,
  address,
  date,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [updatedName, setUpdatedName] = useState(name);
  const [updatedNumber, setUpdatedNumber] = useState(number);
  const [updatedAddress, setUpdatedAddress] = useState(address);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleDeleteClick = () => {
    // Display a confirmation modal
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this header?"
    );

    if (isConfirmed) {
      actionDeleteFaxHeader(id);
    } else {
      console.log("Deletion cancelled");
    }
  };

  const handleSaveClick = (e) => {
    e.preventDefault();
    // You can implement the save functionality here
    // For now, let's just print the updated values to the console
    console.log("Updated Name:", updatedName);
    console.log("Updated Number:", updatedNumber);
    console.log("Updated Address:", updatedAddress);
    setIsEditing(false);
    const requestData = {
      // id: id.toString(),
      header_text: updatedName,
      header_fromNumber: updatedNumber,
      company_address: updatedAddress,
    };
    actionUpdateFaxHeader(id, requestData);
    console.log({ requestData });
  };

  const handleRowDoubleClick = () => {
    setIsEditing(true);
  };

  return (
    <tr
      data-id={id}
      style={{ cursor: "pointer" }}
      onDoubleClick={handleRowDoubleClick}
    >
      <td data-field="id" width={"10%"}>
        {isEditing ? (
          <input
            type="text"
            className="form-control"
            value={updatedName}
            onChange={(e) => setUpdatedName(e.target.value.trim())}
          />
        ) : (
          name
        )}
      </td>
      <td data-field="name" width={"10%"}>
        {isEditing ? (
          <input
            type="number"
            className="form-control"
            value={updatedNumber}
            onChange={(e) => setUpdatedNumber(e.target.value.trim())}
          />
        ) : (
          number
        )}
      </td>
      <td data-field="number" width={"10%"}>
        {isEditing ? (
          <input
            type="text"
            className="form-control"
            value={updatedAddress}
            onChange={(e) => setUpdatedAddress(e.target.value.trim())}
          />
        ) : (
          address
        )}
      </td>
      <td data-field="date" width={"10%"}>
        {moment(date).format("YYYY-MM-DD hh:mm A")}
      </td>
      <td width={"10%"}>
        {isEditing ? (
          <Link
            to={"/"}
            className="btn btn-outline-secondary btn-sm edit"
            title="Save"
            onClick={handleSaveClick}
          >
            <i className="fas fa-save" title="Save" />
          </Link>
        ) : (
          <button
            className="btn btn-outline-secondary btn-sm edit"
            onClick={handleEditClick}
          >
            {updatefaxHeaderLoader == id ? (
              <img src={LoaderGif} alt="Loading" />
            ) : (
              <i className="fas fa-pencil-alt" title="Edit" />
            )}
          </button>
        )}

        <button
          className="btn btn-outline-secondary btn-sm delete"
          onClick={handleDeleteClick}
          style={{ marginLeft: 5 }}
        >
          {faxHeaderDeleteloder == id ? (
            <img src={LoaderGif} alt="Loading" />
          ) : (
            <i className={`fas fa-trash-alt`} title="Delete" />
          )}
        </button>
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    faxHeaderDeleteloder: state.setting.faxHeaderDeleteloder,
    updatefaxHeaderLoader: state.setting.updatefaxHeaderLoader,
  };
};

export default connect(mapStateToProps, {
  actionDeleteFaxHeader,
  actionUpdateFaxHeader,
})(FaxHeaderTableRow);
