import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import DefaultView from "../components/DefaultView";
import ListsTableRow from "../components/ListsTableRow";
import { actionGetList } from "../store/actions/authAction";

const Lists = ({ actionGetList, list, getListLoader }) => {
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [searchText, setSearchText] = useState("");

  // Load the list on component mount
  useEffect(() => {
    actionGetList();
  }, [actionGetList]);

  // Filter the records based on the search text
  useEffect(() => {
    if (searchText.trim() === "") {
      setFilteredRecords(list);
    } else {
      const filtered = list.filter((record) =>
        record.list_name.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredRecords(filtered);
    }
  }, [searchText, list]);

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-top justify-content-between">
                <h4 className="mb-sm-0 main_heading">Lists</h4>
                <div className="page-title-right">
                  <DefaultView />
                </div>
              </div>
            </div>
          </div>
          <div className="addNewBtn pb-20">
            <div className="row">
              <div className="col-sm-12">
                <div className="d-flex justify-content-sm-end">
                  <Link to="/add-new-list" className="btn btn-primary">
                    Add New List
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="mb-3 compose-section">
                <div className="card">
                  <div className="card-header">
                    <div className="row responsSearch">
                      <div className="col-sm-6">
                        <h4 className="card-title">Responsive table</h4>
                        <p className="card-title-desc">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </p>
                      </div>
                      <div className="col-sm-4 dataTables_filter">
                        <div className="d-flex align-items-start flex-column">
                          <label>Search:</label>
                          <input
                            type="search"
                            className="form-control"
                            placeholder="Search item"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    {getListLoader ? (
                      <div>Loading...</div>
                    ) : (
                      <div className="table-responsive">
                        <table className="table table-editable table-nowrap align-middle table-edits">
                          <thead>
                            <tr style={{ cursor: "pointer" }}>
                              <th width="10%">List ID</th>
                              <th width="10%">List Name</th>
                              <th width="10%">Description</th>
                              <th width="10%">Status</th>
                              <th width="10%">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredRecords.length > 0 ? (
                              filteredRecords.map((record, i) => (
                                <ListsTableRow
                                  key={record.list_id} // Use unique ID as key
                                  index={i}
                                  listId={record.list_id}
                                  name={record.list_name}
                                  description={record.description}
                                  status={record.is_active}
                                />
                              ))
                            ) : (
                              <tr>
                                <td colSpan="5">No records found.</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* card */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Map the state to props
const mapStateToProps = (state) => {
  return {
    list: state.auth.list,
    getListLoader: state.auth.getListLoader,
  };
};

export default connect(mapStateToProps, {
  actionGetList,
})(Lists);
