import React, { useRef } from "react";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import "jspdf-autotable";

const NumberExportToPDF = ({ data }) => {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const generatePDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A4 size for PDF

    const doc = new jsPDF("p", unit, size);

    doc.autoTable({ html: "#numbers-table" });

    doc.save("FPN-numbers.pdf");
  };

  return (
    <div>
      <div style={{ display: "none" }}>
        <div ref={componentRef}>
          <table id="numbers-table">
            <thead>
              <tr>
                <th>Fax Number</th>
                <th>Activation Date</th>
                <th>Billing Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index}>
                  <td>{row.number}</td>
                  <td>
                    {moment(row.number_activation_date).format("YYYY/MM/DD")}
                  </td>
                  <td>
                    {moment(row.number_activation_date).format("YYYY/MM/DD")}
                  </td>
                  <td>
                    {row.number_status === "enabled" ? "Active" : "Deactive"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="inline">
        <button
          className="btn btn-primary buttons-pdf buttons-html5"
          onClick={generatePDF}
        >
          Download PDF
        </button>
        <button
          onClick={handlePrint}
          className="btn btn-primary buttons-pdf buttons-html5 mx-1"
        >
          <span>Print PDF</span>
        </button>
      </div>
    </div>
  );
};

export default NumberExportToPDF;
