import React, { memo, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import EmailLeftbar from "../components/EmailLeftbar";
import DefaultView from "../components/DefaultView";
import { toast } from "react-toastify";
import { actionComposeFax } from "../store/actions/inboxAction";
import {
  actionGetFaxHeader,
  actionGetAlerts,
  actionGetFromNumbers,
} from "../store/actions/settingAction";
import { connect } from "react-redux";
import moment from "moment";
import TagsInput from "react-tagsinput";

import "react-tagsinput/react-tagsinput.css";
import { isValidNumber, parsePhoneNumberFromString } from "libphonenumber-js";

const ComponseFax = memo(
  ({
    actionGetFaxHeader,
    actionGetAlerts,
    faxHeaderData,
    faxHeaderloder,
    alertLoader,
    alertData,
    user,
    actionComposeFax,
    composeFaxLoader,
    actionGetFromNumbers,
    getFromNumberLoader,
    fromNumbers,
  }) => {
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [radioType, setRadioType] = useState("SEND_NOW");
    const [faxNumber, setFaxNumber] = useState([]);
    const [header, setHeader] = useState("");
    const [fromNumber, setFromNumber] = useState("");
    const [alert, setAlert] = useState("");
    const [dateTime, setDateTime] = useState("");
    const [errors, setErrors] = useState({});

    useEffect(() => {
      actionGetFaxHeader();
      actionGetAlerts();
      actionGetFromNumbers();
    }, [actionGetFaxHeader, actionGetAlerts, actionGetFromNumbers]);

    const Extension = process.env.REACT_APP_ALLOWED_EXTENSIONS.split(", ");

    // useEffect(() => {
    //   if (alertData.length > 0) {
    //     setAlert(alertData[0].alert_ID);
    //   }
    //   if (faxHeaderData.length > 0) {
    //     setHeader(faxHeaderData[0].header_ID);
    //   }
    // }, [alertData, faxHeaderData]);

    const handleInputChange = (e) => {
      setInputValue(e.target.value);
    };

    /* const handleInputKeyDown = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        addValue();
      }
    }; */

    /* const addValue = () => {
      if (inputValue.trim() !== "") {
        const newValues = [...faxNumber, inputValue.trim()];
        if (newValues.length > 5) {
          toast.error("You can only enter a maximum of 5 numbers.");
        } else {
          setFaxNumber(newValues);
        }
        setInputValue("");
      }
    }; */

    /* const removeValue = (index) => {
      const newValues = [...faxNumber];
      newValues.splice(index, 1);
      setFaxNumber(newValues);
    }; */

    const removeFile = (fileToRemove) => {
      const updatedFiles = uploadedFiles.filter(
        (file) => file !== fileToRemove
      );
      setUploadedFiles(updatedFiles);
    };

    const onDrop = useCallback(
      (acceptedFiles) => {
        const filteredFiles = acceptedFiles.filter((file) => {
          const fileExtension = file.name.split(".").pop().toLowerCase();
          return Extension.includes(fileExtension);
        });

        const filesWithPreviews = filteredFiles.map((file) => ({
          file,
          preview: URL.createObjectURL(file),
        }));
        console.log(...uploadedFiles, ...filesWithPreviews);
        setUploadedFiles([...uploadedFiles, ...filesWithPreviews]);
      },
      [uploadedFiles]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      multiple: true,
    });
    const dropzoneStyle = {
      border: "2px dashed var(--bs-gray-400)",
      padding: "50px",
      margin: "20px",
      borderRadius: "6px",
      cursor: "pointer",
    };

    const validateForm = () => {
      const newErrors = {};

      if (!fromNumber.length) {
        newErrors.fromNumber = "Fax From Number is required";
      }
      if (!faxNumber.length) {
        newErrors.faxNumber = "Fax Number(s) are required";
      }
      if (!uploadedFiles.length) {
        newErrors.uploadedFiles = "File(s) are required";
      }
      if (!alert) {
        newErrors.alert = "Alert is required";
      }
      if (!header) {
        newErrors.header = "Header is required";
      }
      if (!dateTime && radioType != "SEND_NOW") {
        newErrors.dateTime = "Date & Time is required";
      }
      // if (uploadedFiles.length <= 0) {
      //   newErrors.uploadedFiles = "File is required";
      // }

      setErrors(newErrors);

      return Object.keys(newErrors).length === 0;
    };

    const handleSave = async (e) => {
      e.preventDefault();
      if (validateForm()) {
        const concatenatedValues = faxNumber.join(",");

        const formData = new FormData();

        formData.append("isSentMsg", true);
        formData.append("msg_fromNumber", fromNumber);
        formData.append("msg_toNumber", concatenatedValues);
        formData.append("msg_senderName", user.user_fullname);
        formData.append("user_ID", user.user_ID);
        formData.append("header_ID", header);
        formData.append("alert_ID", alert);
        formData.append(
          "msg_status",
          radioType == "SCHEDULE" ? "SCHEDULED" : "PENDING"
        );
        radioType == "SCHEDULE" &&
          formData.append(
            "schedule_msg_date_time",
            moment(dateTime).format("YYYY-MM-DD h:mm:ss a")
          );
        uploadedFiles.forEach((fileObj) => {
          formData.append("files", fileObj.file);
        });

        for (let pair of formData.entries()) {
          console.log(pair[0], pair[1]);
        }
        const res = await actionComposeFax(formData, radioType);
        if (res) {
          setUploadedFiles([]);
          setInputValue("");
          setRadioType("SEND_NOW");
          setFaxNumber([]);
          setDateTime("");
          setHeader("");
          setAlert("");
          setFromNumber("");
        }
      } else {
        console.log("Form has validation errors.");
      }
    };

    // Regex for international phone numbers (broad validation)
    const phoneNumberRegex = /^\+?[1-9]\d{1,14}$/;

    // Function to clean up fax numbers
    const cleanNumber = (number) => {
      return number.replace(/[-\s()]/g, ""); // Remove dashes, spaces, and parentheses
    };

    const handleChangeNumber = (tags) => {
      // Limit to max 5 numbers
      if (tags.length > 5) {
        setErrors({ faxNumber: "You can only enter up to 5 fax numbers." });
        return;
      }
      // Clean and validate tags
      const cleanedTags = tags.map((tag) => cleanNumber(tag));

      // Remove duplicates
      const uniqueTags = Array.from(new Set(cleanedTags));

      const invalidTags = uniqueTags.filter(
        (tag) => !phoneNumberRegex.test(tag)
      );

      if (invalidTags.length > 0) {
        setErrors("Some fax numbers are invalid.");
      } else {
        setErrors("");
      }

      setFaxNumber(uniqueTags.filter((tag) => phoneNumberRegex.test(tag)));
    };

    useEffect(() => {
      console.log({ faxNumber });
    }, [faxNumber]);

    return (
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 main_heading">Compose</h4>
                  <div className="page-title-right">
                    <DefaultView />
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}
            <div className="row">
              <div className="col-12">
                {/* Left sidebar */}
                <EmailLeftbar />
                {/* End Left sidebar */}
                {/* Right Sidebar */}
                <div className="email-rightbar mb-3 compose-section">
                  <div className="card">
                    <div className="card-body">
                      {!getFromNumberLoader && fromNumbers.length < 1 && (
                        <div className="row">
                          <div className="col-md-12">
                            <div className="alert alert-info" role="alert">
                              You currently do not have an active fax number.
                              You can purchase one from{" "}
                              <Link to={"/phone-numbers-list"}>
                                <b>here</b>
                              </Link>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* <div className="row">
                        <div className="col-md-6">
                          <TagsInput
                            value={inputTags}
                            onChange={handleChangeNumber}
                            inputProps={{
                              placeholder: "Inset Fax Number and Hit Enter",
                            }}
                            className={`custom-fax-number-input`}
                          />
                          {error && <p style={{ color: "red" }}>{error}</p>}
                        </div>
                      </div> */}
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-1">
                            <label
                              htmlFor="example-text-input"
                              className="form-label"
                            >
                              Fax Number
                            </label>
                            <TagsInput
                              value={faxNumber}
                              onChange={handleChangeNumber}
                              addOnBlur={handleChangeNumber}
                              inputProps={{
                                placeholder: "Inset Fax Number and Hit Enter",
                              }}
                              className={`custom-fax-number-input`}
                            />
                            {/* <input
                              className={`form-control ${
                                errors.faxNumber ? "is-invalid" : ""
                              }`}
                              type="text"
                              placeholder="Inset Fax Number and Hit Enter"
                              value={inputValue}
                              onChange={handleInputChange}
                              onKeyDown={handleInputKeyDown}
                            /> */}
                          </div>
                          {errors.faxNumber && (
                            <div className="text-danger mt-1">
                              {errors.faxNumber}
                            </div>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-1">
                            <label className="form-label">
                              Select From Number
                            </label>
                            <select
                              className={`form-select ${
                                errors.header ? "is-invalid" : ""
                              }`}
                              value={fromNumber}
                              onChange={(e) => setFromNumber(e.target.value)}
                              disabled={getFromNumberLoader}
                            >
                              <option value={""}>Select From Number</option>
                              {fromNumbers &&
                                fromNumbers.map((data, index) => (
                                  <option key={index} value={data}>
                                    {data}
                                  </option>
                                ))}
                            </select>
                          </div>
                          {errors.fromNumber && (
                            <div className="text-danger mt-1">
                              {errors.fromNumber}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-6">
                          <div className="">
                            <label
                              htmlFor="example-date-input"
                              className="form-label"
                            >
                              Select Alert
                            </label>
                            <select
                              className={`form-select ${
                                errors.alert ? "is-invalid" : ""
                              }`}
                              value={alert}
                              onChange={(e) => setAlert(e.target.value)}
                              disabled={alertLoader}
                            >
                              <option value={""}>Select Alert</option>
                              {alertData &&
                                alertData.map((data, index) => (
                                  <option key={index} value={data.alert_ID}>
                                    {data.alert_value}
                                  </option>
                                ))}
                            </select>
                          </div>
                          {errors.alert && (
                            <div className="text-danger mt-1">
                              {errors.alert}
                            </div>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-1">
                            <label
                              htmlFor="example-date-input"
                              className="form-label"
                            >
                              Select Header
                            </label>
                            <select
                              className={`form-select ${
                                errors.header ? "is-invalid" : ""
                              }`}
                              value={header}
                              onChange={(e) => setHeader(e.target.value)}
                              disabled={faxHeaderloder}
                            >
                              <option value={""}>Select Header</option>
                              {faxHeaderData &&
                                faxHeaderData.map((data, index) => (
                                  <option key={index} value={data.header_ID}>
                                    {data.header_text}
                                  </option>
                                ))}
                            </select>
                          </div>
                          {errors.header && (
                            <div className="text-danger mt-1">
                              {errors.header}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-3">
                          <div>
                            <label
                              htmlFor="example-date-input"
                              className="form-label"
                            >
                              Fax Type
                            </label>
                            <div className="radio-button px-1">
                              <div>
                                <input
                                  type="radio"
                                  id="date1"
                                  name="date"
                                  className="form-check-input"
                                  value="Send Now"
                                  checked={radioType == "SEND_NOW"}
                                  onChange={() => setRadioType("SEND_NOW")}
                                />
                                <label
                                  htmlFor="date1"
                                  style={{ marginBottom: 0, marginLeft: "5px" }}
                                >
                                  Send Now
                                </label>
                              </div>
                              <div style={{ marginLeft: "10px" }}>
                                <input
                                  type="radio"
                                  id="date2"
                                  name="date"
                                  className="form-check-input"
                                  value="Schedule"
                                  checked={radioType == "SCHEDULE"}
                                  onChange={() => setRadioType("SCHEDULE")}
                                />
                                <label
                                  htmlFor="date2"
                                  style={{ marginBottom: 0, marginLeft: "5px" }}
                                >
                                  Schedule
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {radioType == "SCHEDULE" && (
                          <div className="col-md-3">
                            <div>
                              <label
                                htmlFor="example-date-input"
                                className="form-label"
                              >
                                Date & Time
                              </label>
                              <input
                                className={`form-control ${
                                  errors.dateTime ? "is-invalid" : ""
                                }`}
                                type="datetime-local"
                                placeholder="YYYY-MM-DD"
                                id="example-date-input"
                                value={dateTime}
                                onChange={(e) => setDateTime(e.target.value)}
                              />
                            </div>
                            {errors.dateTime && (
                              <div className="text-danger mt-1">
                                {errors.dateTime}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="row mt-3">
                        <div className="col-12">
                          <div className="card">
                            <div className="card-header">
                              <h4 className="card-title">Select Files</h4>
                              <div className="alert alert-primary" role="alert">
                                We Accept{" "}
                                <b>
                                  <i>
                                    {process.env.REACT_APP_ALLOWED_EXTENSIONS}
                                  </i>
                                </b>{" "}
                                files only.
                              </div>
                            </div>
                            <div
                              className={`card-body ${
                                isDragActive ? "active-dropzone" : ""
                              }`}
                              {...getRootProps()}
                              style={dropzoneStyle}
                            >
                              <input {...getInputProps()} />
                              <div className="dz-message needsclick">
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bx-cloud-upload" />
                                </div>
                                <h5>Drop files here or click to upload.</h5>
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                        {/* end col */}
                      </div>{" "}
                      {errors.uploadedFiles && (
                        <div className="text-danger mb-2">
                          {errors.uploadedFiles}
                        </div>
                      )}
                      <div className="row">
                        {uploadedFiles.map((uploadedFile, index) => {
                          let previewElement;

                          switch (uploadedFile.file.type) {
                            case "image/jpeg":
                            case "image/png":
                            case "image/gif":
                              previewElement = (
                                <img
                                  className="card-img-top img-fluid"
                                  src={uploadedFile.preview}
                                  alt={`Preview-${index}`}
                                />
                              );
                              break;
                            case "application/pdf":
                              previewElement = (
                                <embed
                                  src={uploadedFile.preview}
                                  type="application/pdf"
                                  width="100%"
                                  height="auto"
                                />
                              );
                              break;
                            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                              previewElement = (
                                <div style={{ textAlign: "center" }}>
                                  Word Document
                                </div>
                              );
                              break;
                            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                              previewElement = (
                                <div style={{ textAlign: "center" }}>
                                  Excel document
                                </div>
                              );
                              break;
                            case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
                              previewElement = (
                                <div style={{ textAlign: "center" }}>
                                  PowerPoint Presentation
                                </div>
                              );
                              break;
                            // Add more cases for additional file types as needed
                            default:
                              // Render a default preview for unknown file types
                              previewElement = (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  {/* Unknown File Type: {uploadedFile.file.type} */}
                                  File Selected{" "}
                                  <i
                                    style={{ marginTop: 10 }}
                                    className="fa fa-file fa-lg"
                                  />
                                </div>
                              );
                          }

                          return (
                            <div className="col-xl-2 col-6" key={index}>
                              <div className="card">
                                {previewElement}
                                <div className="py-2 text-center">
                                  <span
                                    className="text-danger flex-grow-1 text-center fw-medium"
                                    onClick={() => removeFile(uploadedFile)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Remove
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      {/* end row */}
                      {composeFaxLoader ? (
                        <button
                          type="button"
                          className="btn btn-primary waves-light waves-effect"
                        >
                          <i
                            className="fas fa-spinner fa-spin"
                            title="Loading"
                          ></i>
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary waves-light waves-effect "
                          onClick={handleSave}
                          disabled={composeFaxLoader}
                        >
                          Send
                          <i className="mdi mdi-send ms-2" />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                {/* card */}
              </div>
              {/* end Col */}
            </div>
            {/* end row */}
          </div>
          {/* container-fluid */}
        </div>
        {/* End Page-content */}
      </div>
    );
  }
);

const mapStateToProps = (state) => {
  return {
    faxHeaderData: state.setting.faxHeaderData,
    faxHeaderloder: state.setting.faxHeaderloder,
    alertLoader: state.setting.alertLoader,
    alertData: state.setting.alertData,
    composeFaxLoader: state.inbox.composeFaxLoader,
    user: state.auth.user,
    getFromNumberLoader: state.auth.getFromNumberLoader,
    fromNumbers: state.auth.fromNumbers,
  };
};

export default connect(mapStateToProps, {
  actionGetFaxHeader,
  actionGetAlerts,
  actionComposeFax,
  actionGetFromNumbers,
})(ComponseFax);
