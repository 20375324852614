import React, { useEffect } from "react";
import DefaultView from "../components/DefaultView";
import { actionGetFaxQueues } from "../store/actions/sentFaxesAction";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

const Queues = ({ queuesDataLoader, queuesData, actionGetFaxQueues, user }) => {
  const { type } = useParams();
  useEffect(() => {
    user && actionGetFaxQueues(user.user_ID, type);
  }, [user]);

  return (
    <div className="page-content">
      <div className="container-fluid">
        {/* start page title */}
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 main_heading">
                {type == "IN" ? "Incoming" : "Outgoing"} Queues{" "}
              </h4>
              <div className="page-title-right">
                <DefaultView />
              </div>
            </div>
          </div>
        </div>
        {/* end page title */}
        <div className="row">
          <div className="col-lg-12">
            {/* start card */}
            <div className="invoiceCard mb-3 mt-4">
              <div className="row align-items-end">
                <div className="col-sm-12 col-md-12 justify-content-end d-sm-flex">
                  <div className="mb-3 dt-buttons btn-groups flex-wrap "></div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                  {type == "IN" ? "Incoming" : "Outgoing"}
                </h4>
                <p className="card-title-desc">
                  {type == "IN" ? "Incoming" : "Outgoing"} fax queues will be
                  listed here.
                </p>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  {queuesDataLoader && <p>Loading...</p>}
                  {!queuesDataLoader && queuesData.length > 0 && (
                    <table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>From Number</th>
                          <th>To Number</th>
                          <th>Total Pages</th>
                          <th>Received Time</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {queuesData.map((queueData, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{queueData.msg_fromNumber}</td>
                            <td>{queueData.msg_toNumber}</td>
                            <td>{queueData.msg_totalPages}</td>
                            <td>{queueData.msg_requestTime}</td>
                            <td>{queueData.fax_status}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                  {!queuesDataLoader && queuesData.length < 1 && (
                    <p>Data not found.</p>
                  )}
                </div>
              </div>
              {/* end card body */}
            </div>
            {/* end card */}
          </div>
          {/* end col */}
        </div>
      </div>
      {/* container-fluid */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    queuesDataLoader: state.sentFax.queuesDataLoader,
    queuesData: state.sentFax.queuesData,
  };
};
export default connect(mapStateToProps, {
  actionGetFaxQueues,
})(Queues);
