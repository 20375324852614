import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Link, useNavigate } from "react-router-dom";
import { actionAddFaxCover } from "../store/actions/settingAction";
import { connect } from "react-redux";
import fileIcon from "../assets/svg-icons/file-svg-icon.svg";

const FaxCoverForm = ({ actionAddFaxCover, addfaxCoverloder, user }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [companyNumber, setCompanyNumber] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {}, [user]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      console.log({ acceptedFiles });
      const filesWithPreviews = acceptedFiles.map((file) => ({
        file,
        preview: URL.createObjectURL(file),
      }));
      setUploadedFiles([...uploadedFiles, ...filesWithPreviews]);
    },
    [uploadedFiles]
  );

  const removeFile = (fileToRemove) => {
    const updatedFiles = uploadedFiles.filter((file) => file !== fileToRemove);
    setUploadedFiles(updatedFiles);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!companyName.trim()) {
      newErrors.companyName = "Company Name is required";
    }

    if (!companyNumber.trim()) {
      newErrors.companyNumber = "Company Number is required";
    }
    if (uploadedFiles.length <= 0) {
      newErrors.uploadedFiles = "File is required";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const navigate = useNavigate();

  const handleSave = (e) => {
    // You can add your save logic here
    // For example, you can send the uploadedFiles, companyName, and companyNumber to your server
    // or perform any other action you need.
    e.preventDefault();
    if (validateForm()) {
      const formData = new FormData();

      formData.append("company_name", companyName);
      formData.append("company_number", companyNumber);
      formData.append(
        "file",
        new Blob([uploadedFiles[0]], { type: "*" }),
        uploadedFiles[0].file.name
      );
      actionAddFaxCover(formData, navigate);
    } else {
      console.log("Form has validation errors.");
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  const buttons = [
    { label: "Inbox", icon: "fa fa-inbox" },
    { label: "Exclamation", icon: "fa fa-exclamation-circle" },
    { label: "Trash", icon: "far fa-trash-alt" },
  ];

  const dropzoneStyle = {
    border: "2px dashed var(--bs-gray-400)",
    padding: "50px",
    margin: "20px",
    borderRadius: "6px",
    cursor: "pointer",
  };

  const downloadButtonStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <div className="email-rightbar mb-3 compose-section">
      <div className="card">
        <div className="card-header">
          <h5>Create Cover</h5>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="companyName" className="form-label">
                  Company Name
                </label>
                <input
                  id="companyName"
                  className={`form-control ${
                    errors.companyName ? "is-invalid" : ""
                  }`}
                  type="text"
                  placeholder="Enter Company Name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
                {errors.companyName && (
                  <div className="text-danger">{errors.companyName}</div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="companyNumber" className="form-label">
                  Company Number
                </label>
                <input
                  id="companyNumber"
                  className={`form-control ${
                    errors.companyNumber ? "is-invalid" : ""
                  }`}
                  type="number"
                  placeholder="Enter Company Number"
                  value={companyNumber}
                  onChange={(e) => setCompanyNumber(e.target.value)}
                />
                {errors.companyNumber && (
                  <div className="text-danger">{errors.companyNumber}</div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Upload Cover File</h4>
                  <p className="card-title-desc">
                    * You will be able to upload only single file
                  </p>
                </div>
                {uploadedFiles && uploadedFiles.length == 0 && (
                  <div
                    className={`card-body ${
                      isDragActive ? "active-dropzone" : ""
                    }`}
                    {...getRootProps()}
                    style={dropzoneStyle}
                  >
                    <input {...getInputProps()} />
                    <div className="dz-message needsclick">
                      <div className="mb-3">
                        <i className="display-4 text-muted bx bx-cloud-upload" />
                      </div>
                      <h5>Drop file here or click to upload.</h5>
                    </div>
                  </div>
                )}
              </div>
              {errors.uploadedFiles && (
                <div className="text-danger mb-2">{errors.uploadedFiles}</div>
              )}
            </div>
          </div>

          <div className="row">
            {uploadedFiles.map((uploadedFile, index) => (
              <div className="col-xl-2 col-6" key={index}>
                <div className="card">
                  <div className="card-body">
                    {uploadedFile.file.type.startsWith("image/") ? (
                      <img
                        className="card-img-top img-fluid"
                        src={uploadedFile.preview}
                        alt={`Preview-${index}`}
                      />
                    ) : (
                      <img
                        className="card-img-top img-fluid"
                        src={fileIcon}
                        alt={`Preview-${index}`}
                      />
                    )}

                    <div
                      className="py-2 text-center"
                      style={downloadButtonStyle}
                    >
                      {/*  <Link
                      download={uploadedFile.file.name}
                      to={uploadedFile.preview}
                      className="text-primary me-2 flex-grow-1 text-center fw-medium"
                    >
                      Download
                    </Link> */}
                      <span
                        className="text-danger  flex-grow-1 text-center fw-medium"
                        onClick={() => removeFile(uploadedFile)}
                        style={{ cursor: "pointer" }}
                      >
                        Remove
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            <div className="col-12">
              <div className="text-left">
                {addfaxCoverloder ? (
                  <button
                    type="button"
                    className="btn btn-primary waves-light waves-effect"
                  >
                    <i className="fas fa-spinner fa-spin" title="Loading"></i>
                  </button>
                ) : (
                  <button
                    onClick={handleSave}
                    type="submit"
                    className="btn btn-primary waves-light waves-effect"
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    addfaxCoverloder: state.setting.addfaxCoverloder,
  };
};

export default connect(mapStateToProps, {
  actionAddFaxCover,
})(FaxCoverForm);
