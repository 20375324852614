import React from "react";

function InvoiceTableRow({ index, jobTitle, salary, hireDate }) {
  return (
    <tr className={index % 2 === 0 ? "even" : "odd"}>
      <td className="dtr-control sorting_1" tabIndex={0}>
        {index + 1}
      </td>
      <td>{jobTitle}</td>
      <td>${salary}</td>
      <td>{hireDate}</td>
      <td>
        <div className="d-flex btnsTwo justify-content-center">
          <button>
            <i className="fa fa-file-pdf" />
          </button>
        </div>
      </td>
    </tr>
  );
}

export default InvoiceTableRow;
