import React, { useState, useEffect } from "react";
import { actionAddFaxHeader } from "../store/actions/settingAction";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const FaxHeaderForm = ({ actionAddFaxHeader, addfaxHeaderloder, user }) => {
  const [formData, setFormData] = useState({
    headerName: "",
    headerNumber: "",
    companyAddress: "",
  });

  const [errors, setErrors] = useState({});
  useEffect(() => {}, [user]);

  const validateForm = () => {
    const newErrors = {};

    if (formData.headerName.trim() === "") {
      newErrors.headerName = "Header Name is required";
    }

    if (formData.headerNumber.trim() === "") {
      newErrors.headerNumber = "Header Number is required";
    }

    if (formData.companyAddress.trim() === "") {
      newErrors.companyAddress = "Company Address is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      const requestData = {
        header_text: formData.headerName,
        header_fromNumber: formData.headerNumber,
        company_address: formData.companyAddress,
      };
      actionAddFaxHeader(requestData, navigate);
      console.log("Form data:", requestData);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  return (
    <div className="email-rightbar mb-3 compose-section">
      <div className="card">
        <div className="card-header">
          <h5>Create Header</h5>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="headerName" className="form-label">
                    Header Name
                  </label>
                  <input
                    className={`form-control ${
                      errors.headerName ? "is-invalid" : ""
                    }`}
                    type="text"
                    id="headerName"
                    name="headerName"
                    placeholder="Enter Header Name"
                    value={formData.headerName}
                    onChange={handleChange}
                  />
                  {errors.headerName && (
                    <div className="invalid-feedback">{errors.headerName}</div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="headerNumber" className="form-label">
                    Header Number
                  </label>
                  <input
                    className={`form-control ${
                      errors.headerNumber ? "is-invalid" : ""
                    }`}
                    type="number"
                    id="headerNumber"
                    name="headerNumber"
                    placeholder="Enter Header Number"
                    value={formData.headerNumber}
                    onChange={handleChange}
                  />
                  {errors.headerNumber && (
                    <div className="invalid-feedback">
                      {errors.headerNumber}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="text-fax">
                  <div className="mb-3">
                    <label htmlFor="companyAddress" className="form-label">
                      Company Address
                    </label>
                    <textarea
                      className={`form-control ${
                        errors.companyAddress ? "is-invalid" : ""
                      }`}
                      id="companyAddress"
                      name="companyAddress"
                      placeholder="Enter Company Address"
                      value={formData.companyAddress}
                      onChange={handleChange}
                    />
                    {errors.companyAddress && (
                      <div className="invalid-feedback">
                        {errors.companyAddress}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="right-side-btns d-flex justify-content-end">
              <div className="btn-group">
                {addfaxHeaderloder ? (
                  <button
                    type="button"
                    className="btn btn-primary waves-light waves-effect"
                  >
                    <i className="fas fa-spinner fa-spin" title="Loading"></i>
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary waves-light waves-effect"
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    addfaxHeaderloder: state.setting.addfaxHeaderloder,
  };
};

export default connect(mapStateToProps, {
  actionAddFaxHeader,
})(FaxHeaderForm);
