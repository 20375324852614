import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ContactTableRow from "../components/ContactTableRow";
import DefaultView from "../components/DefaultView";
import { actionGetContacts } from "../store/actions/authAction";
import { connect } from "react-redux";

const Contacts = ({ actionGetContacts, contacts, getContactLoader }) => {
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const filtered =
      contacts &&
      contacts.filter((contact) =>
        contact.contact_name.toLowerCase().includes(searchText.toLowerCase())
      );
    setFilteredRecords(filtered);
  }, [contacts, searchText]);

  useEffect(() => {
    actionGetContacts();
  }, [actionGetContacts]);

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 main_heading">Contact List</h4>
                <div className="page-title-right">
                  <DefaultView />
                </div>
              </div>
            </div>
          </div>
          {/* end page title */}
          <div className="addNewBtn pb-20">
            <div className="row">
              <div className="col-sm-12">
                <div className="d-flex justify-content-sm-end">
                  <Link to="/add-new-contact" className="btn btn-primary">
                    Add New Contact
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="mb-3 compose-section">
                <div className="card marginExtra">
                  <div className="card-header">
                    <div className="row responsSearch">
                      <div className="col-md-6">
                        <h4 className="card-title">Responsive table</h4>
                        <p className="card-title-desc">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </p>
                      </div>
                      <div
                        id="datatable-buttons_filter"
                        className="col-sm-4 dataTables_filter"
                      >
                        <div className="d-flex align-items-start flex-column">
                          <label>Search:</label>
                          <input
                            type="search"
                            className="form-control"
                            placeholder="Search item"
                            aria-controls="datatable-buttons"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      {getContactLoader ? (
                        <div className="text-center">Loading contacts...</div>
                      ) : (
                        <table className="table table-editable table-nowrap align-middle table-edits">
                          <thead>
                            <tr style={{ cursor: "pointer" }}>
                              <th>Contact Name</th>
                              <th>Contact Number</th>
                              <th>Contact Email</th>
                              <th>Created on</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredRecords.length > 0 ? (
                              filteredRecords.map((record, i) => (
                                <ContactTableRow
                                  key={record.contact_id}
                                  contactId={record.contact_id}
                                  name={record.contact_name}
                                  number={record.contact_number}
                                  email={record.contact_email}
                                  created_at={record.created_at}
                                />
                              ))
                            ) : (
                              <tr>
                                <td colSpan="5" className="text-center">
                                  No contacts found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    contacts: state.auth.contacts,
    getContactLoader: state.auth.getContactLoader,
  };
};

export default connect(mapStateToProps, {
  actionGetContacts,
})(Contacts);
