import React, { useState } from "react";
import { Bell, Moon, Sun } from "react-feather";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { actionUpdateSetting, logoutUser } from "../store/actions/authAction";

const Header = ({
  user,
  actionUpdateSetting,
  userSettingLoader,
  logoutUser,
}) => {
  const [darkMode, setDarkMode] = useState(false);
  const navigation = useNavigate();

  const handleDarkMode = () => {
    const body = document.body;
    if (darkMode) {
      // Remove attributes if they are already added
      //body.removeAttribute("data-layout");
      body.removeAttribute("class");
      body.removeAttribute("data-layout-mode");
      body.removeAttribute("data-topbar");
      body.removeAttribute("data-sidebar");
    } else {
      // Add attributes if they are not added
      //body.setAttribute("data-layout", "horizontal");
      body.setAttribute(
        "class",
        "pace-running pace-running pace-done cz-shortcut-listen"
      );
      body.setAttribute("data-layout-mode", "dark");
      body.setAttribute("data-topbar", "dark");
      body.setAttribute("data-sidebar", "dark");
    }

    // Toggle the darkMode state to track whether attributes are added or removed
    setDarkMode(!darkMode);
  };
  return (
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          {/* LOGO */}
          <div className="navbar-brand-box">
            <Link to="/" className="logo logo-dark">
              <span className="logo-sm">
                <img src="/assets/images/logo.png" alt="" height={40} />
              </span>
              <span className="logo-lg">
                <img src="/assets/images/logo.png" alt="" height={40} />
              </span>
            </Link>
            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img src="/assets/images/logo.png" alt="" height={40} />
              </span>
              <span className="logo-lg">
                <img src="/assets/images/logo.png" alt="" height={40} />
              </span>
            </Link>
          </div>
          <button
            type="button"
            className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
            data-bs-toggle="collapse"
            data-bs-target="#topnav-menu-content"
          >
            <i className="fa fa-fw fa-bars" />
          </button>
          {/* App Search*/}
        </div>
        <div className="d-flex">
          <div className="dropdown d-inline-block d-lg-none ms-2">
            <button
              type="button"
              className="btn header-item"
              id="page-header-search-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i data-feather="search" className="icon-lg" />
            </button>
            <div
              className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
              aria-labelledby="page-header-search-dropdown"
            >
              <form className="p-3">
                <div className="form-group m-0">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search ..."
                      aria-label="Search Result"
                    />
                    <button className="btn btn-primary" type="submit">
                      <i className="mdi mdi-magnify" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="dropdown d-none d-sm-inline-block">
            <button
              type="button"
              className="btn header-item"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                id="header-lang-img"
                src="/assets/images/flags/us.jpg"
                alt="Header Language"
                height={16}
              />
            </button>
            <div className="dropdown-menu dropdown-menu-end">
              {/* item*/}
              <Link
                to="/"
                className="dropdown-item notify-item language"
                data-lang="en"
              >
                <img
                  src="/assets/images/flags/us.jpg"
                  alt="user-i"
                  className="me-1"
                  height={12}
                />{" "}
                <span className="align-middle">English</span>
              </Link>
              {/* item*/}
              <Link
                to="/"
                className="dropdown-item notify-item language"
                data-lang="sp"
              >
                <img
                  src="/assets/images/flags/spain.jpg"
                  alt="user-i"
                  className="me-1"
                  height={12}
                />
                <span className="align-middle">Spanish</span>
              </Link>
              {/* item*/}
              <Link
                to="/"
                className="dropdown-item notify-item language"
                data-lang="gr"
              >
                <img
                  src="/assets/images/flags/germany.jpg"
                  alt="user-i"
                  className="me-1"
                  height={12}
                />
                <span className="align-middle">German</span>
              </Link>
              {/* item*/}
              <Link
                to="/"
                className="dropdown-item notify-item language"
                data-lang="it"
              >
                <img
                  src="/assets/images/flags/italy.jpg"
                  alt="user-img"
                  className="me-1"
                  height={12}
                />

                <span className="align-middle">Italian</span>
              </Link>
              {/* item*/}
              <Link
                to="/"
                className="dropdown-item notify-item language"
                data-lang="ru"
              >
                <img
                  src="/assets/images/flags/russia.jpg"
                  alt="user-img"
                  className="me-1"
                  height={12}
                />
                <span className="align-middle">Russian</span>
              </Link>
            </div>
          </div>
          <div className="dropdown d-none d-sm-inline-block">
            <button
              type="button"
              className="btn header-item"
              id="mode-setting-btn"
              onClick={handleDarkMode}
            >
              {darkMode ? (
                <Sun className="icon-lg layout-mode-light" />
              ) : (
                <Moon className="icon-lg layout-mode-dark" />
              )}
            </button>
          </div>

          <div className="dropdown d-inline-block">
            <button
              type="button"
              className="btn header-item noti-icon position-relative"
              id="page-header-notifications-dropdown-1"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <Bell className="icon-lg" />
              <span className="badge bg-danger rounded-pill">5</span>
            </button>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
              <div className="p-3">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="m-0"> Notifications </h6>
                  </div>
                  <div className="col-auto">
                    <Link
                      to="#!"
                      className="small text-reset text-decoration-underline"
                    >
                      {" "}
                      Unread (3)
                    </Link>
                  </div>
                </div>
              </div>
              <div style={{ maxHeight: 230 }}>
                <Link to="#!" className="text-reset notification-item">
                  <div className="d-flex">
                    <img
                      src="/assets/images/users/avatar-3.jpg"
                      className="me-3 rounded-circle avatar-sm"
                      alt="user-p"
                    />
                    <div className="flex-grow-1">
                      <h6 className="mb-1">James Lemire</h6>
                      <div className="font-size-13 text-muted">
                        <p className="mb-1">
                          It will seem like simplified English.
                        </p>
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline" />{" "}
                          <span>1 hour ago</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="#!" className="text-reset notification-item">
                  <div className="d-flex">
                    <div className="avatar-sm me-3">
                      <span className="avatar-title bg-primary rounded-circle font-size-16">
                        <i className="bx bx-cart" />
                      </span>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mb-1">Your order is placed</h6>
                      <div className="font-size-13 text-muted">
                        <p className="mb-1">
                          If several languages coalesce the grammar
                        </p>
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline" />{" "}
                          <span>3 min ago</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="#!" className="text-reset notification-item">
                  <div className="d-flex">
                    <div className="avatar-sm me-3">
                      <span className="avatar-title bg-success rounded-circle font-size-16">
                        <i className="bx bx-badge-check" />
                      </span>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mb-1">Your item is shipped</h6>
                      <div className="font-size-13 text-muted">
                        <p className="mb-1">
                          If several languages coalesce the grammar
                        </p>
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline" />{" "}
                          <span>3 min ago</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="p-2 border-top d-grid">
                <Link
                  className="btn btn-sm btn-link font-size-14 text-center"
                  to="#"
                >
                  <i className="mdi mdi-arrow-right-circle me-1" />{" "}
                  <span>View More..</span>
                </Link>
              </div>
            </div>
          </div>

          <div className="dropdown d-inline-block">
            <button
              type="button"
              className="btn header-item bg-soft-light border-start border-end"
              id="page-header-user-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                className="rounded-circle header-profile-user"
                src="/assets/images/users/avatar-1.jpg"
                alt="Header Avatar"
              />
              <span className="d-none d-xl-inline-block ms-1 fw-medium">
                {user && user.user_fullname}
              </span>
              <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
            </button>
            <div className="dropdown-menu dropdown-menu-end">
              {/* item*/}
              <Link className="dropdown-item" to="/profile">
                <i className="mdi mdi-face-profile font-size-16 align-middle me-1" />{" "}
                Profile
              </Link>
              <div className="dropdownOpen">
                <button className="dropdown-item">
                  <i className="mdi mdi-face-profile font-size-16 align-middle me-1" />{" "}
                  Billing
                </button>
                <Link className="dropdown-item" to="/billing/add-fund">
                  <i className="mdi mdi-face-profile font-size-16 align-middle me-1" />{" "}
                  Add Funds
                </Link>
                <Link className="dropdown-item" to="/invoices">
                  <i className="mdi mdi-face-profile font-size-16 align-middle me-1" />{" "}
                  Invoices
                </Link>
              </div>
              <Link className="dropdown-item" to="/auth-lock">
                <i className="mdi mdi-lock font-size-16 align-middle me-1" />{" "}
                Lock screen
              </Link>
              <div className="dropdown-divider" />
              <Link
                className="dropdown-item"
                to="/auth-lock"
                onClick={(e) => {
                  e.preventDefault();
                  logoutUser();
                }}
              >
                <i className="mdi mdi-logout font-size-16 align-middle me-1" />{" "}
                Logout
              </Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    userSettingLoader: state.auth.userSettingLoader,
  };
};

export default connect(mapStateToProps, { actionUpdateSetting, logoutUser })(
  Header
);
