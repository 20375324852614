import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import DefaultView from "../components/DefaultView";
import EmailLeftbar from "../components/EmailLeftbar";
import EmailListItem from "../components/EmailListItem";
import {
  actionGetAllTrashedMessages,
  actionRemoveMessageFromTrash,
} from "../store/actions/inboxAction";
import ReadEmail from "./ReadEmail";

const Trash = memo(
  ({
    actionGetAllTrashedMessages,
    getTrashedMessagesLoader,
    trashedMessages,
    trashedMessagesCount,
    removeFromTrashLoader,
    actionRemoveMessageFromTrash,
  }) => {
    const [searchText, setSearchText] = useState("");
    const [filteredEmailListItems, setFilteredEmailListItems] = useState([]);
    const [showEmailDetailID, setShowEmailDetailID] = useState("");

    const offset = useRef(0);
    const [itemsPerPage] = useState(20);

    const { pathname } = useLocation();

    useEffect(() => {
      actionGetAllTrashedMessages(offset.current);
    }, [pathname, actionGetAllTrashedMessages]);

    const handleSearch = () => {
      actionGetAllTrashedMessages(0, searchText);
      offset.current = 0;
    };

    useEffect(() => {
      const filtered =
        trashedMessages &&
        trashedMessages.filter((record) => {
          return record.msg.msg_senderName
            .toLowerCase()
            .includes(searchText.toLowerCase());
        });

      setFilteredEmailListItems(filtered);
    }, [searchText]);

    useMemo(() => {
      setFilteredEmailListItems(
        trashedMessages.map((msg) => ({ ...msg, isSelected: false }))
      );
      if (trashedMessages.length < 1) {
        // setOffset(offset);
      }
    }, [trashedMessages]);

    const handleRefresh = () => {
      offset.current = 0;
      setSearchText("");
      actionGetAllTrashedMessages(offset.current);
    };

    const handleNextClick = () => {
      offset.current += 1;
      actionGetAllTrashedMessages(offset.current, searchText ? searchText : "");
    };

    const handleBackClick = () => {
      const newOffset = offset.current - 1;
      console.log({ newOffset, offset: offset.current });
      actionGetAllTrashedMessages(newOffset, searchText ? searchText : "");
      offset.current = newOffset;
    };

    const handleSelectThisPage = () => {
      const pageData = filteredEmailListItems.map((m) => ({
        ...m,
        isSelected: true,
      }));
      setFilteredEmailListItems(pageData);
    };

    const handleDeSelectAll = () => {
      setFilteredEmailListItems(
        filteredEmailListItems.map((m) => ({
          ...m,
          isSelected: false,
        }))
      );
    };

    const handleSelectMessage = (index) => {
      console.log("handleSelectMessage called", index);
      const newFilteredEmailListItems = filteredEmailListItems.map((m, i) => {
        if (index === i) {
          return { ...m, isSelected: !m.isSelected };
        } else {
          return m;
        }
      });
      setFilteredEmailListItems(newFilteredEmailListItems);
    };

    const handleRemoveFromTrash = () => {
      const selectedIds = filteredEmailListItems
        .filter((m) => m.isSelected)
        .map((m) => m.msg.trash_msg_ID);
      console.log({ selectedIds });
      const getMessageParams = {
        offset: offset.current,
        searchText,
      };

      actionRemoveMessageFromTrash(
        {
          trash_msg_ID: selectedIds,
        },
        getMessageParams
      );
    };

    const showingFrom = offset.current * itemsPerPage + 1;
    const showingTo = Math.min(
      (offset.current + 1) * itemsPerPage,
      trashedMessagesCount
    );

    const BackAndNextButtonsGroup = () => (
      <div className="btn-group float-end">
        <button
          type="button"
          className="btn btn-sm btn-success waves-effect"
          onClick={handleBackClick}
          disabled={offset.current === 0}
        >
          <i className="fa fa-chevron-left" />
        </button>
        <button
          type="button"
          className="btn btn-sm btn-success waves-effect"
          onClick={handleNextClick}
          disabled={
            (offset.current + 1) * itemsPerPage >= trashedMessagesCount ||
            !trashedMessagesCount ||
            !filteredEmailListItems.length
          }
        >
          <i className="fa fa-chevron-right" />
        </button>
      </div>
    );

    return showEmailDetailID ? (
      <ReadEmail
        detailId={showEmailDetailID}
        setShowEmailDetailID={setShowEmailDetailID}
        type="TRASH"
        getMessageParams={{
          offset: offset.current,
          searchText,
        }}
      />
    ) : (
      <div className="page-content">
        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 main_heading">Trash</h4>
                <div className="page-title-right">
                  <DefaultView />
                </div>
              </div>
              <div className="d-flex justify-content-end mb-3">
                <BackAndNextButtonsGroup />
              </div>
            </div>
          </div>
          {/* end page title */}
          <div className="row">
            <div className="col-12">
              {/* Left sidebar */}
              <EmailLeftbar />
              {/* End Left sidebar */}

              {/* Right Sidebar */}
              <div className="email-rightbar mb-3">
                <div className="card">
                  <div
                    className="btn-toolbar gap-2 p-3 d-flex justify-content-between"
                    role="toolbar"
                  >
                    <div>
                      <div className="btn-group" style={{ marginRight: 3 }}>
                        <button
                          type="button"
                          className="btn btn-primary waves-light waves-effect dropdown-toggle d-flex"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div className="checkbox-wrapper-mail">
                            <input type="checkbox" id={`chk_select`} />
                            <label htmlFor={`chk_select`} className="toggle" />
                          </div>{" "}
                          <i className="mdi mdi-chevron-down ms-1" />
                        </button>
                        <div className="dropdown-menu">
                          <div
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                            onClick={handleSelectThisPage}
                          >
                            Select This Page
                          </div>
                          <div
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                            onClick={handleDeSelectAll}
                          >
                            None
                          </div>
                        </div>
                      </div>
                      <div className="btn-group">
                        {filteredEmailListItems.filter((m) => m.isSelected)
                          .length > 0 && (
                          <>
                            {
                              <button
                                onClick={handleRemoveFromTrash}
                                title="Delete from Trash"
                                type="button"
                                className="btn btn-primary waves-light waves-effect"
                              >
                                {removeFromTrashLoader ? (
                                  <i
                                    className="fas fa-spinner fa-spin"
                                    title="Loading"
                                  ></i>
                                ) : (
                                  <i className="far fa-trash-alt" />
                                )}
                              </button>
                            }
                          </>
                        )}
                      </div>
                      <div
                        className="btn btn-primary"
                        style={{ cursor: "pointer", marginLeft: 10 }}
                        onClick={() => handleRefresh()}
                      >
                        <i className="fa fa-refresh" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div className="d-flex">
                      <form
                        className="app-search d-none d-lg-block"
                        style={{ float: "left" }}
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSearch();
                        }}
                      >
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            value={searchText}
                            onChange={(e) => {
                              setSearchText(e.target.value);
                              if (e.target.value.trim() === "") {
                                actionGetAllTrashedMessages(offset.current);
                              }
                            }}
                          />
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={handleSearch}
                          >
                            <i className="bx bx-search-alt align-middle" />
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <ul className="message-list faxMessage">
                    {!getTrashedMessagesLoader &&
                      filteredEmailListItems.length < 1 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <h5>No Data Found!</h5>
                        </div>
                      )}
                    {getTrashedMessagesLoader && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h5>Loading data..</h5>
                      </div>
                    )}
                    {!getTrashedMessagesLoader &&
                      filteredEmailListItems.map((item, index) => (
                        <EmailListItem
                          key={index}
                          index={index}
                          senderName={item.msg.msg_senderName}
                          senderPhone={item.msg.msg_fromNumber}
                          noOfPages={
                            item.msg.msg_totalPages ? item.msg.msg_totalPages : 0
                          }
                          date={item.msg.msg_time}
                          isUnread={1}
                          isStarred={"NO"}
                          isChecked={item.isSelected}
                          handleSelectMessage={handleSelectMessage}
                          detailId={item.msg.trash_msg_ID}
                          setShowEmailDetailID={setShowEmailDetailID}
                          //handleStarred={handleStarred}
                        />
                      ))}
                  </ul>
                </div>{" "}
                {/* card */}
                <div className="row">
                  <div className="col-7">
                    Showing {trashedMessagesCount > 0 ? showingFrom : 0} -{" "}
                    {trashedMessagesCount > 0 ? showingTo : 0} of{" "}
                    {trashedMessagesCount ? trashedMessagesCount : 0}
                  </div>
                  <div className="col-5">
                    <BackAndNextButtonsGroup />
                  </div>
                </div>
              </div>
              {/* end  Right Sidebar Col-9 */}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

const mapStateToProps = (state) => {
  return {
    getTrashedMessagesLoader: state.inbox.getTrashedMessagesLoader,
    trashedMessages: state.inbox.trashedMessages,
    trashedMessagesCount: state.inbox.trashedMessagesCount,
    removeFromTrashLoader: state.inbox.removeFromTrashLoader,
  };
};

export default connect(mapStateToProps, {
  actionGetAllTrashedMessages,
  actionRemoveMessageFromTrash,
})(Trash);
