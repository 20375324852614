import * as actionTypes from "../action";

const initialState = {
  getSentMessagesLoader: false,
  sentMessages: [],
  sentMessagesCount: 0,
  addSentMessageImportantLoader: false,
  removeSentMessageImportantLoader: false,
  markSentMessageReadLoader: false,
  markSentMessageUnreadLoader: false,
  markSendTrashMessageLoader: false,
  queuesDataLoader: false,
  queuesData: [],
  getFaxQueuesCountLoader: false,
  inFaxQueuesCount: 0,
  outFaxQueues: 0,
};

const sentFaxesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SENT_MESSAGES_LOADER:
      return { ...state, getSentMessagesLoader: action.payload };

    case actionTypes.SENT_MESSAGES_DATA:
      return {
        ...state,
        sentMessages: action.payload.messages,
        sentMessagesCount: parseFloat(action.payload.totalCount),
      };

    case actionTypes.MARK_READ_SENT_MESSAGES_LOADER:
      return { ...state, markSentMessageReadLoader: action.payload };

    case actionTypes.MARK_READ_SENT_MESSAGES:
      return {
        ...state,
        sentMessages: state.sentMessages.map((m) => {
          if (action.payload.includes(m.msg.sent_msg_ID)) {
            return { ...m, msg: { ...m.msg, is_read: "1" } };
          } else {
            return m;
          }
        }),
      };
    case actionTypes.MARK_UNREAD_SENT_MESSAGES_LOADER:
      return { ...state, markSentMessageUnreadLoader: action.payload };

    case actionTypes.MARK_UNREAD_SENT_MESSAGES:
      return {
        ...state,
        sentMessages: state.sentMessages.map((m) => {
          if (action.payload.includes(m.msg.sent_msg_ID)) {
            return { ...m, msg: { ...m.msg, is_read: "0" } };
          } else {
            return m;
          }
        }),
      };

    case actionTypes.MARK_ADD_IMPORTANT_LOADER_SENT_MESSAGES:
      return { ...state, addSentMessageImportantLoader: action.payload };

    case actionTypes.MARK_ADD_IMPORTANT_SENT_MESSAGES:
      return {
        ...state,
        sentMessages: state.sentMessages.map((m) => {
          if (action.payload.includes(m.msg.sent_msg_ID)) {
            return { ...m, isSelected: false };
          } else {
            return m;
          }
        }),
      };

    case actionTypes.MARK_REMOVE_IMPORTANT_LOADER_SENT_MESSAGES:
      return { ...state, removeSentMessageImportantLoader: action.payload };

    case actionTypes.MARK_REMOVE_IMPORTANT_SENT_MESSAGES:
      return {
        ...state,
        sentMessages: state.sentMessages.map((m) => {
          if (action.payload.includes(m.msg.sent_msg_ID)) {
            return { ...m, isSelected: false };
          } else {
            return m;
          }
        }),
      };

    case actionTypes.MARK_ADD_STARRED_SENT_MESSAGES:
      return {
        ...state,
        sentMessages: state.sentMessages.map((m) => {
          if (action.payload.sent_msg_ID === m.msg.sent_msg_ID) {
            return { ...m, msg: { ...m.msg, is_starred: "1" } };
          } else {
            return m;
          }
        }),
      };

    case actionTypes.MARK_REMOVE_STARRED_SENT_MESSAGES:
      return {
        ...state,
        sentMessages: state.sentMessages.map((m) => {
          if (action.payload.sent_msg_ID === m.msg.sent_msg_ID) {
            return { ...m, msg: { ...m.msg, is_starred: "0" } };
          } else {
            return m;
          }
        }),
      };

    case actionTypes.GET_FAX_QUEUES_LOADER:
      return { ...state, queuesDataLoader: action.payload };

    case actionTypes.FAX_QUEUES_DATA:
      return { ...state, queuesData: action.payload };

    case actionTypes.GET_FAX_QUEUES_COUNT_LOADER:
      return { ...state, getFaxQueuesCountLoader: action.payload };

    case actionTypes.FAX_QUEUES_COUNT_DATA:
      return {
        ...state,
        inFaxQueuesCount: action.payload.inFaxQueues.length,
        outFaxQueuesCount: action.payload.outFaxQueues.length,
      };

    default:
      return state;
  }
};
export default sentFaxesReducer;
