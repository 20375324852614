import React from "react";
import { Link, useLocation } from "react-router-dom";

const EmailLeftbar = () => {
  const { pathname } = useLocation();
  return (
    <div className="email-leftbar card">
      <Link to="/compose-fax" className="compose_link">
        <button
          type="button"
          className="btn btn-danger w-100 waves-effect waves-light"
        >
          Compose
        </button>
      </Link>
      <div className="mail-list mt-4">
        <ul className="mail-sub">
          <Link to="/inbox" className={`${pathname == "/inbox" && "active"}`}>
            <i className="mdi mdi-email-outline me-2" /> Inbox{" "}
            {/* <span className="ms-1 float-end">(18)</span> */}
          </Link>
          <>
            <Link
              to="/outbox"
              className={`${pathname == "/outbox" && "active"}`}
            >
              <i className="mdi mdi-email-check-outline me-2" />
              Oubox
            </Link>
            <Link
              to="/sent-faxes"
              className={`${pathname == "/sent-faxes" && "active"}`}
            >
              <i className="mdi mdi-email-send-outline me-2" />
              Sent Faxes
            </Link>
            <Link to="/trash" className={`${pathname == "/trash" && "active"}`}>
              <i className="mdi mdi-trash-can-outline me-2" />
              Trash
            </Link>

            <Link
              to="/failed"
              className={`${pathname == "/failed" && "active"}`}
            >
              <i className="mdi mdi-close-outline me-2" />
              Failed
            </Link>
            <Link
              to="/scheduled"
              className={`${pathname == "/scheduled" && "active"}`}
            >
              <i className="mdi mdi-clock-outline me-2" />
              Scheduled
            </Link>
          </>
        </ul>
      </div>
      <h6 className="mt-4">Settings</h6>
      <div className="mail-list mt-2">
        <ul className="mail-sub">
          <Link to="/fax-covers">
            <i className="mdi mdi mdi-file-outline me-2" />
            Fax Covers
          </Link>
          <Link to="/fax-alerts">
            <i className="mdi mdi mdi-alert-outline me-2" />
            Fax Alerts
          </Link>
          <Link to="/fax-headers">
            <i className="mdi mdi mdi-file-outline me-2" />
            Fax Headers
          </Link>
          <Link to="/fax-blacklist">
            <i className="fa fa-ban me-2" aria-hidden="true" />
            Fax Blacklist
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default EmailLeftbar;
