import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { actionDeleteFaxCover } from "../store/actions/settingAction";
import LoaderGif from "../assets/loader/Rolling.gif";
import moment from "moment";

const FaxCoverTableRow = ({
  actionDeleteFaxCover,
  faxCoverDeleteloder,
  id,
  name,
  date,
  number,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [updatedId, setUpdatedId] = useState(number);
  const [updatedName, setUpdatedName] = useState(name);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = (e) => {
    e.preventDefault();
    // You can implement the save functionality here
    // For now, let's just print the updated values to the console
    console.log("Updated ID:", updatedId);
    console.log("Updated Name:", updatedName);
    setIsEditing(false);
  };

  const handleRowDoubleClick = () => {
    setIsEditing(true);
  };

  const handleDeleteClick = () => {
    // Display a confirmation modal
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this cover?"
    );

    if (isConfirmed) {
      actionDeleteFaxCover(id);
    } else {
      console.log("Deletion cancelled");
    }
  };

  return (
    <tr data-id={id} style={{ cursor: "pointer" }}>
      <td data-field="name" width={"30%"}>
        {isEditing ? (
          <input
            type="text"
            className="form-control"
            value={updatedName}
            onChange={(e) => setUpdatedName(e.target.value)}
          />
        ) : (
          name
        )}
      </td>
      <td data-field="id" width={"30%"}>
        {isEditing ? (
          <input
            type="text"
            className="form-control"
            value={updatedId}
            onChange={(e) => setUpdatedId(e.target.value)}
          />
        ) : (
          number
        )}
      </td>
      <td style={{ width: "200px" }}>
        {moment(date).format("YYYY-MM-DD hh:mm A")}
      </td>
      <td>
        {/* {isEditing ? (
          <Link
            to={"/"}
            className="btn btn-outline-secondary btn-sm edit"
            title="Save"
            onClick={handleSaveClick}
          >
            <i className="fas fa-save" title="Save" />
          </Link>
        ) : (
          <button
            className="btn btn-outline-secondary btn-sm edit"
            onClick={handleEditClick}
          >
            <i className="fas fa-pencil-alt" title="Edit" />
          </button>
        )} */}
        <button
          className="btn btn-outline-secondary btn-sm delete"
          onClick={handleDeleteClick}
          style={{ marginLeft: 5 }}
        >
          {faxCoverDeleteloder == id ? (
            <img src={LoaderGif} alt="Loading" />
          ) : (
            <i className={`fas fa-trash-alt`} title="Delete" />
          )}
        </button>
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    faxCoverDeleteloder: state.setting.faxCoverDeleteloder,
  };
};

export default connect(mapStateToProps, {
  actionDeleteFaxCover,
})(FaxCoverTableRow);
