import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import { actionCreateList } from "../store/actions/authAction";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import sampleFile from "../assets/sample-contact.xlsx";

const AddNewList = ({ actionCreateList, createListLoader }) => {
  const [formData, setFormData] = useState({
    listName: "",
    description: "",
  });

  const [formErrors, setFormErrors] = useState({
    listName: "",
  });

  const [uploadedContacts, setUploadedContacts] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...formErrors };

    if (formData.listName.trim() === "") {
      newErrors.listName = "List Name is required";
      isValid = false;
    }

    setFormErrors(newErrors);
    return isValid;
  };

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const contacts = XLSX.utils.sheet_to_json(worksheet);

      // Add validation for email and phone number
      const validatedContacts = contacts.map((contact) => ({
        name: contact["Contact Name"],
        email: contact["Contact Email"],
        number: contact["Contact Number"],
        isEmailValid: validateEmail(contact["Contact Email"]),
        isNumberValid: validatePhoneNumber(contact["Contact Number"]),
      }));

      setUploadedContacts(validatedContacts);
    };
    reader.readAsArrayBuffer(file);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (number) => {
    const phoneRegex = /^\+?[0-9]{1,15}$/; // Adjust regex for more strict validation if needed
    return phoneRegex.test(number);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ], // Excel 2007+
      "application/vnd.ms-excel": [".xls"], // Excel 97-2003
    },
    onDropRejected: (rejectedFiles) => {
      Swal.fire({
        icon: "error",
        title: "Invalid file type",
        text: "Please upload an Excel file (.xls or .xlsx).",
      });
    },
  });

  const handleRemoveContact = (index) => {
    const updatedContacts = uploadedContacts.filter((_, i) => i !== index);
    setUploadedContacts(updatedContacts);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Filter contacts who have valid email and valid phone number
      const validContacts = uploadedContacts.filter(
        (file) => file.isEmailValid && file.isNumberValid
      );

      if (validContacts.length === 0) {
        Swal.fire({
          icon: "error",
          title: "No valid contacts found",
          text: "Please check the uploaded data.",
        });
        return;
      }

      // Create the request object with only valid contacts
      const requestData = {
        list_name: formData.listName,
        description: formData.description,
        contacts: validContacts.map((contact) => ({
          contact_name: contact.name,
          contact_number: contact.number,
          contact_email: contact.email,
        })),
      };

      // Call the action to create the list
      actionCreateList(requestData)
        .then((response) => {
          // Assuming actionCreateList returns a promise
          // Reset the form after successful API call
          setFormData({
            listName: "",
            description: "",
          });
          setUploadedContacts([]);
          setFormErrors({
            listName: "",
          });
          console.log("Form data with valid contacts submitted:", requestData);
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
        });
    }
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-6">
              <div className="page-title-box d-sm-flex align-items-top justify-content-between">
                <h4 className="mb-sm-0 main_heading">Add New List</h4>
              </div>
            </div>
            <div className="col-6">
              <div className="page-title-box d-sm-flex align-items-top justify-content-end">
                <a href={sampleFile} download className="btn btn-info">
                  Download Sample Contacts
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="mb-3 compose-section">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="listName" className="form-label">
                              List Name
                            </label>
                            <input
                              className={`form-control ${
                                formErrors.listName ? "is-invalid" : ""
                              }`}
                              type="text"
                              id="listName"
                              name="listName"
                              placeholder="Enter List Name"
                              value={formData.listName}
                              onChange={handleInputChange}
                            />
                            {formErrors.listName && (
                              <div className="invalid-feedback">
                                {formErrors.listName}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="description" className="form-label">
                              Description
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              id="description"
                              name="description"
                              placeholder="Enter Description"
                              value={formData.description}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <label className="form-label">Upload Excel File</label>

                        <div {...getRootProps()} className="dropzone">
                          <input {...getInputProps()} />
                          <div className="dz-message needsclick">
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bx-cloud-upload" />
                            </div>
                            <h5>Drop Excel file here or click to upload.</h5>
                          </div>
                        </div>
                      </div>

                      {uploadedContacts.length > 0 && (
                        <div className="mt-4">
                          <h5>Uploaded Contacts:</h5>
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Contact Name</th>
                                <th>Contact Email</th>
                                <th>Email Valid</th>
                                <th>Contact Number</th>
                                <th>Number Valid</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {uploadedContacts.map((contact, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{contact.name}</td>
                                  <td>{contact.email}</td>
                                  <td>
                                    {contact.isEmailValid ? (
                                      <span className="text-success">
                                        Valid
                                      </span>
                                    ) : (
                                      <span className="text-danger">
                                        Invalid
                                      </span>
                                    )}
                                  </td>
                                  <td>{contact.number}</td>
                                  <td>
                                    {contact.isNumberValid ? (
                                      <span className="text-success">
                                        Valid
                                      </span>
                                    ) : (
                                      <span className="text-danger">
                                        Invalid
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <button
                                      type="button"
                                      className="btn btn-danger btn-sm"
                                      onClick={() => handleRemoveContact(index)}
                                    >
                                      Remove
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                      <div className="d-flex justify-content-end mt-4 mb-4">
                        <div className="right-side-btns">
                          {createListLoader ? (
                            <button className="btn btn-primary" disabled>
                              <i
                                className="fas fa-spinner fa-spin"
                                title="Loading"
                              ></i>
                            </button>
                          ) : (
                            <button type="submit" className="btn btn-primary">
                              Save
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    createListLoader: state.auth.createListLoader,
  };
};

export default connect(mapStateToProps, {
  actionCreateList,
})(AddNewList);
