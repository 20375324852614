import { combineReducers } from "redux";
import authReducer from "./authReducer";
import inboxReducer from "./inboxReducer";
import settingReducer from "./settingReducer";
import sentFaxesReducer from "./sentFaxesReducer";
import referralReducer from "./referralReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  inbox: inboxReducer,
  setting: settingReducer,
  sentFax: sentFaxesReducer,
  referral: referralReducer,
});

export default rootReducer;
