import React from "react";
import { Link } from "react-router-dom";

const Footer = () => (
  <footer className="footer">
    <div className="container-fluid">
      <div className="row" style={{ paddingBottom: 20 }}>
        <div className="col-sm-6" style={{ textAlign: "left" }}>
          <ul className="list-inline mb-0">
            <li className="list-inline-item sicon">
              <Link
                to="https://www2.foipin.com/login/linkedin"
                className="social-list-item bg-info text-white border-info"
              >
                <img src="/assets/images/youtube.png" alt="YouTube" />
              </Link>
            </li>
            <li className="list-inline-item sicon">
              <Link
                to="https://www2.foipin.com/login/linkedin"
                className="social-list-item bg-info text-white border-info"
              >
                <img src="/assets/images/insta.png" alt="Instagram" />
              </Link>
            </li>
            <li className="list-inline-item sicon">
              <Link
                to="https://www2.foipin.com/login/facebook"
                className="social-list-item bg-primary text-white border-primary"
              >
                <img src="/assets/images/twitter.png" alt="Twitter" />
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-sm-6" style={{ textAlign: "right", paddingTop: 7 }}>
          © {new Date().getFullYear()}, www.foipin.com
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
