import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Select from "react-select";
import makeAnimated from "react-select/animated";

// import "choices.js/public/assets/styles/choices.min.css";
import Choices from "choices.js";
import DefaultView from "../components/DefaultView";
import { connect } from "react-redux";
import { toast as message } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import {
  actionAddVerifiedEmail,
  actionVerifyEmail,
} from "../store/actions/authAction";

const AddVerifiedEmail = ({
  user,
  actionAddVerifiedEmail,
  addVerifiedEmailLoader,
  actionVerifyEmail,
  verifyEmailLoader,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [errors, setErrors] = useState({});
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [resendTimeout, setResendTimeout] = useState(null);

  const handleEmailChange = (e) => setEmail(e.target.value);

  const handleSubmit = () => {
    const validationErrors = {};
    if (!email.trim()) {
      validationErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      validationErrors.email = "Please enter a valid email address";
    }

    // If there are errors, set them in state and prevent form submission
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    actionAddVerifiedEmail(user.user_ID, email, nextTab);
  };

  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
  };

  const nextTab = () => {
    if (activeTab < 2) {
      handleTabChange(activeTab + 1);
    }
  };

  const previousTab = () => {
    if (activeTab > 0) {
      handleTabChange(activeTab - 1);
    }
  };

  const handleChangeOtp = (e) => setOtp(e.target.value);

  const handleVerifyOtp = () => {
    actionVerifyEmail(user.user_ID, email, otp, nextTab);
  };

  const handleResendOtp = () => {
    // Logic to resend OTP goes here (e.g., API call)
    actionAddVerifiedEmail(user.user_ID, email);

    setIsResendDisabled(true); // Disable the button for 30 seconds
    setResendTimeout(30); // Set the initial timeout

    const countdown = setInterval(() => {
      setResendTimeout((prev) => {
        if (prev === 1) {
          clearInterval(countdown);
          setIsResendDisabled(false); // Enable button after 30 seconds
          return null;
        }
        return prev - 1;
      });
    }, 1000);
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 main_heading">Add Email</h4>
                <div className="page-title-right">
                  <DefaultView />
                </div>
              </div>
            </div>
          </div>
          {/* end page title */}
          <div className="row">
            <div className="col-lg-12">
              {/* start card */}
              <div className="card mt-4">
                {/* <div className="card-header">
                  <h4 className="card-title mb-0">
                    Verify Phone / Fax Number to redirect our fax service
                  </h4>
                </div> */}
                <div className="card-body">
                  <div id="progrss-wizard" className="twitter-bs-wizard">
                    <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                      <li
                        className="nav-item"
                        //onClick={() => setActiveTab(0)}
                      >
                        <span
                          className={
                            activeTab === 0 ? "nav-link active" : "nav-link"
                          }
                          data-toggle="tab"
                          style={{ cursor: "pointer" }}
                        >
                          <div
                            className="step-icon"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Seller Details"
                          >
                            <i className="fa fa-hashtag"></i>
                          </div>
                        </span>
                      </li>
                      <li
                        className="nav-item"
                        //onClick={() => setActiveTab(1)}
                      >
                        <span
                          className={
                            activeTab === 1 ? "nav-link active" : "nav-link"
                          }
                          data-toggle="tab"
                          style={{ cursor: "pointer" }}
                        >
                          <div
                            className="step-icon"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Company Document"
                          >
                            <i
                              className="fa fa-envelope"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </span>
                      </li>
                      <li
                        className="nav-item"
                        //onClick={() => setActiveTab(2)}
                      >
                        <span
                          className={
                            activeTab === 2 ? "nav-link active" : "nav-link"
                          }
                          data-toggle="tab"
                          style={{ cursor: "pointer" }}
                        >
                          <div
                            className="step-icon"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Bank Details"
                          >
                            <i className="fa fa-check"></i>
                          </div>
                        </span>
                      </li>
                    </ul>

                    {/* wizard-nav */}
                    <div id="bar" className="progress mt-4">
                      <div
                        className="progress-bar bg-success progress-bar-striped progress-bar-animated"
                        style={{
                          width: `${(activeTab + 1) * 33.3333}%`,
                        }}
                      ></div>
                    </div>
                    <div className="tab-content twitter-bs-wizard-tab-content">
                      <div
                        className={`tab-pane ${activeTab === 0 && "active"}`}
                        id="progress-seller-details"
                      >
                        <div className="text-center mb-4">
                          <h5>Add Email</h5>
                          <p className="card-title-desc">
                            You will receive a One-Time Password (OTP) email
                            inbox.
                          </p>
                        </div>
                        {/* BODY 1 */}
                        <form style={{ width: 400, margin: "auto" }}>
                          <div className="form-group mt-3">
                            <input
                              type="text"
                              className="form-control"
                              id="Email"
                              value={email}
                              onChange={handleEmailChange}
                              placeholder="Enter Email"
                            />
                            {errors.email && (
                              <span className="error text-danger">
                                {errors.email}
                              </span>
                            )}
                          </div>
                        </form>
                        {/* BODY 1 Ends  */}
                        <ul className="custom-pager-buttons">
                          <li
                            className={
                              activeTab === 0 ? "next" : "next disabled"
                            }
                          >
                            {addVerifiedEmailLoader ? (
                              <button
                                type="button"
                                className="btn btn-primary"
                                disabled={true}
                              >
                                <div
                                  className="spinner-border spinner-border-sm mx-2"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleSubmit}
                                disabled={!email}
                              >
                                Next{" "}
                                <i className="bx bx-chevron-right ms-1"></i>
                              </button>
                            )}
                          </li>
                        </ul>
                      </div>
                      <div
                        className={`tab-pane ${activeTab === 1 && "active"}`}
                        id="progress-company-document"
                      >
                        <div>
                          <div className="text-center mb-4">
                            <h5>Verify Email</h5>
                            <p className="card-title-desc">
                              Make sure your email is on and has a stable
                              connection. The OTP should be sent to the email.
                            </p>
                          </div>
                          {/* BODY 2 */}
                          <form style={{ width: 400, margin: "auto" }}>
                            <div className="form-group">
                              <div className="form-group mt-3">
                                <label>OTP sent on {email}</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="emailOtp"
                                  value={otp}
                                  onChange={handleChangeOtp}
                                  placeholder="Enter OTP"
                                />
                              </div>
                              <div className="d-flex justify-content-end">
                                <span
                                  style={{
                                    color: isResendDisabled ? "gray" : "blue",
                                    cursor: isResendDisabled
                                      ? "not-allowed"
                                      : "pointer",
                                  }}
                                  onClick={
                                    !isResendDisabled ? handleResendOtp : null
                                  }
                                >
                                  Resend OTP
                                </span>
                                {resendTimeout && (
                                  <span
                                    className="text-muted"
                                    style={{ marginLeft: "8px" }}
                                  >
                                    in {resendTimeout}s
                                  </span>
                                )}
                              </div>
                            </div>
                          </form>
                          {/* BODY 2 Ends  */}
                          <ul className="custom-pager-buttons">
                            <li
                              className={
                                activeTab === 1
                                  ? "previous"
                                  : "previous disabled"
                              }
                            >
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={previousTab}
                              >
                                <i className="bx bx-chevron-left me-1"></i>{" "}
                                Previous
                              </button>
                            </li>
                            <li
                              className={
                                activeTab === 2 ? "next" : "next disabled"
                              }
                            >
                              {verifyEmailLoader ? (
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  disabled={true}
                                >
                                  <div
                                    className="spinner-border spinner-border-sm mx-2"
                                    role="status"
                                  >
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </div>
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={handleVerifyOtp}
                                  disabled={otp.length < 6}
                                >
                                  Verify
                                  <i className="bx bx-chevron-right ms-1"></i>
                                </button>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        className={`tab-pane ${activeTab === 2 && "active"}`}
                        id="progress-bank-detail"
                      >
                        <div>
                          <div className="text-center mb-4">
                            <h5>{email} Successfully Added </h5>
                            <p className="card-title-desc">
                              Your email has been verified and added
                            </p>
                          </div>
                          {/* BODY 3 */}
                          {/* BODY 3 Ends  */}
                          <ul className="custom-pager-buttons">
                            <li
                              className={
                                activeTab === 2
                                  ? "previous"
                                  : "previous disabled"
                              }
                            >
                              {/* <button
                                type="button"
                                className="btn btn-primary"
                                onClick={previousTab}
                              >
                                <i className="bx bx-chevron-left me-1"></i>{" "}
                                Previous
                              </button> */}
                            </li>
                            <li className="float-end">
                              <Link
                                to="/add-verified-email"
                                className="btn btn-primary"
                                style={{ marginRight: 15 }}
                              >
                                Add New Email
                              </Link>
                              <Link
                                to="/verified-email-list"
                                className="btn btn-primary"
                              >
                                View All
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card body */}
              </div>
              {/* end card */}
            </div>
            {/* end col */}
          </div>
        </div>
        {/* container-fluid */}
      </div>
      {/* End Page-content */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    addVerifiedEmailLoader: state.auth.addVerifiedEmailLoader,
    verifyEmailLoader: state.auth.verifyEmailLoader,
  };
};

export default connect(mapStateToProps, {
  actionAddVerifiedEmail,
  actionVerifyEmail,
})(AddVerifiedEmail);
