import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import DefaultView from "../components/DefaultView";
import EmailLeftbar from "../components/EmailLeftbar";
import EmailListItem from "../components/EmailListItem";
import {
  actionGetReceivedMessages,
  actionMarkAddImportant,
  actionMarkRemoveImportant,
  actionMarkReadMessages,
  actionMarkUnreadMessages,
  actionMarkAddStarred,
  actionMarkRemoveStarred,
  actionMarkTrashMessage,
} from "../store/actions/inboxAction";
import ReadEmail from "./ReadEmail";

const Inbox = memo(
  ({
    actionGetReceivedMessages,
    getReceivedMessagesLoader,
    receivedMessages,
    receivedMessagesCount,
    actionMarkAddImportant,
    actionMarkRemoveImportant,
    addImportantLoader,
    removeImportantLoader,
    markReadLoader,
    markUnreadLoader,
    markTrashLoader,
    actionMarkReadMessages,
    actionMarkUnreadMessages,
    actionMarkAddStarred,
    actionMarkRemoveStarred,
    actionMarkTrashMessage,
  }) => {
    const [searchText, setSearchText] = useState("");
    const [type, setType] = useState("");
    const [listType, setListType] = useState(false);
    const [filteredEmailListItems, setFilteredEmailListItems] = useState([]);
    const [showEmailDetailID, setShowEmailDetailID] = useState("");
    //const [offset, setOffset] = useState(0);

    const offset = useRef(0);
    const [itemsPerPage] = useState(20);

    const { pathname } = useLocation();
    // useEffect(() => {
    //   switch (pathname) {
    //     case "/inbox":
    //       !receivedMessages.length && actionGetReceivedMessages(offset.current);
    //       break;

    //     default:
    //       break;
    //   }
    // }, [pathname, actionGetReceivedMessages]);

    useEffect(() => {
      // Retrieve offset from local storage
      const storedOffset = localStorage.getItem("inboxOffset");
      offset.current = storedOffset ? parseInt(storedOffset, 10) : 0;

      // Perform actions based on the route
      switch (pathname) {
        case "/inbox":
          // Check if receivedMessages is empty or if offset needs to be updated
          if (!receivedMessages.length) {
            actionGetReceivedMessages(offset.current);
          }
          break;

        // Add cases for other routes if needed

        default:
          break;
      }
    }, [pathname, actionGetReceivedMessages]);

    // Save offset to local storage whenever it changes
    useEffect(() => {
      localStorage.setItem("inboxOffset", offset.current.toString());
    }, [offset.current]);

    const handleSearch = () => {
      // Implement your search logic here using 'searchText' state
      actionGetReceivedMessages(0, listType, searchText);
      offset.current = 0;
    };

    useEffect(() => {
      const filtered =
        receivedMessages &&
        receivedMessages.filter((record) => {
          if (pathname == "/inbox") {
            return record.msg.msg_senderName
              .toLowerCase()
              .includes(searchText.toLowerCase());
          } else if (pathname == "/outbox") {
          }
        });

      setFilteredEmailListItems(filtered);
    }, [searchText]);

    useMemo(() => {
      setFilteredEmailListItems(
        receivedMessages.map((msg) => ({ ...msg, isSelected: false }))
      );
      if (receivedMessages.length < 1) {
        // setOffset(offset);
      }
    }, [receivedMessages]);

    const handleRefresh = () => {
      offset.current = 0;
      setSearchText("");
      setListType("");
      actionGetReceivedMessages(offset.current);
    };

    const handleNextClick = () => {
      offset.current += 1;
      actionGetReceivedMessages(
        offset.current,
        listType,
        searchText ? searchText : ""
      );
    };

    const handleBackClick = () => {
      const newOffset = offset.current - 1;
      console.log({ newOffset, offset: offset.current });
      actionGetReceivedMessages(
        newOffset,
        listType,
        searchText ? searchText : ""
      );
      offset.current = newOffset;
    };

    const handleSelectThisPage = (importantType) => {
      const pageData = filteredEmailListItems.map((m) => ({
        ...m,
        isSelected: true,
      }));
      setFilteredEmailListItems(pageData);
      importantType && importantType == "selectAll"
        ? setType("selectAll")
        : importantType == "selectThisPage"
        ? setType("selectThisPage")
        : setType("");
    };

    const handleDeSelectAll = () => {
      setType("");
      setFilteredEmailListItems(
        filteredEmailListItems.map((m) => ({
          ...m,
          isSelected: false,
        }))
      );
    };
    const handleSelectAll = () => {
      handleSelectThisPage("selectAll");
      // TODO: logic for server data change
    };

    const handleSelectMessage = (index) => {
      setType("");
      console.log("handleSelectMessage called", index);
      const newFilteredEmailListItems = filteredEmailListItems.map((m, i) => {
        if (index === i) {
          return { ...m, isSelected: !m.isSelected };
        } else {
          return m;
        }
      });
      setFilteredEmailListItems(newFilteredEmailListItems);
    };
    const handleStarred = (index, type) => {
      const selectedMsg = filteredEmailListItems[index];

      if (selectedMsg) {
        const recvd_msg_ID = selectedMsg.msg.recvd_msg_ID;
        console.log({ recvd_msg_ID });
        type == "starAdd"
          ? actionMarkAddStarred({ recvd_msg_ID })
          : actionMarkRemoveStarred({ recvd_msg_ID });
      }
    };

    const handleMarkReadSelected = () => {
      const selectedIds = filteredEmailListItems
        .filter((m) => m.isSelected)
        .map((m) => m.msg.recvd_msg_ID);
      console.log({ selectedIds });

      type && type == "selectAll"
        ? actionMarkReadMessages({
            recvd_msg_ID: [0],
            select_all: "YES",
          })
        : actionMarkReadMessages({
            recvd_msg_ID: selectedIds,
            select_all: "NO",
          });
    };

    const handleMarkUnReadSelected = () => {
      const selectedIds = filteredEmailListItems
        .filter((m) => m.isSelected)
        .map((m) => m.msg.recvd_msg_ID);
      console.log({ selectedIds });

      type && type == "selectAll"
        ? actionMarkUnreadMessages({
            recvd_msg_ID: [0],
            select_all: "YES",
          })
        : actionMarkUnreadMessages({
            recvd_msg_ID: selectedIds,
            select_all: "NO",
          });
    };

    const handleMarkAddImportant = () => {
      const selectedIds = filteredEmailListItems
        .filter((m) => m.isSelected)
        .map((m) => m.msg.recvd_msg_ID);

      type && type == "selectAll"
        ? actionMarkAddImportant(
            {
              recvd_msg_ID: [0],
              select_all: "YES",
            },
            filteredEmailListItems
          )
        : actionMarkAddImportant(
            {
              recvd_msg_ID: selectedIds,
              select_all: "NO",
            },
            filteredEmailListItems
          );
    };

    const handleMarkRemoveImportant = () => {
      const selectedIds = filteredEmailListItems
        .filter((m) => m.isSelected)
        .map((m) => m.msg.recvd_msg_ID);
      console.log({ selectedIds });
      const getMessageParams = {
        offset: offset.current,
        listType,
        searchText,
      };

      type && type == "selectAll"
        ? actionMarkRemoveImportant(
            {
              recvd_msg_ID: [0],
              select_all: "YES",
            },
            filteredEmailListItems,
            getMessageParams
          )
        : actionMarkRemoveImportant(
            {
              recvd_msg_ID: selectedIds,
              select_all: "NO",
            },
            filteredEmailListItems,
            getMessageParams
          );
    };
    const handleMarkTrash = () => {
      const selectedIds = filteredEmailListItems
        .filter((m) => m.isSelected)
        .map((m) => m.msg.recvd_msg_ID);
      console.log({ selectedIds });
      const getMessageParams = {
        offset: offset.current,
        listType,
        searchText,
      };

      actionMarkTrashMessage(
        {
          recvd_msg_ID: selectedIds,
        },
        getMessageParams
      );
    };

    const showingFrom = offset.current * itemsPerPage + 1;
    const showingTo = Math.min(
      (offset.current + 1) * itemsPerPage,
      receivedMessagesCount
    );

    const BackAndNextButtonsGroup = () => (
      <div className="btn-group float-end">
        <button
          type="button"
          className="btn btn-sm btn-success waves-effect"
          onClick={handleBackClick}
          disabled={offset.current === 0}
        >
          <i className="fa fa-chevron-left" />
        </button>
        <button
          type="button"
          className="btn btn-sm btn-success waves-effect"
          onClick={handleNextClick}
          disabled={
            (offset.current + 1) * itemsPerPage >= receivedMessagesCount ||
            !receivedMessagesCount ||
            !filteredEmailListItems.length
          }
        >
          <i className="fa fa-chevron-right" />
        </button>
      </div>
    );

    return showEmailDetailID ? (
      <ReadEmail
        detailId={showEmailDetailID}
        setShowEmailDetailID={setShowEmailDetailID}
        type="INBOX"
        getMessageParams={{
          offset: offset.current,
          listType,
          searchText,
        }}
      />
    ) : (
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 main_heading">InBox</h4>
                <div className="page-title-right">
                  <DefaultView />
                </div>
              </div>
              <div className="d-flex justify-content-end mb-3">
                <BackAndNextButtonsGroup />
              </div>
              {/* Left sidebar */}
              <EmailLeftbar />
              {/* End Left sidebar */}

              {/* Right Sidebar */}
              <div className="email-rightbar mb-3">
                <div className="card">
                  <div
                    className="btn-toolbar gap-2 p-3 d-flex justify-content-between"
                    role="toolbar"
                  >
                    <div>
                      <div className="btn-group" style={{ marginRight: 3 }}>
                        <button
                          type="button"
                          className="btn btn-primary waves-light waves-effect dropdown-toggle d-flex"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div className="checkbox-wrapper-mail">
                            <input type="checkbox" id={`chk_select`} />
                            <label htmlFor={`chk_select`} className="toggle" />
                          </div>{" "}
                          <i className="mdi mdi-chevron-down ms-1" />
                        </button>
                        <div className="dropdown-menu">
                          <div
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleSelectThisPage("selectThisPage")
                            }
                          >
                            Select This Page
                          </div>
                          <div
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                            onClick={handleSelectAll}
                          >
                            Select All
                          </div>
                          <div
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                            onClick={handleDeSelectAll}
                          >
                            None
                          </div>
                        </div>
                      </div>
                      <div className="btn-group">
                        {filteredEmailListItems.filter((m) => m.isSelected)
                          .length > 0 && (
                          <>
                            <button
                              onClick={handleMarkReadSelected}
                              title="Mark Read"
                              type="button"
                              className="btn btn-primary waves-light waves-effect"
                            >
                              {markReadLoader ? (
                                <i
                                  className="fas fa-spinner fa-spin"
                                  title="Loading"
                                ></i>
                              ) : (
                                <i className="fa fa-envelope-open"></i>
                              )}
                            </button>
                            <button
                              onClick={handleMarkUnReadSelected}
                              title="Mark Unread"
                              type="button"
                              className="btn btn-primary waves-light waves-effect"
                            >
                              {markUnreadLoader ? (
                                <i
                                  className="fas fa-spinner fa-spin"
                                  title="Loading"
                                ></i>
                              ) : (
                                <i className="fa fa-envelope"></i>
                              )}
                            </button>
                            {!listType && (
                              <button
                                onClick={handleMarkAddImportant}
                                title="Mark Important"
                                type="button"
                                className="btn btn-primary waves-light waves-effect"
                              >
                                {addImportantLoader ? (
                                  <i
                                    className="fas fa-spinner fa-spin"
                                    title="Loading"
                                  ></i>
                                ) : (
                                  <i className="fa fa-exclamation-circle" />
                                )}
                              </button>
                            )}
                            <button
                              onClick={handleMarkRemoveImportant}
                              title="Mark Not Important"
                              type="button"
                              className="btn btn-primary waves-light waves-effect"
                            >
                              {removeImportantLoader ? (
                                <i
                                  className="fas fa-spinner fa-spin"
                                  title="Loading"
                                ></i>
                              ) : (
                                <i className="fa fa-times" />
                              )}
                            </button>
                            {type !== "selectAll" && (
                              <button
                                onClick={handleMarkTrash}
                                title="Move to trash"
                                type="button"
                                className="btn btn-primary waves-light waves-effect"
                              >
                                {markTrashLoader ? (
                                  <i
                                    className="fas fa-spinner fa-spin"
                                    title="Loading"
                                  ></i>
                                ) : (
                                  <i className="far fa-trash-alt" />
                                )}
                              </button>
                            )}
                          </>
                        )}
                      </div>
                      <div
                        className="btn btn-primary"
                        style={{ cursor: "pointer", marginLeft: 10 }}
                        onClick={() => handleRefresh()}
                      >
                        <i className="fa fa-refresh" aria-hidden="true"></i>
                      </div>

                      {/* <div class="btn-group">
                                        <button type="button" class="btn btn-primary waves-light waves-effect dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i class="fa fa-tag"></i> <i class="mdi mdi-chevron-down ms-1"></i>
                                        </button>
                                        <div class="dropdown-menu">
                                            <Link class="dropdown-item" to="#">Updates</Link>
                                            <Link class="dropdown-item" to="#">Social</Link>
                                            <Link class="dropdown-item" to="#">Team Manage</Link>
                                        </div>
                                    </div>
                                    
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-primary waves-light waves-effect dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                            More <i class="mdi mdi-dots-vertical ms-2"></i>
                                        </button>
                                        <div class="dropdown-menu">
                                            <Link class="dropdown-item" to="#">Mark as Unread</Link>
                                            <Link class="dropdown-item" to="#">Mark as Important</Link>
                                            <Link class="dropdown-item" to="#">Add to Tasks</Link>
                                            <Link class="dropdown-item" to="#">Add Star</Link>
                                            <Link class="dropdown-item" to="#">Mute</Link>
                                        </div>
                                    </div> */}
                    </div>
                    {/* <div class="md-3" style="padding-right:35%;"> </div> */}
                    <div className="d-flex">
                      <div
                        className="btn-group"
                        style={{ marginRight: 10 }}
                        role="group"
                        aria-label="Basic radio toggle button group"
                      >
                        <input
                          type="radio"
                          className="btn-check"
                          name="btnradio"
                          id="btnradio4"
                        />
                        <button
                          onClick={() => {
                            if (listType) {
                              offset.current = 0;
                              setListType(false);
                              actionGetReceivedMessages(
                                offset.current,
                                "NO",
                                searchText ? searchText : ""
                              );
                            }
                          }}
                          style={{ minWidth: "80px" }}
                          className={`btn ${
                            listType ? "btn-outline-secondary" : "btn-primary"
                          }`}
                          htmlFor="btnradio4"
                        >
                          All
                        </button>
                        <input
                          type="radio"
                          className="btn-check"
                          name="btnradio"
                          id="btnradio5"
                          autoComplete="on"
                          defaultChecked=""
                        />
                        <button
                          onClick={() => {
                            if (!listType) {
                              offset.current = 0;
                              setListType(true);
                              actionGetReceivedMessages(
                                offset.current,
                                "YES",
                                searchText ? searchText : ""
                              );
                            }
                          }}
                          style={{ minWidth: "80px" }}
                          className={`btn ${
                            listType ? "btn-primary" : "btn-outline-secondary"
                          }`}
                          htmlFor="btnradio5"
                        >
                          Important
                        </button>
                      </div>
                      <form
                        className="app-search d-none d-lg-block"
                        style={{ float: "left" }}
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSearch();
                        }}
                      >
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            value={searchText}
                            onChange={(e) => {
                              setSearchText(e.target.value);
                              if (e.target.value.trim() === "") {
                                actionGetReceivedMessages(
                                  offset.current,
                                  listType
                                );
                              }
                            }}
                          />
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={handleSearch}
                          >
                            <i className="bx bx-search-alt align-middle" />
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <ul className="message-list faxMessage">
                    {!getReceivedMessagesLoader &&
                      filteredEmailListItems.length < 1 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <h5>No Data Found!</h5>
                        </div>
                      )}
                    {getReceivedMessagesLoader && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h5>Loading data..</h5>
                      </div>
                    )}
                    {!getReceivedMessagesLoader &&
                      filteredEmailListItems.map((item, index) => (
                        <EmailListItem
                          key={index}
                          index={index}
                          senderName={item.msg.msg_senderName}
                          senderPhone={item.msg.msg_fromNumber}
                          noOfPages={
                            item.msg.msg_totalPages
                              ? item.msg.msg_totalPages
                              : 0
                          }
                          date={item.msg.msg_time}
                          isUnread={parseInt(item.msg.is_read)}
                          isStarred={parseInt(item.msg.is_starred)}
                          isChecked={item.isSelected}
                          handleSelectMessage={handleSelectMessage}
                          handleStarred={handleStarred}
                          detailId={item.msg.recvd_msg_ID}
                          setShowEmailDetailID={setShowEmailDetailID}
                        />
                      ))}
                  </ul>
                </div>{" "}
                {/* card */}
                <div className="row">
                  <div className="col-7">
                    Showing {receivedMessagesCount > 0 ? showingFrom : 0} -{" "}
                    {receivedMessagesCount > 0 ? showingTo : 0} of{" "}
                    {receivedMessagesCount ? receivedMessagesCount : 0}
                  </div>
                  <div className="col-5">
                    <BackAndNextButtonsGroup />
                  </div>
                </div>
              </div>
              {/* end  Right Sidebar Col-9 */}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

const mapStateToProps = (state) => {
  return {
    getReceivedMessagesLoader: state.inbox.getReceivedMessagesLoader,
    receivedMessages: state.inbox.receivedMessages,
    receivedMessagesCount: state.inbox.receivedMessagesCount,
    addImportantLoader: state.inbox.addImportantLoader,
    removeImportantLoader: state.inbox.removeImportantLoader,
    markReadLoader: state.inbox.markReadLoader,
    markUnreadLoader: state.inbox.markUnreadLoader,
    markTrashLoader: state.inbox.markTrashLoader,
  };
};

export default connect(mapStateToProps, {
  actionGetReceivedMessages,
  actionMarkAddImportant,
  actionMarkRemoveImportant,
  actionMarkReadMessages,
  actionMarkUnreadMessages,
  actionMarkAddStarred,
  actionMarkRemoveStarred,
  actionMarkTrashMessage,
})(Inbox);
