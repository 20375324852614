import * as actionTypes from "../action";

const initialState = {
  addReferralLoader: false,
  getReferralLoader: false,
  resendReferralLoader: false,
  referrals: [],
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_REFERRAL_LOADER:
      return { ...state, addReferralLoader: action.payload };

    case actionTypes.GET_REFERRALS_LOADER:
      return { ...state, getReferralLoader: action.payload };

    case actionTypes.GET_REFERRALS_DATA:
      return { ...state, referrals: action.payload };

    case actionTypes.RESEND_REFERRAL_LOADER:
      return { ...state, resendReferralLoader: action.payload };

    default:
      return state;
  }
};
export default authReducer;
