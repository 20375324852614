import React from "react";
import Workbook from "react-excel-workbook";
import moment from "moment";

const NumberExportToExcel = ({ data }) => (
  <Workbook
    filename="FPN-numbers.xlsx"
    element={
      <button
        className="btn btn-success buttons-excel buttons-html5 mx-1"
        tabIndex={0}
        aria-controls="datatable-buttons"
        type="button"        
      >
        <span>Excel</span>
      </button>
    }
  >
    <Workbook.Sheet name="Numbers Data" data={data}>
      <Workbook.Column label="Fax Number" value={(row) => row.number} />
      <Workbook.Column
        label="Activation Date"
        value={(row) => moment(row.number_activation_date).format("YYYY/MM/DD")}
      />
      <Workbook.Column
        label="Billing Date"
        value={(row) => moment(row.number_activation_date).format("YYYY/MM/DD")}
      />
      <Workbook.Column
        label="Status"
        value={(row) =>
          row.number_status == "enabled" ? "Active" : "Deactive"
        }
      />
    </Workbook.Sheet>
  </Workbook>
);

export default NumberExportToExcel;
