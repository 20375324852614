import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import DefaultView from "../components/DefaultView";
import FaxPhoneNumberTableRow from "../components/FaxPhoneNumberTableRow";
import { connect } from "react-redux";
import {
  actionGetVerifiedEmails,
  actionDeleteVerifiedEmail,
} from "../store/actions/authAction";

function VerifiedPhoneFaxNumbersList({
  actionGetVerifiedEmails,
  verifiedEmails,
  getVerifiedEmailLoader,
  actionDeleteVerifiedEmail,
  deleteVerifiedEmail,
  user,
}) {
  const [filterQuery, setFilterQuery] = useState("");
  const [filterQueryResult, setFilterQueryResult] = useState([]);

  useEffect(() => {
    // Check if both start and end dates are selected
    const filtered =
      verifiedEmails &&
      verifiedEmails.filter((record) =>
        record.email.toLowerCase().includes(filterQuery.toLowerCase())
      );
    setFilterQueryResult(filtered);
  }, [filterQuery, verifiedEmails]);

  useEffect(() => {
    user && actionGetVerifiedEmails(user.user_ID);
  }, [user]);

  const handleDeleteEmail = (email) => {
    const userConfirmed = window.confirm(
      "Are you sure you want to delete this email?"
    );

    if (userConfirmed) {
      // Proceed with the delete action
      console.log("email deleted");
      actionDeleteVerifiedEmail(user.user_ID, email);
      // Add your delete logic here
    }
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 main_heading">Verified Emails</h4>
                <div className="page-title-right">
                  <DefaultView />
                </div>
              </div>
            </div>
          </div>
          {/* end page title */}
          <div className="row">
            <div className="col-lg-12">
              {/* start card */}
              <div className="d-flex justify-content-end my-2">
                <Link
                  to={"/add-verified-email"}
                  className="btn btn-primary buttons-html5"
                >
                  Add New Email
                </Link>
              </div>
              <div className="card invoiceCard">
                <div className="card-header">
                  <h4 className="card-title">Email List</h4>
                  <p className="card-title-desc">
                    Here, you will find your verified Emails. Use this email to
                    configure and redirect your fax service.
                  </p>
                </div>
                <div className="row d-none">
                  <div className="col-lg-4">
                    <div className="classic-colorpicker" />
                  </div>
                  <div className="col-lg-4">
                    <div className="monolith-colorpicker" />
                  </div>
                  <div className="col-lg-4">
                    <div className="nano-colorpicker" />
                  </div>
                </div>
                <div className="card-body">
                  <div
                    id="datatable-buttons_wrapper"
                    className="dataTables_wrapper dt-bootstrap4 no-footer"
                  >
                    <div className="dateSearch mb-3">
                      <div className="row">
                        <div className="col-sm-12 col-md-6">
                          {/* <div className="mb-3">
                            <label>Filter By Type: </label>
                            <select
                              className="form-select"
                              onChange={handleTypeChange}
                            >
                              <option value={""}>Select Type</option>
                              <option value={"PHONE"}>Phone</option>
                              <option value={"FAX"}>Fax</option>
                            </select>
                          </div> */}
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div
                            id="datatable-buttons_filter"
                            className="dataTables_filter"
                          >
                            <div className="d-flex align-items-start flex-column">
                              <label>Search:</label>
                              <input
                                type="search"
                                className="form-control"
                                placeholder="Search item"
                                aria-controls="datatable-buttons"
                                value={filterQuery}
                                onChange={(e) => setFilterQuery(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="table-responsive">
                          {getVerifiedEmailLoader && (
                            <div className="text-center">Loading</div>
                          )}
                          {!getVerifiedEmailLoader && (
                            <table
                              id="datatable-buttons"
                              className="invoiceTable table table-bordered dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                              role="grid"
                              aria-describedby="datatable-buttons_info"
                              style={{ width: 1253 }}
                            >
                              <thead>
                                <tr role="row">
                                  <th
                                    className="sorting sorting_asc"
                                    tabIndex={0}
                                    rowSpan={1}
                                    colSpan={1}
                                  >
                                    Sr. No
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    rowSpan={1}
                                    colSpan={1}
                                  >
                                    Email
                                  </th>

                                  <th
                                    className="sorting text-center"
                                    tabIndex={0}
                                    rowSpan={1}
                                    colSpan={1}
                                  >
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {filterQueryResult.map((item, index) => (
                                  <tr role="row" key={index}>
                                    <th
                                      className="sorting sorting_asc"
                                      tabIndex={0}
                                      rowSpan={1}
                                      colSpan={1}
                                    >
                                      {index + 1}
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      rowSpan={1}
                                      colSpan={1}
                                    >
                                      {item.email}
                                    </th>

                                    <th
                                      className="sorting text-center"
                                      tabIndex={0}
                                      rowSpan={1}
                                      colSpan={1}
                                    >
                                      <button
                                        onClick={() =>
                                          handleDeleteEmail(item.email)
                                        }
                                        className="btn btn-outline btn-danger btn-sm mx-1"
                                        title="Delete Email"
                                        disabled={deleteVerifiedEmail}
                                      >
                                        {deleteVerifiedEmail ? (
                                          <i
                                            className="fas fa-spinner fa-spin"
                                            title="Loading"
                                          ></i>
                                        ) : (
                                          <i
                                            className="fas fa-trash"
                                            title="Delete Email"
                                          />
                                        )}
                                      </button>
                                    </th>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end card */}
            </div>
            {/* end col */}
          </div>
        </div>
        {/* container-fluid */}
      </div>
      {/* End Page-content */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    verifiedEmails: state.auth.verifiedEmails,
    getVerifiedEmailLoader: state.auth.getVerifiedEmailLoader,
    deleteVerifiedEmail: state.auth.deleteVerifiedEmail,
    getVerifiedEmailLoader: state.auth.getVerifiedEmailLoader,
  };
};

export default connect(mapStateToProps, {
  actionGetVerifiedEmails,
  actionDeleteVerifiedEmail,
})(VerifiedPhoneFaxNumbersList);
