import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import DefaultView from "../components/DefaultView";

const PortingRequest = () => {
  const [uploadedFiles, setUploadedFiles] = useState([]);

  console.log({ uploadedFiles });
  const onDrop = (acceptedFiles) => {
    // Add the uploaded files to the state
    setUploadedFiles(acceptedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*", // Define the accepted file types (e.g., images)
  });

  const handleDownload = () => {
    const fileUrl = process.env.REACT_APP_LOA_FILE;
    const link = document.createElement("a");
    link.href = fileUrl;
    link.target = "_blank";
    link.download = "Letter_of_Authorization"; // specify the name of the file to download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-top justify-content-between">
                <h4 className="mb-sm-0 main_heading">Porting Request Form</h4>
                <div className="page-title-right">
                  <DefaultView />
                </div>
              </div>
              <div className="d-flex justify-content-between pb-20">
                <button className="btn btn-primary" onClick={handleDownload}>
                  Download Letter of Authorization
                </button>
                <div className="">
                  <Link to="#" className="btn btn-primary backbtn">
                    Save
                  </Link>
                  {/* <Link to="lists.html" className="btn btn-primary backbtn">
                    Back
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
          {/* end page title */}
          <div className="row">
            <div className="col-12">
              {/* Right Sidebar */}
              <div className="mb-3 compose-section">
                <div className="">
                  <div className="mb-3 compose-section">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">
                          Upload Letter of Authorization (LOA)
                        </h4>
                        <p className="card-title-desc">
                          Ensuring Proper Documentation: A Guide to Crafting an
                          Effective Letter of Authorization (LOA)
                        </p>
                      </div>
                      <div className="card-body">
                        <div>
                          <form
                            action="#"
                            className="dropzone dz-clickable"
                            {...getRootProps()}
                          >
                            {/* Use getInputProps() to add the input element */}
                            <input {...getInputProps()} />
                            <div className="dz-message needsclick">
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bx-cloud-upload" />
                              </div>
                              <h5>Drop files here or click to upload.</h5>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                  {/* end row */}
                </div>
              </div>
              {/* card */}
            </div>
            {/* end Col */}
          </div>
          {/* end row */}
          {uploadedFiles.length > 0 && (
            <div>
              <h4>Uploaded Files:</h4>
              <ul>
                {uploadedFiles.map((file) => (
                  <li key={file.name}>{file.name}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
        {/* container-fluid */}
      </div>
      {/* End Page-content */}
    </div>
  );
};

export default PortingRequest;
