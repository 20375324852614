import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const AuthLock = () => {
  useEffect(() => {
    // Remove the data-layout attribute from the body tag when the component mounts
    document.body.removeAttribute("data-layout");

    // Optionally, you can add it back when the component unmounts
    return () => {
      document.body.setAttribute("data-layout", "topnav");
    };
  }, []); // The empty dependency array ensures this effect runs only once
  return (
    <div className="auth-page">
      <div className="container-fluid p-0">
        <div className="row g-0">
          <div className="col-xxl-3 col-lg-4 col-md-5">
            <div className="auth-full-page-content d-flex p-sm-5 p-4">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="mb-4 mb-md-5 text-center">
                    <Link to="/" className="d-block auth-logo">
                      <img src="assets/images/logo.png" alt="" height={28} />{" "}
                      <span className="logo-txt" />
                    </Link>
                  </div>
                  <div className="auth-content my-auto">
                    <div className="text-center">
                      <h5 className="mb-0">Lock Screen</h5>
                      <p className="text-muted mt-2">
                        Enter your password to unlock the screen!
                      </p>
                    </div>
                    <div className="user-thumb text-center mb-4 mt-4 pt-2">
                      <img
                        src="assets/images/users/avatar-1.jpg"
                        className="rounded-circle img-thumbnail avatar-lg"
                        alt="thumbnail"
                      />
                      <h5 className="font-size-15 mt-3">Shawn</h5>
                    </div>
                    <form className="mt-4" action="index.html">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="userpassword">
                          Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="userpassword"
                          placeholder="Enter password"
                        />
                      </div>
                      <div className="mb-3 mt-4">
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                        >
                          Unlock
                        </button>
                      </div>
                    </form>
                    <div className="mt-5 text-center">
                      <p className="text-muted mb-0">
                        Not you ? Return{" "}
                        <Link to="/" className="text-primary fw-semibold">
                          {" "}
                          Sign In{" "}
                        </Link>{" "}
                      </p>
                    </div>
                  </div>
                  {/* <div class="mt-4 mt-md-5 text-center">
                              <p class="mb-0">©
                                   Minia . Crafted with
                                  <i class="mdi mdi-heart text-danger"></i> by Themesbrand
                              </p>
                          </div> */}
                </div>
              </div>
            </div>
            {/* end auth full page content */}
          </div>
          {/* end col */}
          <div className="col-xxl-9 col-lg-8 col-md-7">
            <div className="auth-bg pt-md-5 p-4 d-flex">
              <div className="bg-overlay bg-primary" />
              <ul className="bg-bubbles">
                <li />
                <li />
                <li />
                <li />
                <li />
                <li />
                <li />
                <li />
                <li />
                <li />
              </ul>
              {/* end bubble effect */}
              <div className="row justify-content-center align-items-center">
                <div className="col-xl-7">
                  <div className="p-0 p-sm-4 px-xl-0">
                    <div
                      id="reviewcarouselIndicators"
                      className="carousel slide"
                      data-bs-ride="carousel"
                    >
                      <div className="carousel-indicators carousel-indicators-rounded justify-content-start ms-0 mb-0">
                        <button
                          type="button"
                          data-bs-target="#reviewcarouselIndicators"
                          data-bs-slide-to={0}
                          className="active"
                          aria-current="true"
                          aria-label="Slide 1"
                        />
                        <button
                          type="button"
                          data-bs-target="#reviewcarouselIndicators"
                          data-bs-slide-to={1}
                          aria-label="Slide 2"
                        />
                        <button
                          type="button"
                          data-bs-target="#reviewcarouselIndicators"
                          data-bs-slide-to={2}
                          aria-label="Slide 3"
                        />
                      </div>
                      {/* end carouselIndicators */}
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <div className="testi-contain text-white">
                            <i className="bx bxs-quote-alt-left text-success display-6" />
                            <h4 className="mt-4 fw-medium lh-base text-white">
                              “I feel confident imposing change on myself. It's
                              a lot more progressing fun than looking back.
                              That's why I ultricies enim at malesuada nibh diam
                              on tortor neaded to throw curve balls.”
                            </h4>
                            <div className="mt-4 pt-3 pb-5">
                              <div className="d-flex align-items-start">
                                <div className="flex-shrink-0">
                                  <img
                                    src="assets/images/users/avatar-1.jpg"
                                    className="avatar-md img-fluid rounded-circle"
                                    alt="..."
                                  />
                                </div>
                                <div className="flex-grow-1 ms-3 mb-4">
                                  <h5 className="font-size-18 text-white">
                                    Richard Drews
                                  </h5>
                                  <p className="mb-0 text-white-50">
                                    Web Designer
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="testi-contain text-white">
                            <i className="bx bxs-quote-alt-left text-success display-6" />
                            <h4 className="mt-4 fw-medium lh-base text-white">
                              “Our task must be to free ourselves by widening
                              our circle of compassion to embrace all living
                              creatures and the whole of quis consectetur nunc
                              sit amet semper justo. nature and its beauty.”
                            </h4>
                            <div className="mt-4 pt-3 pb-5">
                              <div className="d-flex align-items-start">
                                <div className="flex-shrink-0">
                                  <img
                                    src="assets/images/users/avatar-2.jpg"
                                    className="avatar-md img-fluid rounded-circle"
                                    alt="..."
                                  />
                                </div>
                                <div className="flex-grow-1 ms-3 mb-4">
                                  <h5 className="font-size-18 text-white">
                                    Rosanna French
                                  </h5>
                                  <p className="mb-0 text-white-50">
                                    Web Developer
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="testi-contain text-white">
                            <i className="bx bxs-quote-alt-left text-success display-6" />
                            <h4 className="mt-4 fw-medium lh-base text-white">
                              “I've learned that people will forget what you
                              said, people will forget what you did, but people
                              will never forget how donec in efficitur lectus,
                              nec lobortis metus you made them feel.”
                            </h4>
                            <div className="mt-4 pt-3 pb-5">
                              <div className="d-flex align-items-start">
                                <img
                                  src="assets/images/users/avatar-3.jpg"
                                  className="avatar-md img-fluid rounded-circle"
                                  alt="..."
                                />
                                <div className="flex-1 ms-3 mb-4">
                                  <h5 className="font-size-18 text-white">
                                    Ilse R. Eaton
                                  </h5>
                                  <p className="mb-0 text-white-50">Manager</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* end carousel-inner */}
                    </div>
                    {/* end review carousel */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end col */}
        </div>
        {/* end row */}
      </div>
      {/* end container fluid */}
    </div>
  );
};

export default AuthLock;
