import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Link } from "react-router-dom";
import DefaultView from "../components/DefaultView";
import {
  actionGetList,
  actionCreateContact,
} from "../store/actions/authAction";
import { connect } from "react-redux";

const AddNewContact = ({
  actionGetList,
  getListLoader,
  list,
  actionCreateContact,
  createContactLoader,
}) => {
  const [formData, setFormData] = useState({
    contactName: "",
    contactNumber: "",
    contactEmail: "",
    list_id: "", // assuming this is the selected list
  });

  const [formErrors, setFormErrors] = useState({
    contactName: "",
    contactNumber: "",
    contactEmail: "",
    list_id: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...formErrors };

    // Validate Contact Name
    if (formData.contactName.trim() === "") {
      newErrors.contactName = "Contact Name is required";
      isValid = false;
    }

    // Validate Contact Number (allowing international formats)
    const phonePattern = /^\+?[0-9]{1,15}$/; // Allows country codes and up to 15 digits
    if (formData.contactNumber.trim() === "") {
      newErrors.contactNumber = "Contact Number is required";
      isValid = false;
    } else if (!phonePattern.test(formData.contactNumber)) {
      newErrors.contactNumber = "Please enter a valid phone number";
      isValid = false;
    }

    // Validate Contact Email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation
    if (formData.contactEmail.trim() === "") {
      newErrors.contactEmail = "Contact Email is required";
      isValid = false;
    } else if (!emailPattern.test(formData.contactEmail)) {
      newErrors.contactEmail = "Please enter a valid email address";
      isValid = false;
    }

    setFormErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Form is valid, handle submission here
      // console.log("Form data submitted:", formData);
      const request = {
        contact_name: formData.contactName,
        contact_number: formData.contactNumber,
        contact_email: formData.contactEmail,
        list_id: formData.list_id,
      };
      console.log({ request });
      actionCreateContact(request,setFormData);
    }
  };

  useEffect(() => {
    actionGetList();
  }, []);

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-top justify-content-between">
                <h4 className="mb-sm-0 main_heading">Add New Contact</h4>
                <div className="page-title-right">
                  <DefaultView />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="mb-3 compose-section">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="contactName" className="form-label">
                              Contact Name
                            </label>
                            <input
                              className={`form-control ${
                                formErrors.contactName ? "is-invalid" : ""
                              }`}
                              type="text"
                              id="contactName"
                              name="contactName"
                              placeholder="Enter Contact Name"
                              value={formData.contactName}
                              onChange={handleInputChange}
                            />
                            {formErrors.contactName && (
                              <div className="invalid-feedback">
                                {formErrors.contactName}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="contactNumber"
                              className="form-label"
                            >
                              Contact Number
                            </label>
                            <input
                              className={`form-control ${
                                formErrors.contactNumber ? "is-invalid" : ""
                              }`}
                              type="text"
                              id="contactNumber"
                              name="contactNumber"
                              placeholder="Enter Contact Number"
                              value={formData.contactNumber}
                              onChange={handleInputChange}
                            />
                            {formErrors.contactNumber && (
                              <div className="invalid-feedback">
                                {formErrors.contactNumber}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="contactEmail"
                              className="form-label"
                            >
                              Contact Email
                            </label>
                            <input
                              className={`form-control ${
                                formErrors.contactEmail ? "is-invalid" : ""
                              }`}
                              type="text"
                              id="contactEmail"
                              name="contactEmail"
                              placeholder="Enter Contact Email"
                              value={formData.contactEmail}
                              onChange={handleInputChange}
                            />
                            {formErrors.contactEmail && (
                              <div className="invalid-feedback">
                                {formErrors.contactEmail}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="list_id" className="form-label">
                              Select List
                            </label>
                            <select
                              className={`form-select ${
                                formErrors.list_id ? "is-invalid" : ""
                              }`}
                              name="list_id"
                              value={formData.list_id}
                              onChange={handleInputChange}
                            >
                              <option value="">Select List</option>
                              {list &&
                                list.map((l, index) => (
                                  <option key={index} value={l.list_id}>
                                    {l.list_name}
                                  </option>
                                ))}
                            </select>
                            {formErrors.list_id && (
                              <div className="text-danger mt-1">
                                {formErrors.list_id}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end mt-4 mb-4">
                        {createContactLoader ? (
                          <button className="btn btn-primary" disabled>
                            <i
                              className="fas fa-spinner fa-spin"
                              title="Loading"
                            ></i>
                          </button>
                        ) : (
                          <button type="submit" className="btn btn-primary">
                            Save
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getListLoader: state.auth.getListLoader,
    list: state.auth.list,
    createContactLoader: state.auth.createContactLoader,
  };
};

export default connect(mapStateToProps, {
  actionGetList,
  actionCreateContact,
})(AddNewContact);
