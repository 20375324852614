import React, { useEffect, useRef, useState } from "react";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import { Link } from "react-router-dom";
import DefaultView from "../components/DefaultView";
import DeviceCallReportTableRow from "../components/DeviceCallReportTableRow";

const records = [
  {
    callid: "1",
    from: "2223332",
    to: "333333",
    datetime: "2008/11/28",
    pages: "3-5",
    duration: "1:00 Hour",
    direction: "IN",
  },
  {
    callid: "2",
    from: "1114444",
    to: "555555",
    datetime: "2009/12/15",
    pages: "1-2",
    duration: "0:45 Hour",
    direction: "OUT",
  },
  {
    callid: "3",
    from: "7776666",
    to: "9998888",
    datetime: "2010/08/20",
    pages: "6-8",
    duration: "2:30 Hours",
    direction: "IN",
  },
  {
    callid: "4",
    from: "3332222",
    to: "444444",
    datetime: "2011/05/10",
    pages: "2-4",
    duration: "1:15 Hour",
    direction: "OUT",
  },
  {
    callid: "5",
    from: "5556666",
    to: "777777",
    datetime: "2012/02/18",
    pages: "5-7",
    duration: "1:45 Hour",
    direction: "IN",
  },
  {
    callid: "6",
    from: "9991111",
    to: "222555",
    datetime: "2013/09/05",
    pages: "4-6",
    duration: "1:30 Hour",
    direction: "OUT",
  },
  {
    callid: "7",
    from: "8883333",
    to: "111222",
    datetime: "2014/07/14",
    pages: "7-9",
    duration: "2:15 Hours",
    direction: "IN",
  },
  {
    callid: "8",
    from: "4448888",
    to: "666777",
    datetime: "2015/04/25",
    pages: "8-10",
    duration: "2:00 Hours",
    direction: "OUT",
  },
  {
    callid: "9",
    from: "5555555",
    to: "333444",
    datetime: "2016/01/30",
    pages: "1-3",
    duration: "0:45 Hour",
    direction: "IN",
  },
  {
    callid: "10",
    from: "2227777",
    to: "111333",
    datetime: "2017/10/12",
    pages: "4-6",
    duration: "1:30 Hour",
    direction: "OUT",
  },
  {
    callid: "11",
    from: "8881111",
    to: "999222",
    datetime: "2018/08/19",
    pages: "7-9",
    duration: "2:15 Hours",
    direction: "IN",
  },
  {
    callid: "12",
    from: "4443333",
    to: "555666",
    datetime: "2019/06/24",
    pages: "5-7",
    duration: "1:45 Hour",
    direction: "OUT",
  },
  {
    callid: "13",
    from: "3335555",
    to: "777888",
    datetime: "2020/03/08",
    pages: "3-5",
    duration: "1:00 Hour",
    direction: "IN",
  },
  {
    callid: "14",
    from: "1114444",
    to: "999777",
    datetime: "2021/12/11",
    pages: "6-8",
    duration: "2:30 Hours",
    direction: "OUT",
  },
  {
    callid: "15",
    from: "7771111",
    to: "888333",
    datetime: "2022/10/28",
    pages: "2-4",
    duration: "1:15 Hour",
    direction: "IN",
  },
  {
    callid: "16",
    from: "5557777",
    to: "666999",
    datetime: "2023/08/05",
    pages: "8-10",
    duration: "2:00 Hours",
    direction: "OUT",
  },
  {
    callid: "17",
    from: "3334444",
    to: "222666",
    datetime: "2024/05/18",
    pages: "4-6",
    duration: "1:30 Hour",
    direction: "IN",
  },
  {
    callid: "18",
    from: "9998888",
    to: "777444",
    datetime: "2025/02/23",
    pages: "1-3",
    duration: "0:45 Hour",
    direction: "OUT",
  },
  {
    callid: "19",
    from: "2229999",
    to: "888666",
    datetime: "2026/12/02",
    pages: "7-9",
    duration: "2:15 Hours",
    direction: "IN",
  },
  {
    callid: "20",
    from: "4449999",
    to: "555888",
    datetime: "2027/09/17",
    pages: "5-7",
    duration: "1:45 Hour",
    direction: "OUT",
  },
  // Add more records as needed
];
function DeviceCallReport() {
  const datepickerRef = useRef(null);
  const [filteredRecords, setFilteredRecords] = useState(records);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    // Initialize Flatpickr after the component has mounted
    flatpickr(datepickerRef.current, {
      mode: "range",
      enableTime: true, // You can customize the options here
      dateFormat: "Y-m-d H:i",
    });
  }, []);

  useEffect(() => {
    const filtered = records.filter((record) =>
      record.from.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredRecords(filtered);
  }, [searchText]);

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 main_heading">Device Call Report</h4>
                <div className="page-title-right">
                  <DefaultView />
                </div>
              </div>
            </div>
          </div>
          {/* end page title */}
          <div className="row">
            <div className="col-lg-12">
              {/* start card */}
              <div className="invoiceCard mb-3 mt-4">
                <div className="row align-items-end">
                  <div className="col-md-6 col-sm-12">
                    <div className="mb-3 dateWrap">
                      <label className="form-label">Date Range:</label>
                      <input
                        type="text"
                        className="form-control flatpickr-input"
                        id="datepicker-range"
                        readOnly="readonly"
                        ref={datepickerRef} // Reference to the input element
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 justify-content-end d-sm-flex">
                    <div className="mb-3 dt-buttons btn-groups flex-wrap ">
                      <button
                        className="btn btn-success buttons-excel buttons-html5"
                        tabIndex={0}
                        aria-controls="datatable-buttons"
                        type="button"
                        style={{ marginRight: 5 }}
                      >
                        <span>Excel</span>
                      </button>
                      <button
                        className="btn btn-primary buttons-pdf buttons-html5"
                        tabIndex={0}
                        aria-controls="datatable-buttons"
                        type="button"
                      >
                        <span>PDF</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card invoiceCard">
                <div className="card-header">
                  <h4 className="card-title">Device Call Report</h4>
                  <p className="card-title-desc">
                    The Buttons extension for DataTables provides a common set
                    of options, API methods and styling to display buttons on a
                    page that will interact with a DataTable. The core library
                    provides the based framework upon which plug-ins can built.{" "}
                  </p>
                </div>
                <div className="row d-none">
                  <div className="col-lg-4">
                    <div className="classic-colorpicker" />
                  </div>
                  <div className="col-lg-4">
                    <div className="monolith-colorpicker" />
                  </div>
                  <div className="col-lg-4">
                    <div className="nano-colorpicker" />
                  </div>
                </div>
                <div className="card-body">
                  <div
                    id="datatable-buttons_wrapper"
                    className="dataTables_wrapper dt-bootstrap4 no-footer"
                  >
                    <div className="dateSearch mb-3">
                      <div className="row">
                        <div className="col-sm-12 col-md-6">
                          <div className="mb-3">
                            <label>Listbox: </label>
                            <select className="form-select">
                              <option>Listbox</option>
                              <option>Large select</option>
                              <option>Small select</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div
                            id="datatable-buttons_filter"
                            className="dataTables_filter"
                          >
                            <div className="d-flex align-items-start flex-column">
                              <label>Search:</label>
                              <input
                                type="search"
                                className="form-control"
                                placeholder="Search item"
                                aria-controls="datatable-buttons"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="table-responsive">
                          <table
                            id="datatable-buttons"
                            className="invoiceTable table table-bordered dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                            role="grid"
                            aria-describedby="datatable-buttons_info"
                            style={{ width: 1253 }}
                          >
                            <thead>
                              <tr role="row">
                                <th
                                  className="sorting sorting_asc"
                                  tabIndex={0}
                                  aria-controls="datatable-buttons"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-sort="ascending"
                                  aria-label="Name: activate to sort column descending"
                                >
                                  CallID
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="datatable-buttons"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Position: activate to sort column ascending"
                                >
                                  From
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="datatable-buttons"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Office: activate to sort column ascending"
                                >
                                  To
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="datatable-buttons"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Start date: activate to sort column ascending"
                                >
                                  Datetime
                                </th>
                                <th
                                  className="sorting text-center"
                                  tabIndex={0}
                                  aria-controls="datatable-buttons"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Start date: activate to sort column ascending"
                                >
                                  Pages
                                </th>
                                <th
                                  className="sorting text-center"
                                  tabIndex={0}
                                  aria-controls="datatable-buttons"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Salary: activate to sort column ascending"
                                >
                                  Duration
                                </th>
                                <th
                                  className="sorting text-center"
                                  tabIndex={0}
                                  aria-controls="datatable-buttons"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Salary: activate to sort column ascending"
                                >
                                  Direction
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {filteredRecords.map((item, index) => (
                                <DeviceCallReportTableRow
                                  key={index}
                                  index={index}
                                  callid={item.callid}
                                  from={item.from}
                                  to={item.to}
                                  datetime={item.datetime}
                                  pages={item.pages}
                                  duration={item.duration}
                                  direction={item.direction}
                                />
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div
                          className="dataTables_info"
                          id="datatable-buttons_info"
                          role="status"
                          aria-live="polite"
                        >
                          Showing 1 to 10 of 57 entries
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <div
                          className="dataTables_paginate paging_simple_numbers"
                          id="datatable-buttons_paginate"
                        >
                          <ul className="pagination">
                            <li
                              className="paginate_button page-item previous disabled"
                              id="datatable-buttons_previous"
                            >
                              <Link
                                to="#"
                                aria-controls="datatable-buttons"
                                data-dt-idx={0}
                                tabIndex={0}
                                className="page-link"
                              >
                                Previous
                              </Link>
                            </li>
                            <li className="paginate_button page-item active">
                              <Link
                                to="#"
                                aria-controls="datatable-buttons"
                                data-dt-idx={1}
                                tabIndex={0}
                                className="page-link"
                              >
                                1
                              </Link>
                            </li>
                            {/* Add more pagination items as needed */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end card */}
            </div>
            {/* end col */}
          </div>
        </div>
        {/* container-fluid */}
      </div>
      {/* End Page-content */}
    </div>
  );
}

export default DeviceCallReport;
