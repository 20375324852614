import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import DefaultView from "../components/DefaultView";
import FaxPhoneNumberTableRow from "../components/FaxPhoneNumberTableRow";
import { connect } from "react-redux";
import { actionGetMyNumbers } from "../store/actions/authAction";
import moment from "moment";
import NumberExportToExcel from "../components/NumberExportToExcel";
import NumberExportToPDF from "../components/NumberExportToPDF";

function PhoneNumberList({ actionGetMyNumbers, myNumbers, user }) {
  const datepickerRef = useRef(null);
  const [filterQuery, setFilterQuery] = useState("");
  const [filterQueryResult, setFilterQueryResult] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    // Initialize Flatpickr after the component has mounted
    /* flatpickr(datepickerRef.current, {
      mode: "range",
      enableTime: true, // You can customize the options here
      dateFormat: "Y-m-d",
      onChange: function (selectedDates) {
        if (selectedDates.length === 2) {
          setStartDate(selectedDates[0]);
          setEndDate(selectedDates[1]);
        }
      },
    }); */
  }, []);

  useEffect(() => {
    console.log({ startDate: startDate });
    // Check if both start and end dates are selected
    const isDateSelected = startDate && endDate;

    const filtered =
      myNumbers &&
      myNumbers.filter(
        (record) =>
          (record.number.toLowerCase().includes(filterQuery.toLowerCase()) ||
            record.number_status.includes(filterQuery.toLowerCase())) &&
          (!selectedStatus || record.number_status === selectedStatus) &&
          // Apply date filter only if both start and end dates are selected
          (!isDateSelected ||
            moment(record.number_activation_date).isBetween(
              moment(startDate).startOf("day"),
              moment(endDate).endOf("day"),
              null,
              "[]"
            ))
      );
    setFilterQueryResult(filtered);
  }, [filterQuery, selectedStatus, myNumbers, startDate, endDate]);

  useEffect(() => {
    user && actionGetMyNumbers(user.user_ID);
  }, [user]);

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 main_heading">Phone Number List </h4>
                <div className="page-title-right">
                  <DefaultView />
                </div>
              </div>
            </div>
          </div>
          {/* end page title */}
          <div className="row">
            <div className="col-lg-12">
              {/* start card */}
              <div className="invoiceCard mb-3 mt-4">
                <div className="row align-items-end">
                  <div className="col-md-6 col-sm-12">
                    {/* <div className="mb-3 dateWrap">
                      <label className="form-label">Date Range:</label>
                      <input
                        type="text"
                        className="form-control flatpickr-input"
                        id="datepicker-range"
                        readOnly="readonly"
                        ref={datepickerRef}
                        onChange={(e) => console.ldog(e)}
                      />
                    </div> */}
                  </div>
                  <div className="col-sm-12 col-md-6 justify-content-end d-sm-flex">
                    <div className="mb-3 dt-buttons btn-groups flex-wrap">
                      <div className="d-flex">
                        <NumberExportToExcel data={filterQueryResult} />
                        <NumberExportToPDF data={filterQueryResult} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card invoiceCard">
                <div className="card-header">
                  <h4 className="card-title">Phone Number List</h4>
                  <p className="card-title-desc">
                    The Buttons extension for DataTables provides a common set
                    of options, API methods and styling to display buttons on a
                    page that will interact with a DataTable. The core library
                    provides the based framework upon which plug-ins can built.{" "}
                  </p>
                </div>
                <div className="row d-none">
                  <div className="col-lg-4">
                    <div className="classic-colorpicker" />
                  </div>
                  <div className="col-lg-4">
                    <div className="monolith-colorpicker" />
                  </div>
                  <div className="col-lg-4">
                    <div className="nano-colorpicker" />
                  </div>
                </div>
                <div className="card-body">
                  <div
                    id="datatable-buttons_wrapper"
                    className="dataTables_wrapper dt-bootstrap4 no-footer"
                  >
                    <div className="dateSearch mb-3">
                      <div className="row">
                        <div className="col-sm-12 col-md-6">
                          <div className="mb-3">
                            <label>Filter By Status: </label>
                            <select
                              className="form-select"
                              onChange={handleStatusChange}
                            >
                              <option value={""}>Select Status</option>
                              <option value={"enabled"}>Active</option>
                              <option value={"disabled"}>Inactive</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div
                            id="datatable-buttons_filter"
                            className="dataTables_filter"
                          >
                            <div className="d-flex align-items-start flex-column">
                              <label>Search:</label>
                              <input
                                type="search"
                                className="form-control"
                                placeholder="Search item"
                                aria-controls="datatable-buttons"
                                value={filterQuery}
                                onChange={(e) => setFilterQuery(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="table-responsive">
                          <table
                            id="datatable-buttons"
                            className="invoiceTable table table-bordered dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                            role="grid"
                            aria-describedby="datatable-buttons_info"
                            style={{ width: 1253 }}
                          >
                            <thead>
                              <tr role="row">
                                <th
                                  className="sorting sorting_asc"
                                  tabIndex={0}
                                  aria-controls="datatable-buttons"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-sort="ascending"
                                  aria-label="Name: activate to sort column descending"
                                >
                                  Sr. No
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="datatable-buttons"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Position: activate to sort column ascending"
                                >
                                  Fax Number
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="datatable-buttons"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Position: activate to sort column ascending"
                                >
                                  Route to
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="datatable-buttons"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Office: activate to sort column ascending"
                                >
                                  Activation Date
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="datatable-buttons"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Start date: activate to sort column ascending"
                                >
                                  Billing Date
                                </th>
                                <th
                                  className="sorting text-center"
                                  tabIndex={0}
                                  aria-controls="datatable-buttons"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Start date: activate to sort column ascending"
                                >
                                  Status
                                </th>
                                <th
                                  className="sorting text-center"
                                  tabIndex={0}
                                  aria-controls="datatable-buttons"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Salary: activate to sort column ascending"
                                >
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {filterQueryResult.map((item, index) => (
                                <FaxPhoneNumberTableRow
                                  key={index}
                                  index={index}
                                  number_ID={item.number_ID}
                                  faxNumber={item.number}
                                  activationDate={moment(
                                    item.number_activation_date
                                  ).format("YYYY/MM/DD")}
                                  billingDate={moment(
                                    item.number_billing_date
                                  ).format("YYYY/MM/DD")}
                                  status={
                                    item.number_status == "enabled"
                                      ? "Active"
                                      : "Deactive"
                                  }
                                  number_status={item.number_status}
                                  routeTo={item.route_destination}
                                  //onButtonClick={id => handleButtonClick(id)}
                                />
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div
                          className="dataTables_info"
                          id="datatable-buttons_info"
                          role="status"
                          aria-live="polite"
                        >
                          Showing 1 to 10 of {myNumbers.length} entries
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <div
                          className="dataTables_paginate paging_simple_numbers"
                          id="datatable-buttons_paginate"
                        >
                          <ul className="pagination">
                            <li
                              className="paginate_button page-item previous disabled"
                              id="datatable-buttons_previous"
                            >
                              <Link
                                to="#"
                                aria-controls="datatable-buttons"
                                data-dt-idx={0}
                                tabIndex={0}
                                className="page-link"
                              >
                                Previous
                              </Link>
                            </li>
                            <li className="paginate_button page-item active">
                              <Link
                                to="#"
                                aria-controls="datatable-buttons"
                                data-dt-idx={1}
                                tabIndex={0}
                                className="page-link"
                              >
                                1
                              </Link>
                            </li>
                            <li className="paginate_button page-item ">
                              <Link
                                to="#"
                                aria-controls="datatable-buttons"
                                data-dt-idx={2}
                                tabIndex={0}
                                className="page-link"
                              >
                                2
                              </Link>
                            </li>
                            <li className="paginate_button page-item ">
                              <Link
                                to="#"
                                aria-controls="datatable-buttons"
                                data-dt-idx={3}
                                tabIndex={0}
                                className="page-link"
                              >
                                3
                              </Link>
                            </li>
                            <li className="paginate_button page-item ">
                              <Link
                                to="#"
                                aria-controls="datatable-buttons"
                                data-dt-idx={4}
                                tabIndex={0}
                                className="page-link"
                              >
                                4
                              </Link>
                            </li>
                            <li className="paginate_button page-item ">
                              <Link
                                to="#"
                                aria-controls="datatable-buttons"
                                data-dt-idx={5}
                                tabIndex={0}
                                className="page-link"
                              >
                                5
                              </Link>
                            </li>
                            <li className="paginate_button page-item ">
                              <Link
                                to="#"
                                aria-controls="datatable-buttons"
                                data-dt-idx={6}
                                tabIndex={0}
                                className="page-link"
                              >
                                6
                              </Link>
                            </li>
                            <li
                              className="paginate_button page-item next"
                              id="datatable-buttons_next"
                            >
                              <Link
                                to="#"
                                aria-controls="datatable-buttons"
                                data-dt-idx={7}
                                tabIndex={0}
                                className="page-link"
                              >
                                Next
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* end card */}
            </div>
            {/* end col */}
          </div>
        </div>
        {/* container-fluid */}
      </div>
      {/* End Page-content */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    myNumbers: state.auth.myNumbers,
  };
};

export default connect(mapStateToProps, {
  actionGetMyNumbers,
})(PhoneNumberList);
