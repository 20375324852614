import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import DefaultView from "../components/DefaultView";

const ColumnChart = ({ chartData }) => {
  const options = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val + "%";
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#74788d"],
      },
    },
    xaxis: {
      categories: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={[{ data: chartData }]}
      type="bar"
      height={350}
    />
  );
};

const AddFund = () => {
  const [formData, setFormData] = useState({
    fundTextBox: "",
    widgetUsage: "",
    autoRecharge: false,
    termsAccepted: false,
    fullName: "",
    billingEmail: "",
    billingPhone: "",
    listBoxValue: "",
    billingAddress: "",
    cardName: "",
    cardNumber: "",
    cardExpiry: "",
    cardCVC: "",
    saveCard: false,
  });

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handlePrevious = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validation logic for each step
    if (activeStep === 0) {
      if (!formData.fundTextBox) {
        alert("Add Fund Text Box is required");
        return;
      }
      // Add more validation checks for step 1
    } else if (activeStep === 1) {
      if (!formData.fullName) {
        alert("Full Name is required");
        return;
      }
      // Add more validation checks for step 2
    }

    // If all steps are completed, you can submit the form data
    if (activeStep === 2) {
      console.log("Form data submitted:", formData);
      return;
    }

    // Move to the next step
    handleNext();
    setFormData(formData); // TODO: remove
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 main_heading">Billing</h4>
                <div className="page-title-right">
                  <DefaultView />
                </div>
              </div>
            </div>
          </div>
          {/* end page title */}
          <div className="row">
            <div className="col-lg-12">
              {/* start card */}
              <div className="card mt-4">
                <div className="card-header">
                  <h4 className="card-title mb-0">Wizard with Progressbar</h4>
                </div>
                <div className="card-body">
                  <div id="progrss-wizard" className="twitter-bs-wizard">
                    <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                      <li className="nav-item" onClick={() => setActiveStep(0)}>
                        <span
                          className={
                            activeStep === 0 ? "nav-link active" : "nav-link"
                          }
                          data-toggle="tab"
                          style={{ cursor: "pointer" }}
                        >
                          <div
                            className="step-icon"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title=""
                            data-bs-original-title="Seller Details"
                          >
                            <i className="bx bx-list-ul" />
                          </div>
                        </span>
                      </li>
                      <li className="nav-item" onClick={() => setActiveStep(1)}>
                        <span
                          className={
                            activeStep === 1 ? "nav-link active" : "nav-link"
                          }
                          data-toggle="tab"
                          style={{ cursor: "pointer" }}
                        >
                          <div
                            className="step-icon"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title=""
                            data-bs-original-title="Company Document"
                          >
                            <i className="bx bx-book-bookmark" />
                          </div>
                        </span>
                      </li>
                      <li className="nav-item" onClick={() => setActiveStep(2)}>
                        <span
                          className={
                            activeStep === 2 ? "nav-link active" : "nav-link"
                          }
                          data-toggle="tab"
                          style={{ cursor: "pointer" }}
                        >
                          <div
                            className="step-icon"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title=""
                            data-bs-original-title="Bank Details"
                          >
                            <i className="bx bxs-bank" />
                          </div>
                        </span>
                      </li>
                    </ul>
                    {/* wizard-nav */}
                    <div id="bar" className="progress mt-4">
                      <div
                        className="progress-bar bg-success progress-bar-striped progress-bar-animated"
                        style={{
                          width: `${(activeStep + 1) * 33.3333}%`,
                        }}
                      />
                    </div>
                    <div className="tab-content twitter-bs-wizard-tab-content">
                      <div
                        className={`tab-pane ${
                          activeStep === 0 ? "active" : ""
                        }`}
                        id="progress-seller-details"
                      >
                        <div className="text-center mb-4">
                          <h5>Seller Details</h5>
                          <p className="card-title-desc">
                            Fill all information below
                          </p>
                        </div>
                        <form>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="progresspill-firstname-input">
                                  Add Fund Text Box
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="progresspill-firstname-input"
                                  placeholder="Add Fund Text Box"
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="progresspill-firstname-input">
                                  Widget Usage
                                </label>
                                <label htmlFor="progresspill-email-input">
                                  Provide auto-recharge
                                </label>
                                <input
                                  type="Text"
                                  className="form-control"
                                  id="progresspill-email-input"
                                  placeholder="Show monthly usage"
                                />
                              </div>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label htmlFor="progresspill-lastname-input">
                                      Auto Recharge
                                    </label>
                                    <div className="square-switch">
                                      <input
                                        type="checkbox"
                                        id="square-switch3"
                                        switch="bool"
                                        defaultChecked=""
                                      />
                                      <label
                                        htmlFor="square-switch3"
                                        data-on-label="Yes"
                                        data-off-label="No"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-3 terms">
                                    <label htmlFor="progresspill-phoneno-input">
                                      Terms and conditions
                                    </label>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="formCheck2"
                                        defaultChecked=""
                                      />
                                      <button
                                        type="button"
                                        className="btn btn-primary waves-effect waves-light"
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModalScrollable"
                                      >
                                        Terms and conditions
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-12">
                              <div className="card">
                                <div className="card-header">
                                  <h4 className="card-title mb-0">
                                    Column with Data Labels
                                  </h4>
                                </div>
                                <div className="card-body">
                                  {/* <div
                                    id="column_chart_datalabel"
                                    data-colors='["#5156be"]'
                                    className="apex-charts"
                                    dir="ltr"
                                  /> */}
                                  <ColumnChart
                                    chartData={[
                                      1200, 1500, 900, 2000, 2500, 1800, 2200,
                                      2100, 1900, 2400, 2700, 2300,
                                    ]}
                                  />
                                </div>
                              </div>
                              {/*end card*/}
                            </div>
                          </div>
                          {/* end row */}
                        </form>
                        <ul className="pager wizard twitter-bs-wizard-pager-link">
                          <li className="next">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleNext}
                            >
                              Next <i className="bx bx-chevron-right ms-1" />
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div
                        className={`tab-pane ${
                          activeStep === 1 ? "active" : ""
                        }`}
                        id="progress-company-document"
                      >
                        <div>
                          <div className="text-center mb-4">
                            <h5>Person Information</h5>
                            <p className="card-title-desc">
                              Fill all information below
                            </p>
                          </div>
                          <form>
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label
                                    htmlFor="progresspill-pancard-input"
                                    className="form-label"
                                  >
                                    Full Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="progresspill-pancard-input"
                                    placeholder="Enter your PAN Card No"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label htmlFor="progresspill-email-input">
                                    Billing Email
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="progresspill-email-input"
                                    placeholder="Enter your Email"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label
                                    htmlFor="progresspill-cstno-input"
                                    className="form-label"
                                  >
                                    Billing Phone{" "}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="progresspill-cstno-input"
                                    placeholder="Enter your Phone"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label className="form-label">Listbox </label>
                                  <div className="d-flex">
                                    <select className="form-select">
                                      <option>Listbox </option>
                                      <option>Large select</option>
                                      <option>Small select</option>
                                    </select>
                                    <button className="addNewBtn btn btn-primary">
                                      Add New
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="mb-3">
                                  <label htmlFor="progresspill-address-input">
                                    Billing Address
                                  </label>
                                  <textarea
                                    id="progresspill-address-input"
                                    className="form-control"
                                    rows={2}
                                    placeholder="Enter your address"
                                    defaultValue={""}
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                          <ul className="pager wizard twitter-bs-wizard-pager-link">
                            <li className="previous disabled">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handlePrevious}
                              >
                                <i className="bx bx-chevron-left me-1" />{" "}
                                Previous{" "}
                              </button>
                            </li>
                            <li className="next">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleNext}
                              >
                                Next <i className="bx bx-chevron-right ms-1" />
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        className={`tab-pane ${
                          activeStep === 2 ? "active" : ""
                        }`}
                        id="progress-bank-detail"
                      >
                        <div>
                          <div className="text-center mb-4">
                            <h5>Card Details</h5>
                            <p className="card-title-desc">
                              Fill all information below
                            </p>
                          </div>
                          <form>
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label
                                    htmlFor="progresspill-namecard-input"
                                    className="form-label"
                                  >
                                    Name (as on Card)
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="progresspill-namecard-input"
                                    placeholder="Enter your Name on Card"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label
                                    htmlFor="progresspill-cardno-input"
                                    className="form-label"
                                  >
                                    Card Number
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="progresspill-cardno-input"
                                    placeholder="Enter your Card Number"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label
                                    htmlFor="progresspill-expiration-input"
                                    className="form-label"
                                  >
                                    Expiry Date
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    id="progresspill-expiration-input"
                                    placeholder="MM/YY"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label
                                    htmlFor="progresspill-card-verification-input"
                                    className="form-label"
                                  >
                                    CVC
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="progresspill-card-verification-input"
                                    placeholder="CVC"
                                  />
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="formCheck2"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="formCheck2"
                                    >
                                      {" "}
                                      Checkbox option to save{" "}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <ul className="pager wizard twitter-bs-wizard-pager-link">
                                <li className="previous disabled">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handlePrevious}
                                  >
                                    <i className="bx bx-chevron-left me-1" />{" "}
                                    Previous{" "}
                                  </button>
                                </li>
                                <li className="next">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleSubmit}
                                  >
                                    Save Changes
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* end card body */}
                </div>
                {/* end card */}
              </div>
              {/* end col */}
            </div>
          </div>
          {/* container-fluid */}
        </div>
        {/* End Page-content */}
      </div>
    </div>
  );
};

export default AddFund;
