import React, { useState } from "react";
import { Link } from "react-router-dom";
import DefaultView from "../components/DefaultView";

function GiftFaxMachine() {
  const [formData, setFormData] = useState({
    name: "",
    faxNumber: "",
    emailAddress: "",
    phoneNumber: "",
  });

  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  console.log({ submitted });

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = "Name is required";
    }

    if (!formData.faxNumber) {
      newErrors.faxNumber = "Fax Number is required";
    }

    if (!formData.emailAddress) {
      newErrors.emailAddress = "Email Address is required";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.emailAddress)) {
      newErrors.emailAddress = "Invalid email address";
    }

    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "Phone Number is required";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Perform your form submission here, e.g., send data to a server.
      console.log("Form submitted with data:", formData);
      setSubmitted(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-top justify-content-between">
                <h4 className="mb-sm-0 main_heading">
                  Gift a Fax Machine to your friend
                </h4>
                <div className="page-title-right">
                  <DefaultView />
                </div>
              </div>
            </div>
          </div>
          {/* end page title */}
          <div className="row">
            <div className="col-12">
              {/* Right Sidebar */}
              <div className="mb-3 compose-section">
                <div className="card">
                  <div className="card-body">
                    <div className="mb-3 compose-section">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="form-label"
                            >
                              Name
                            </label>
                            <input
                              id="name"
                              name="name"
                              type="text"
                              className={`form-control ${
                                errors.name ? "is-invalid" : ""
                              }`}
                              placeholder="Enter Name"
                              onChange={handleChange}
                              value={formData.name}
                            />
                            {errors.name && (
                              <div className="text-danger">{errors.name}</div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="form-label"
                            >
                              Fax Number
                            </label>
                            <input
                              id="faxNumber"
                              name="faxNumber"
                              type="text"
                              className={`form-control ${
                                errors.faxNumber ? "is-invalid" : ""
                              }`}
                              placeholder="Enter Fax Number"
                              onChange={handleChange}
                              value={formData.faxNumber}
                            />
                            {errors.faxNumber && (
                              <div className="text-danger">
                                {errors.faxNumber}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="example-date-input"
                              className="form-label"
                            >
                              Email Address
                            </label>
                            <input
                              id="emailAddress"
                              name="emailAddress"
                              type="text"
                              className={`form-control ${
                                errors.emailAddress ? "is-invalid" : ""
                              }`}
                              placeholder="Email Address"
                              onChange={handleChange}
                              value={formData.emailAddress}
                            />
                            {errors.emailAddress && (
                              <div className="text-danger">
                                {errors.emailAddress}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="example-date-input"
                              className="form-label"
                            >
                              Phone Number
                            </label>
                            <input
                              id="phoneNumber"
                              name="phoneNumber"
                              type="text"
                              className={`form-control ${
                                errors.phoneNumber ? "is-invalid" : ""
                              }`}
                              placeholder="Enter Phone Number"
                              onChange={handleChange}
                              value={formData.phoneNumber}
                            />
                            {errors.phoneNumber && (
                              <div className="text-danger">
                                {errors.phoneNumber}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                    {/* end row */}
                  </div>
                </div>
              </div>
              {/* card */}
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex justify-content-sm-end">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                    <Link to="lists.html" className="btn btn-primary backbtn">
                      Back
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* end Col */}
          </div>
          {/* end row */}
        </div>
        {/* container-fluid */}
      </div>
      {/* End Page-content */}
    </div>
  );
}

export default GiftFaxMachine;
