import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast as message } from "react-toastify";
import DefaultView from "../components/DefaultView";
import {
  actionUpdateUserProfile,
  actionUploadProfilePic,
} from "../store/actions/authAction";
import { setAuthToken } from "../utils/helper";

function Profile({
  user,
  userUpdateLoader,
  profileDataLoader,
  actionUpdateUserProfile,
  profilePicUploadLoader,
  actionUploadProfilePic,
}) {
  // State for storing uploaded files
  const [uploadedFiles, setUploadedFiles] = useState([]);
  // Define state variables for form data and validation
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    mobileNumber: "",
    phoneNumber: "",
    officeNumber: "",
    faxNumber: "",
    country: "",
    state: "",
    zip: "",
    city: "",
    address: "",
  });

  useEffect(() => {
    if (user) {
      setFormData({
        fullName: user.user_fullname ? user.user_fullname : "",
        email: user.user_email ? user.user_email : "",
        mobileNumber: user.user_mobile ? user.user_mobile : "",
        phoneNumber: user.user_phone ? user.user_phone : "",
        officeNumber: user.user_office_number ? user.user_office_number : "",
        faxNumber: user.user_fax_number ? user.user_fax_number : "",
        country: user.user_country_name ? user.user_country_name : "",
        state: user.user_state ? user.user_state : "",
        zip: user.user_zip ? user.user_zip : "",
        city: user.user_city ? user.user_city : "",
        address: user.user_address ? user.user_address : "",
      });
    }
  }, [user]);

  const userauthToken = localStorage.getItem("authToken");
  const userauthId = localStorage.getItem("authUserId");
  // console.log({ token });
  // console.log({ authUserId });
  useEffect(() => {
    setAuthToken(userauthToken);
  }, [userauthToken]);

  const [formErrors, setFormErrors] = useState({});

  // Function to handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Initialize an empty object to store validation errors
    const errors = {};

    // Validation for Full Name
    if (!formData.fullName.trim()) {
      errors.fullName = "Full Name is required";
    }

    // Validation for Email
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)
    ) {
      errors.email = "Invalid email address";
    }

    // Validation for Mobile Number
    if (!formData.mobileNumber.trim()) {
      errors.mobileNumber = "Mobile Number is required";
    } else if (!/^[0-9]{10}$/i.test(formData.mobileNumber)) {
      errors.mobileNumber = "Invalid mobile number";
    }

    // Validation for Phone Number
    if (!formData.phoneNumber.trim()) {
      errors.phoneNumber = "Phone Number is required";
    } else if (!/^[0-9]{10}$/i.test(formData.phoneNumber)) {
      errors.phoneNumber = "Invalid phone number";
    }

    // Validation for Office Number
    if (!formData.officeNumber.trim()) {
      errors.officeNumber = "Office Number is required";
    } else if (!/^[0-9]{10}$/i.test(formData.officeNumber)) {
      errors.officeNumber = "Invalid office number";
    }

    // Validation for Fax Number
    if (!formData.faxNumber.trim()) {
      errors.faxNumber = "Fax Number is required";
    } else if (!/^[0-9]{10}$/i.test(formData.faxNumber)) {
      errors.faxNumber = "Invalid fax number";
    }

    // Validation for Country
    if (!formData.country.trim()) {
      errors.country = "Country is required";
    }

    // Validation for State
    if (!formData.state.trim()) {
      errors.state = "State is required";
    }

    // Validation for ZIP
    if (!formData.zip.trim()) {
      errors.zip = "ZIP is required";
    }

    // Validation for City
    if (!formData.city.trim()) {
      errors.city = "City is required";
    }

    // Validation for Address
    if (!formData.address.trim()) {
      errors.address = "Address is required";
    }

    if (uploadedFiles.length <= 0) {
      errors.uploadedFiles = "File is required";
    }

    // Check if there are any validation errors
    if (Object.keys(errors).length === 0) {
      setFormErrors("");
      // If no errors, you can submit the form data to the server or perform other actions
      console.log("Form data submitted:", formData);
      const DataObj = {
        user_fullname: formData.fullName,
        user_pic_filename: uploadedFiles.file ? uploadedFiles.file.path : "",
        user_mobile: formData.mobileNumber,
        user_phone: formData.phoneNumber,
        user_office_number: formData.officeNumber,
        user_fax_number: formData.faxNumber,
        user_country_name: formData.country,
        user_state: formData.state,
        user_zip: formData.zip,
        user_city: formData.city,
        user_address: formData.address,
        user_token: userauthToken,
      };
      actionUpdateUserProfile(user.user_ID, DataObj);
    } else {
      // If there are errors, set the formErrors state to display them to the user
      setFormErrors(errors);
    }
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const imageFiles = acceptedFiles.filter((file) =>
        file.type.startsWith("image/")
      );
      // actionUploadProfilePic();
      if (imageFiles.length > 0) {
        const filesWithPreviews = imageFiles.map((file) => ({
          file,
          preview: URL.createObjectURL(file),
        }));

        const formData = new FormData();
        formData.append(
          "file",
          new Blob([filesWithPreviews[0]], { type: "*" }),
          filesWithPreviews[0].file.name
        );

        try {
          const result = actionUploadProfilePic(
            formData,
            userauthId,
            userauthToken
          );
          console.log("result", result);
          if (result) {
            setUploadedFiles(filesWithPreviews[0]);
          }
        } catch (error) {
          // Handle error
          message.error("Error uploading profile pic:");
          console.log(error);
        }
      } else {
        // Handle the case when no image files are dropped
        message.error("Please drop only image file.");
        // You can also set an error state if needed
      }
    },
    [uploadedFiles]
  );

  const removeFile = (fileToRemove) => {
    const updatedFiles = uploadedFiles.filter((file) => file !== fileToRemove);
    setUploadedFiles(updatedFiles);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  const dropzoneStyle = {
    border: "2px dashed var(--bs-gray-400)",
    padding: "50px",
    margin: "20px",
    borderRadius: "6px",
    cursor: "pointer",
  };

  const downloadButtonStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          {profileDataLoader && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: 200,
              }}
            >
              <h5>Loading data..</h5>
            </div>
          )}
          {!profileDataLoader && user && (
            <>
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-top justify-content-between">
                    <h4 className="mb-sm-0 main_heading">My Profile</h4>
                    <div className="page-title-right">
                      <DefaultView />
                      <div className="d-flex justify-content-end pt-20">
                        <div className="right-side-btns">
                          <div className="btn-group1">
                            {userUpdateLoader ? (
                              <button
                                type="button"
                                className="btn btn-primary waves-light waves-effect"
                                style={{ marginRight: 5, minWidth: 55 }}
                              >
                                <i
                                  className="fas fa-spinner fa-spin"
                                  title="Loading"
                                ></i>
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="btn btn-primary waves-light waves-effect "
                                style={{ marginRight: 5 }}
                                onClick={handleSubmit}
                                disabled={profilePicUploadLoader}
                              >
                                Save
                              </button>
                            )}
                            {/*  <Link
                              to="lists.html"
                              className="btn btn-primary waves-light waves-effect back_button"
                            >
                              Back
                            </Link> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="mb-3 compose-section">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">Personal Details</h4>
                        <p className="card-title-desc">Account type</p>
                      </div>

                      <div className="card-body">
                        <div className="mb-3 compose-section">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="fullName"
                                  className="form-label"
                                >
                                  Full Name
                                </label>
                                <input
                                  className={`form-control ${
                                    formErrors.fullName ? "is-invalid" : ""
                                  }`}
                                  type="text"
                                  placeholder="Enter Full Name"
                                  name="fullName"
                                  value={formData.fullName}
                                  onChange={handleInputChange}
                                />
                                {formErrors.fullName && (
                                  <div className="invalid-feedback">
                                    {formErrors.fullName}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label htmlFor="email" className="form-label">
                                  Email
                                </label>
                                <input
                                  className={`form-control ${
                                    formErrors.email ? "is-invalid" : ""
                                  }`}
                                  type="text"
                                  placeholder="Email"
                                  name="email"
                                  value={formData.email}
                                  onChange={handleInputChange}
                                  disabled={true}
                                />
                                {formErrors.email && (
                                  <div className="invalid-feedback">
                                    {formErrors.email}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* card */}
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">Contact Numbers</h4>
                        <p className="card-title-desc">Contact Number</p>
                      </div>
                      <div className="card-body">
                        <div className="mb-3 compose-section">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="mobileNumber"
                                  className="form-label"
                                >
                                  Mobile Number
                                </label>
                                <input
                                  className={`form-control ${
                                    formErrors.mobileNumber ? "is-invalid" : ""
                                  }`}
                                  type="text"
                                  placeholder="Enter Mobile Number"
                                  name="mobileNumber"
                                  value={formData.mobileNumber}
                                  onChange={handleInputChange}
                                />
                                {formErrors.mobileNumber && (
                                  <div className="invalid-feedback">
                                    {formErrors.mobileNumber}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="phoneNumber"
                                  className="form-label"
                                >
                                  Phone Number
                                </label>
                                <input
                                  className={`form-control ${
                                    formErrors.phoneNumber ? "is-invalid" : ""
                                  }`}
                                  type="text"
                                  placeholder="Phone Number"
                                  name="phoneNumber"
                                  value={formData.phoneNumber}
                                  onChange={handleInputChange}
                                />
                                {formErrors.phoneNumber && (
                                  <div className="invalid-feedback">
                                    {formErrors.phoneNumber}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="officeNumber"
                                  className="form-label"
                                >
                                  Office Number
                                </label>
                                <input
                                  className={`form-control ${
                                    formErrors.officeNumber ? "is-invalid" : ""
                                  }`}
                                  type="text"
                                  placeholder="Enter Office Number"
                                  name="officeNumber"
                                  value={formData.officeNumber}
                                  onChange={handleInputChange}
                                />
                                {formErrors.officeNumber && (
                                  <div className="invalid-feedback">
                                    {formErrors.officeNumber}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="faxNumber"
                                  className="form-label"
                                >
                                  Fax Number
                                </label>
                                <input
                                  className={`form-control ${
                                    formErrors.faxNumber ? "is-invalid" : ""
                                  }`}
                                  type="text"
                                  placeholder="Fax Number"
                                  name="faxNumber"
                                  value={formData.faxNumber}
                                  onChange={handleInputChange}
                                />
                                {formErrors.faxNumber && (
                                  <div className="invalid-feedback">
                                    {formErrors.faxNumber}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* card */}
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">Address Details</h4>
                        <p className="card-title-desc">Address</p>
                      </div>
                      <div className="card-body">
                        <div className="mb-3 compose-section">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label htmlFor="country" className="form-label">
                                  Country
                                </label>
                                <input
                                  className={`form-control ${
                                    formErrors.country ? "is-invalid" : ""
                                  }`}
                                  type="text"
                                  placeholder="Enter Country"
                                  name="country"
                                  value={formData.country}
                                  onChange={handleInputChange}
                                />
                                {formErrors.country && (
                                  <div className="invalid-feedback">
                                    {formErrors.country}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label htmlFor="state" className="form-label">
                                  State
                                </label>
                                <input
                                  className={`form-control ${
                                    formErrors.state ? "is-invalid" : ""
                                  }`}
                                  type="text"
                                  placeholder="Enter State"
                                  name="state"
                                  value={formData.state}
                                  onChange={handleInputChange}
                                />
                                {formErrors.state && (
                                  <div className="invalid-feedback">
                                    {formErrors.state}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label htmlFor="zip" className="form-label">
                                  ZIP
                                </label>
                                <input
                                  className={`form-control ${
                                    formErrors.zip ? "is-invalid" : ""
                                  }`}
                                  type="text"
                                  placeholder="Enter ZIP"
                                  name="zip"
                                  value={formData.zip}
                                  onChange={handleInputChange}
                                />
                                {formErrors.zip && (
                                  <div className="invalid-feedback">
                                    {formErrors.zip}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label htmlFor="city" className="form-label">
                                  City
                                </label>
                                <input
                                  className={`form-control ${
                                    formErrors.city ? "is-invalid" : ""
                                  }`}
                                  type="text"
                                  placeholder="Enter City"
                                  name="city"
                                  value={formData.city}
                                  onChange={handleInputChange}
                                />
                                {formErrors.city && (
                                  <div className="invalid-feedback">
                                    {formErrors.city}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label htmlFor="address" className="form-label">
                                  Address
                                </label>
                                <input
                                  className={`form-control ${
                                    formErrors.address ? "is-invalid" : ""
                                  }`}
                                  type="text"
                                  placeholder="Enter Address"
                                  name="address"
                                  value={formData.address}
                                  onChange={handleInputChange}
                                />
                                {formErrors.address && (
                                  <div className="invalid-feedback">
                                    {formErrors.address}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* card */}
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">Upload photo or logo</h4>
                        <p className="card-title-desc">Photo or Logo </p>
                      </div>
                      <div className="card-body">
                        {!uploadedFiles.length && (
                          <div
                            className={`card-body ${
                              isDragActive ? "active-dropzone" : ""
                            }`}
                            {...getRootProps()}
                            style={dropzoneStyle}
                          >
                            <input {...getInputProps()} />
                            <div className="dz-message needsclick">
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bx-cloud-upload" />
                              </div>
                              <h5>Drop file here or click to upload.</h5>
                            </div>
                          </div>
                        )}
                        {formErrors.uploadedFiles && (
                          <div
                            className="text-danger mb-2"
                            style={{ paddingLeft: "1.25rem" }}
                          >
                            {formErrors.uploadedFiles}
                          </div>
                        )}
                        <br />
                        <div className="row" style={{ paddingLeft: "1.25rem" }}>
                          <div className="col-xl-2 col-6">
                            {!profilePicUploadLoader &&
                              uploadedFiles.file &&
                              uploadedFiles.file.type.startsWith("image/") && (
                                <div className="card">
                                  <div className="card-body">
                                    <img
                                      className="card-img-top img-fluid"
                                      src={uploadedFiles.preview}
                                      alt={`Preview`}
                                    />
                                    <div
                                      className="py-2 text-center"
                                      style={downloadButtonStyle}
                                    >
                                      <span
                                        className="text-danger  flex-grow-1 text-center fw-medium"
                                        onClick={() => setUploadedFiles("")}
                                        style={{ cursor: "pointer" }}
                                      >
                                        Remove
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                            {profilePicUploadLoader && (
                              <div className="d-flex justify-content-center align-item-center">
                                <i
                                  className="fas fa-spinner fa-spin"
                                  style={{ fontSize: 20 }}
                                  title="Loading"
                                />
                              </div>
                            )}
                          </div>
                          {/*  {uploadedFiles.map((uploadedFile, index) => (
                        <div className="col-xl-2 col-6" key={index}>
                          <div className="card">
                            <div className="card-body">
                              {uploadedFile.file.type.startsWith("image/") ? (
                                <img
                                  className="card-img-top img-fluid"
                                  src={uploadedFile.preview}
                                  alt={`Preview-${index}`}
                                />
                              ) : (
                                <img
                                  className="card-img-top img-fluid"
                                  src={fileIcon}
                                  alt={`Preview-${index}`}
                                />
                              )}

                              <div
                                className="py-2 text-center"
                                style={downloadButtonStyle}
                              >
                                <span
                                  className="text-danger  flex-grow-1 text-center fw-medium"
                                  onClick={() => removeFile(uploadedFile)}
                                  style={{ cursor: "pointer" }}
                                >
                                  Remove
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))} */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="d-flex justify-content-end">
                          <div className="right-side-btns">
                            <div className="btn-group1">
                              {userUpdateLoader ? (
                                <button
                                  type="button"
                                  className="btn btn-primary waves-light waves-effect"
                                  style={{ marginRight: 5, minWidth: 55 }}
                                >
                                  <i
                                    className="fas fa-spinner fa-spin"
                                    title="Loading"
                                  ></i>
                                </button>
                              ) : (
                                <button
                                  disabled={profilePicUploadLoader}
                                  onClick={handleSubmit}
                                  type="submit"
                                  className="btn btn-primary waves-light waves-effect"
                                  style={{ marginRight: 5 }}
                                >
                                  Save
                                </button>
                              )}
                              {/* <Link
                                to="lists.html"
                                className="btn btn-primary waves-light waves-effect back_button"
                              >
                                Back
                              </Link> */}
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                      {/* end col */}
                    </div>{" "}
                    {/* end row */}
                  </div>
                  {/* end Col */}
                </div>
                {/* end row */}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    userUpdateLoader: state.auth.userUpdateLoader,
    profileDataLoader: state.auth.profileDataLoader,
    profilePicUploadLoader: state.auth.profilePicUploadLoader,
  };
};

export default connect(mapStateToProps, {
  actionUpdateUserProfile,
  actionUploadProfilePic,
})(Profile);
