import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import AuthLock from "../pages/AuthLock";
import withLayout from "./LayoutHOC";
import { connect } from "react-redux";
import { actionMe } from "../store/actions/authAction";
import { setAuthToken } from "../utils/helper";

// Import components directly without lazy loading
import Home from "../pages/Home";
import Inbox from "../pages/Inbox";
import SentFaxes from "../pages/SentFaxes";
import Trash from "../pages/Trash";
import ReadEmail from "../pages/ReadEmail";
import FaxCovers from "../pages/FaxCovers";
import CreateFaxCover from "../pages/CreateFaxCover";
import FaxAlerts from "../pages/FaxAlerts";
import CreateFaxAlert from "../pages/CreateFaxAlert";
import FaxHeaders from "../pages/FaxHeaders";
import CreateFaxHeader from "../pages/CreateFaxHeader";
import FaxBlackList from "../pages/FaxBlackList";
import CreateFaxBlackList from "../pages/CreateFaxBlackList";
import PortingRequest from "../pages/PortingRequest";
import FaxPhoneNumbers from "../pages/FaxPhoneNumbers";
import SearchDocument from "../pages/SearchDocument";
import OcrRead from "../pages/OcrRead";
import Contacts from "../pages/Contacts";
import AddNewContact from "../pages/AddNewContact";
import AddNewList from "../pages/AddNewList";
import Lists from "../pages/Lists";
import Referral from "../pages/Referral";
import ReferralInviteForm from "../pages/ReferralInviteForm";
import FaxDevices from "../pages/FaxDevices";
import DeviceConfiguration from "../pages/DeviceConfiguration";
import DeviceCallReport from "../pages/DeviceCallReport";
import GiftFaxMachine from "../pages/GiftFaxMachine";
import Profile from "../pages/Profile";
import AddFund from "../pages/AddFund";
import Invoices from "../pages/Invoices";
import ComponseFax from "../pages/ComponseFax";
import FaxPhoneNumbersList from "../pages/FaxPhoneNumbersList";
import PhoneNumberSetRoute from "../pages/PhoneNumberSetRoute";
import Queues from "../pages/Queues";
import NotFound from "../pages/NotFound";
import VerifiedPhoneFaxNumbersList from "../pages/VerifiedPhoneFaxNumbersList";
import AddVerifiedNumber from "../pages/AddVerifiedNumber";
import VerifiedEmailList from "../pages/VerifiedEmailList";
import AddVerifiedEmail from "../pages/AddVerifiedEmail";

// With Layout
const HomeWithLayout = withLayout(Home);
const InboxWithLayout = withLayout(Inbox);
const SentFaxesWithLayout = withLayout(SentFaxes);
const TrashWithLayout = withLayout(Trash);
const ReadEmailWithLayout = withLayout(ReadEmail);
const FaxCoversWithLayout = withLayout(FaxCovers);
const CreateFaxCoverWithLayout = withLayout(CreateFaxCover);

const FaxAlertsWithLayout = withLayout(FaxAlerts);
const CreateFaxAlertWithLayout = withLayout(CreateFaxAlert);

const FaxHeaderWithLayout = withLayout(FaxHeaders);
const CreateFaxHeaderWithLayout = withLayout(CreateFaxHeader);

const FaxBlackListWithLayout = withLayout(FaxBlackList);
const CreateFaxBlackListWithLayout = withLayout(CreateFaxBlackList);
const PortingRequestWithLayout = withLayout(PortingRequest);
const FaxPhoneNumbersWithLayout = withLayout(FaxPhoneNumbers);
const SearchDocumentWithLayout = withLayout(SearchDocument);
const ContactsWithLayout = withLayout(Contacts);
const OCRWithLayout = withLayout(OcrRead);
const AddNewContactWithLayout = withLayout(AddNewContact);
const AddNewListWithLayout = withLayout(AddNewList);
const ListWithLayout = withLayout(Lists);
const ReferralWithLayout = withLayout(Referral);
const ReferralInviteFormWithLayout = withLayout(ReferralInviteForm);
const FaxDevicesWithLayout = withLayout(FaxDevices);
const DeviceConfigurationWithLayout = withLayout(DeviceConfiguration);
const DeviceCallReportWithLayout = withLayout(DeviceCallReport);
const GiftFaxMachineWithLayout = withLayout(GiftFaxMachine);
const ProfileWithLayout = withLayout(Profile);
const AddFundWithLayout = withLayout(AddFund);
const InvoicesWithLayout = withLayout(Invoices);
const ComposeWithLayout = withLayout(ComponseFax);
const FaxPhoneNumbersListWithLayout = withLayout(FaxPhoneNumbersList);
const VerifiedPhoneFaxNumbersListWithLayout = withLayout(
  VerifiedPhoneFaxNumbersList
);
const VerifiedEmailListWithLayout = withLayout(VerifiedEmailList);
const PhoneNumberSetRouteWithLayout = withLayout(PhoneNumberSetRoute);
const QueuesWithLayout = withLayout(Queues);

const NotFoundWithLayout = withLayout(NotFound);
const AddVerifiedNumberWithLayout = withLayout(AddVerifiedNumber);
const AddVerifiedEmailWithLayout = withLayout(AddVerifiedEmail);

if (localStorage.authToken) {
  setAuthToken(localStorage.authToken);
}

const AppRoutes = ({ actionMe, user }) => {
  useEffect(() => {
    const token = localStorage.getItem("authToken");
    setAuthToken(token);
    actionMe();
    console.log({ token });
  }, [actionMe]);

  /*   useEffect(() => {
    if (user && Object.keys(user.user_settings).length > 0) {
      if (user.user_settings.user_view === "DASHBOARD") {
        navigate("/");
      } else if (user.user_settings.user_view === "INBOX") {
        navigate("/inbox");
      }
    }
  }, [user]); */

  return (
    <Routes>
      <Route path="/" element={<HomeWithLayout />} />
      <Route path="/inbox" element={<InboxWithLayout />} />
      <Route path="/sent-faxes" element={<SentFaxesWithLayout />} />
      <Route path="/outbox" element={<SentFaxesWithLayout />} />
      <Route path="/failed" element={<SentFaxesWithLayout />} />
      <Route path="/scheduled" element={<SentFaxesWithLayout />} />
      <Route path="/trash" element={<TrashWithLayout />} />
      <Route path="/inbox/:param" element={<InboxWithLayout />} />

      <Route path="/compose-fax" element={<ComposeWithLayout />} />
      {/* <Route path="/read-mail" element={<ReadEmailWithLayout />} /> */}
      <Route path="/read-mail/:param" element={<ReadEmailWithLayout />} />

      <Route path="/fax-covers" element={<FaxCoversWithLayout />} />
      <Route path="/fax-covers/create" element={<CreateFaxCoverWithLayout />} />

      <Route path="/fax-alerts" element={<FaxAlertsWithLayout />} />
      <Route path="/fax-alerts/create" element={<CreateFaxAlertWithLayout />} />

      <Route path="/fax-headers" element={<FaxHeaderWithLayout />} />
      <Route
        path="/fax-headers/create"
        element={<CreateFaxHeaderWithLayout />}
      />

      <Route path="/fax-blacklist" element={<FaxBlackListWithLayout />} />
      <Route
        path="/fax-blacklist/create"
        element={<CreateFaxBlackListWithLayout />}
      />

      <Route path="/porting-request" element={<PortingRequestWithLayout />} />
      <Route
        path="/buy-fax-phone-numbers"
        element={<FaxPhoneNumbersWithLayout />}
      />
      <Route
        path="/phone-numbers-list"
        element={<FaxPhoneNumbersListWithLayout />}
      />
      <Route
        path="/phone-number-set-route/:numberId"
        element={<PhoneNumberSetRouteWithLayout />}
      />
      {/* Verified Phone / Fax / Email Routes Start */}
      <Route
        path="/verified-phone-fax-list"
        element={<VerifiedPhoneFaxNumbersListWithLayout />}
      />
      <Route
        path="/add-verified-phone-fax-number"
        element={<AddVerifiedNumberWithLayout />}
      />

      <Route
        path="/verified-email-list"
        element={<VerifiedEmailListWithLayout />}
      />

      <Route
        path="/add-verified-email"
        element={<AddVerifiedEmailWithLayout />}
      />

      {/* Verified Phone / Fax / Email Routes Start Ends */}
      <Route path="/search-document" element={<SearchDocumentWithLayout />} />
      <Route path="/ocr" element={<OCRWithLayout />} />
      <Route path="/contacts" element={<ContactsWithLayout />} />
      <Route path="/add-new-contact" element={<AddNewContactWithLayout />} />
      <Route path="/add-new-list" element={<AddNewListWithLayout />} />
      <Route path="/lists" element={<ListWithLayout />} />
      <Route path="/referral" element={<ReferralWithLayout />} />
      <Route
        path="/referral-invitation-form"
        element={<ReferralInviteFormWithLayout />}
      />

      <Route path="/fax-devices" element={<FaxDevicesWithLayout />} />
      <Route
        path="/fax-devices/configuration"
        element={<DeviceConfigurationWithLayout />}
      />
      <Route
        path="/fax-devices/call-report"
        element={<DeviceCallReportWithLayout />}
      />
      <Route path="/gift-fax-machine" element={<GiftFaxMachineWithLayout />} />
      <Route path="/profile" element={<ProfileWithLayout />} />
      <Route path="/billing/add-fund" element={<AddFundWithLayout />} />
      <Route path="/invoices" element={<InvoicesWithLayout />} />
      <Route path="/queues/:type" element={<QueuesWithLayout />} />
      <Route path="/auth-lock" element={<AuthLock />} />
      <Route path="*" element={<NotFoundWithLayout />} />
    </Routes>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};
export default connect(mapStateToProps, {
  actionMe,
})(AppRoutes);
