import React, { useEffect, useRef, useState } from "react";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import DefaultView from "../components/DefaultView";
import DeviceTableRow from "../components/DeviceTableRow";
import { Link } from "react-router-dom";
const records = [
  {
    index: 1,
    id: "A12345",
    amount: "250.50",
    date: "2023/09/15",
    status: "Active",
    action: "Edit/Delete",
  },
  {
    index: 2,
    id: "B56789",
    amount: "120.75",
    date: "2023/09/16",
    status: "Deactive",
    action: "Edit/Delete",
  },
  {
    index: 3,
    id: "C98765",
    amount: "350.25",
    date: "2023/09/17",
    status: "Active",
    action: "Edit/Delete",
  },
  {
    index: 4,
    id: "D54321",
    amount: "450.90",
    date: "2023/09/18",
    status: "Active",
    action: "Edit/Delete",
  },
  {
    index: 5,
    id: "E24680",
    amount: "180.60",
    date: "2023/09/19",
    status: "Deactive",
    action: "Edit/Delete",
  },
  {
    index: 6,
    id: "F13579",
    amount: "300.30",
    date: "2023/09/20",
    status: "Active",
    action: "Edit/Delete",
  },
  {
    index: 7,
    id: "G86420",
    amount: "90.25",
    date: "2023/09/21",
    status: "Active",
    action: "Edit/Delete",
  },
  {
    index: 8,
    id: "H75319",
    amount: "550.00",
    date: "2023/09/22",
    status: "Deactive",
    action: "Edit/Delete",
  },
  {
    index: 9,
    id: "I98765",
    amount: "75.50",
    date: "2023/09/23",
    status: "Active",
    action: "Edit/Delete",
  },
  {
    index: 10,
    id: "J24680",
    amount: "420.25",
    date: "2023/09/24",
    status: "Deactive",
    action: "Edit/Delete",
  },
  // Add more realistic records as needed
];

function DeviceList() {
  const datepickerRef = useRef(null);
  const [filteredRecords, setFilteredRecords] = useState(records);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    // Initialize Flatpickr after the component has mounted
    flatpickr(datepickerRef.current, {
      mode: "range",
      enableTime: true, // You can customize the options here
      dateFormat: "Y-m-d H:i",
    });
  }, []);

  useEffect(() => {
    const filtered = records.filter((record) =>
      record.id.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredRecords(filtered);
  }, [searchText]);
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 main_heading">Device List</h4>
                <div className="page-title-right">
                  <DefaultView />
                </div>
              </div>
            </div>
          </div>
          {/* end page title */}
          <div className="row">
            <div className="col-lg-12">
              {/* start card */}
              <div className="invoiceCard mb-3 mt-4">
                <div className="row align-items-end">
                  <div className="col-md-6 col-sm-12">
                    <div className="mb-3 dateWrap">
                      <label className="form-label">Date Range:</label>
                      <input
                        type="text"
                        className="form-control flatpickr-input"
                        id="datepicker-range"
                        readOnly="readonly"
                        ref={datepickerRef} // Reference to the input element
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 justify-content-end d-sm-flex">
                    <div className="mb-3 dt-buttons btn-groups flex-wrap ">
                      {/* <button class="btn btn-secondary buttons-copy buttons-html5" tabindex="0" aria-controls="datatable-buttons" type="button">
                                  <span>Copy</span>
                                </button> */}
                      <button
                        className="btn btn-success buttons-excel buttons-html5"
                        tabIndex={0}
                        aria-controls="datatable-buttons"
                        type="button"
                        style={{ marginRight: 5 }}
                      >
                        <span>Excel</span>
                      </button>
                      <button
                        className="btn btn-primary buttons-pdf buttons-html5"
                        tabIndex={0}
                        aria-controls="datatable-buttons"
                        type="button"
                      >
                        <span>PDF</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card invoiceCard">
                <div className="card-header">
                  <h4 className="card-title">Device List</h4>
                  <p className="card-title-desc">
                    The Buttons extension for DataTables provides a common set
                    of options, API methods and styling to display buttons on a
                    page that will interact with a DataTable. The core library
                    provides the based framework upon which plug-ins can built.{" "}
                  </p>
                </div>
                <div className="row d-none">
                  <div className="col-lg-4">
                    <div className="classic-colorpicker" />
                  </div>
                  <div className="col-lg-4">
                    <div className="monolith-colorpicker" />
                  </div>
                  <div className="col-lg-4">
                    <div className="nano-colorpicker" />
                  </div>
                </div>
                <div className="card-body">
                  <div
                    id="datatable-buttons_wrapper"
                    className="dataTables_wrapper dt-bootstrap4 no-footer"
                  >
                    <div className="dateSearch mb-3">
                      <div className="row">
                        <div className="col-sm-12 col-md-6">
                          <div className="mb-3">
                            <label>Listbox: </label>
                            <select className="form-select">
                              <option>Listbox</option>
                              <option>Large select</option>
                              <option>Small select</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div
                            id="datatable-buttons_filter"
                            className="dataTables_filter"
                          >
                            <div className="d-flex align-items-start flex-column searchWrp">
                              <label>Search:</label>
                              <input
                                type="search"
                                className="form-control"
                                placeholder="Search item"
                                aria-controls="datatable-buttons"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="table-responsive">
                          <table
                            id="datatable-buttons"
                            className="invoiceTable table table-bordered dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                            role="grid"
                            aria-describedby="datatable-buttons_info"
                            style={{ width: 1253 }}
                          >
                            <thead>
                              <tr role="row">
                                <th
                                  className="sorting sorting_asc"
                                  tabIndex={0}
                                  aria-controls="datatable-buttons"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-sort="ascending"
                                  aria-label="Name: activate to sort column descending"
                                >
                                  ID
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="datatable-buttons"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Position: activate to sort column ascending"
                                >
                                  Device Number
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="datatable-buttons"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Office: activate to sort column ascending"
                                >
                                  Activation Date
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="datatable-buttons"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Start date: activate to sort column ascending"
                                >
                                  Billing Date
                                </th>
                                <th
                                  className="sorting text-center"
                                  tabIndex={0}
                                  aria-controls="datatable-buttons"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Start date: activate to sort column ascending"
                                >
                                  Status
                                </th>
                                <th
                                  className="sorting text-center"
                                  tabIndex={0}
                                  aria-controls="datatable-buttons"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Salary: activate to sort column ascending"
                                >
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {filteredRecords.map((record, index) => (
                                <DeviceTableRow
                                  key={index}
                                  index={index}
                                  id={record.id}
                                  amount={record.amount}
                                  date={record.date}
                                  isActive={index % 2 === 0}
                                />
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div
                          className="dataTables_info"
                          id="datatable-buttons_info"
                          role="status"
                          aria-live="polite"
                        >
                          Showing 1 to 10 of 57 entries
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <div
                          className="dataTables_paginate paging_simple_numbers"
                          id="datatable-buttons_paginate"
                        >
                          <ul className="pagination">
                            <li
                              className="paginate_button page-item previous disabled"
                              id="datatable-buttons_previous"
                            >
                              <Link
                                to="#"
                                aria-controls="datatable-buttons"
                                data-dt-idx={0}
                                tabIndex={0}
                                className="page-link"
                              >
                                Previous
                              </Link>
                            </li>
                            <li className="paginate_button page-item active">
                              <Link
                                to="#"
                                aria-controls="datatable-buttons"
                                data-dt-idx={1}
                                tabIndex={0}
                                className="page-link"
                              >
                                1
                              </Link>
                            </li>
                            <li className="paginate_button page-item ">
                              <Link
                                to="#"
                                aria-controls="datatable-buttons"
                                data-dt-idx={2}
                                tabIndex={0}
                                className="page-link"
                              >
                                2
                              </Link>
                            </li>
                            <li className="paginate_button page-item ">
                              <Link
                                to="#"
                                aria-controls="datatable-buttons"
                                data-dt-idx={3}
                                tabIndex={0}
                                className="page-link"
                              >
                                3
                              </Link>
                            </li>
                            <li className="paginate_button page-item ">
                              <Link
                                to="#"
                                aria-controls="datatable-buttons"
                                data-dt-idx={4}
                                tabIndex={0}
                                className="page-link"
                              >
                                4
                              </Link>
                            </li>
                            <li className="paginate_button page-item ">
                              <Link
                                to="#"
                                aria-controls="datatable-buttons"
                                data-dt-idx={5}
                                tabIndex={0}
                                className="page-link"
                              >
                                5
                              </Link>
                            </li>
                            <li className="paginate_button page-item ">
                              <Link
                                to="#"
                                aria-controls="datatable-buttons"
                                data-dt-idx={6}
                                tabIndex={0}
                                className="page-link"
                              >
                                6
                              </Link>
                            </li>
                            <li
                              className="paginate_button page-item next"
                              id="datatable-buttons_next"
                            >
                              <Link
                                to="#"
                                aria-controls="datatable-buttons"
                                data-dt-idx={7}
                                tabIndex={0}
                                className="page-link"
                              >
                                Next
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end card */}
            </div>
            {/* end col */}
          </div>
        </div>
        {/* container-fluid */}
      </div>
      {/* End Page-content */}
    </div>
  );
}

export default DeviceList;
