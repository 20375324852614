import moment from "moment";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import LoaderGif from "../assets/loader/Rolling.gif";
import {
  actionDeleteFaxBlacklist,
  actionUpdateFaxBlacklist,
} from "../store/actions/settingAction";

const FaxBlackListTableRow = ({
  user,
  id,
  name,

  date,
  number,
  actionDeleteFaxBlacklist,
  deleteFaxBlackListLoader,
  actionUpdateFaxBlacklist,
  updateFaxBlackListLoader,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [updatedNumber, setUpdatedNumber] = useState(number);
  const [updatedName, setUpdatedName] = useState(name);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = (e) => {
    e.preventDefault();
    // You can implement the save functionality here
    // For now, let's just print the updated values to the console
    console.log("Updated Name:", updatedName);

    const d = {
      blacklist_number: updatedNumber,
      // id: id.toString(),
      blacklist_name: updatedName,
      blacklist_number: updatedNumber,
    };
    actionUpdateFaxBlacklist(d, id);
    setIsEditing(false);
  };

  const handleRowDoubleClick = () => {
    setIsEditing(true);
  };

  const handleDelete = (Id) => {
    // Display a confirmation modal
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this blacklist?"
    );

    if (isConfirmed) {
      actionDeleteFaxBlacklist(Id);
    } else {
      console.log("Deletion cancelled");
    }
  };

  return (
    <tr
      data-id={id}
      style={{ cursor: "pointer" }}
      onDoubleClick={handleRowDoubleClick}
    >
      <td data-field="id" width={"10%"}>
        {isEditing ? (
          <input
            className="form-control"
            type="text"
            value={updatedName}
            onChange={(e) => setUpdatedName(e.target.value)}
          />
        ) : (
          name
        )}
      </td>
      <td data-field="name" width={"10%"}>
        {isEditing ? (
          <input
            className="form-control"
            type="number"
            value={updatedNumber}
            onChange={(e) => setUpdatedNumber(e.target.value)}
          />
        ) : (
          number
        )}
      </td>
      <td width={"10%"}>{moment(date).format("YYYY-MM-DD hh:mm A")}</td>
      <td width={"10%"}>
        {isEditing ? (
          <Link
            to={"/"}
            className="btn btn-outline-secondary btn-sm edit"
            title="Save"
            onClick={handleSaveClick}
          >
            <i className="fas fa-save" title="Save" />
          </Link>
        ) : (
          <button
            className="btn btn-outline-secondary btn-sm edit"
            onClick={handleEditClick}
          >
            {updateFaxBlackListLoader == id ? (
              <img src={LoaderGif} alt="Loading" />
            ) : (
              <i className="fas fa-pencil-alt" title="Edit" />
            )}
          </button>
        )}

        <button
          onClick={() => handleDelete(id)}
          className="btn btn-outline-secondary btn-sm delete"
          title="Delete"
          style={{ marginLeft: 5 }}
        >
          {deleteFaxBlackListLoader == id ? (
            <img src={LoaderGif} alt="Loading" />
          ) : (
            <i className="fas fa-trash-alt" title="Delete" />
          )}
        </button>
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => {
  return {
    deleteFaxBlackListLoader: state.setting.deleteFaxBlackListLoader,
    updateFaxBlackListLoader: state.setting.updateFaxBlackListLoader,
    user: state.auth.user,
  };
};
export default connect(mapStateToProps, {
  actionUpdateFaxBlacklist,
  actionDeleteFaxBlacklist,
})(FaxBlackListTableRow);
