import axios from "axios";
import * as actionTypes from "../action";
import { toast as message } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const actionGetReceivedMessages =
  (offset = 0, important = null, q = null, limit = 20) =>
  async (dispatch, getState) => {
    console.log("actionGetReceivedMessages calling");
    dispatch({ type: actionTypes.GET_RECEIVED_MESSAGES_LOADER, payload: true });

    try {
      const url = `${BASE_URL}/inbox/get-recvd-msgs?limit=${limit}&offset=${offset}${
        q ? `&search=${q}` : ""
      }${important == "YES" ? `&is_important=${"Yes"}` : ""}`;

      let res = await axios.get(url);

      dispatch({
        type: actionTypes.GET_RECEIVED_MESSAGES_LOADER,
        payload: false,
      });
      if (parseInt(res.data.http_status_code) == 200) {
        dispatch({
          type: actionTypes.RECEIVED_MESSAGES_DATA,
          payload: res.data.result.data,
        });
      } else {
        console.log(res.data.error.error_message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      }
      dispatch({
        type: actionTypes.GET_RECEIVED_MESSAGES_LOADER,
        payload: false,
      });
    }
  };

export const actionMarkReadMessages = (data) => async (dispatch, getState) => {
  console.log("actionMarkReadMessages calling");
  dispatch({ type: actionTypes.MARK_READ_MESSAGES_LOADER, payload: true });

  try {
    let res = await axios.patch(`${BASE_URL}/inbox/read/add`, data);

    dispatch({
      type: actionTypes.MARK_READ_MESSAGES_LOADER,
      payload: false,
    });

    if (parseInt(res.data.http_status_code) == 201) {
      dispatch({
        type: actionTypes.MARK_READ_MESSAGES,
        payload: data.recvd_msg_ID,
      });
      message.success("mark as read successfully.");
    } else {
      console.log(res.data.error.error_message);
      message.error(res.data.error.error_message);
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      message.error(error.response.data.message);
    } 

    dispatch({
      type: actionTypes.MARK_READ_MESSAGES_LOADER,
      payload: false,
    });
  }
};

export const actionMarkUnreadMessages =
  (data) => async (dispatch, getState) => {
    console.log("actionMarkUnreadMessages calling");
    dispatch({ type: actionTypes.MARK_UNREAD_MESSAGES_LOADER, payload: true });

    try {
      let res = await axios.patch(`${BASE_URL}/inbox/read/remove`, data);

      dispatch({
        type: actionTypes.MARK_UNREAD_MESSAGES_LOADER,
        payload: false,
      });

      if (parseInt(res.data.http_status_code) == 201) {
        dispatch({
          type: actionTypes.MARK_UNREAD_MESSAGES,
          payload: data.recvd_msg_ID,
        });
        message.success("mark as unread successfully.");
      } else {
        console.log(res.data.error.error_message);
        message.error(res.data.error.error_message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } 

      dispatch({
        type: actionTypes.MARK_UNREAD_MESSAGES_LOADER,
        payload: false,
      });
    }
  };

export const actionMarkAddImportant =
  (data, messages) => async (dispatch, getState) => {
    console.log("actionMarkAddImportant calling");
    dispatch({ type: actionTypes.MARK_ADD_IMPORTANT_LOADER, payload: true });

    try {
      let res = await axios.patch(`${BASE_URL}/inbox/important/add`, data);
      dispatch({
        type: actionTypes.MARK_ADD_IMPORTANT_LOADER,
        payload: false,
      });
      if (parseInt(res.data.http_status_code) == 201) {
        dispatch({
          type: actionTypes.MARK_ADD_IMPORTANT_MESSAGES,
          payload: messages,
        });
        message.success("Importance marked successfully.");
      } else {
        console.log(res.data.error.error_message);
        message.error(res.data.error.error_message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } 
      dispatch({
        type: actionTypes.MARK_ADD_IMPORTANT_LOADER,
        payload: false,
      });
    }
  };

export const actionMarkRemoveImportant =
  (data, messages, params) => async (dispatch, getState) => {
    console.log("actionMarkRemoveImportant calling", params);
    dispatch({ type: actionTypes.MARK_REMOVE_IMPORTANT_LOADER, payload: true });

    try {
      let res = await axios.patch(`${BASE_URL}/inbox/important/remove`, data);
      dispatch({
        type: actionTypes.MARK_REMOVE_IMPORTANT_LOADER,
        payload: false,
      });
      if (parseInt(res.data.http_status_code) == 201) {
        dispatch({
          type: actionTypes.MARK_REMOVE_IMPORTANT_MESSAGES,
          payload: messages,
        });
        params &&
          params.listType &&
          dispatch(
            actionGetReceivedMessages(
              params.offset,
              params.listType ? "YES" : "",
              params.searchText ? params.searchText : ""
            )
          );
        message.success("Remove From Importance successfully.");
      } else {
        console.log(res.data.error.error_message);
        message.error(res.data.error.error_message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } 
      dispatch({
        type: actionTypes.MARK_REMOVE_IMPORTANT_LOADER,
        payload: false,
      });
    }
  };

export const actionMarkAddStarred = (data) => async (dispatch, getState) => {
  console.log("actionMarkAddStarred calling");

  try {
    let res = await axios.patch(`${BASE_URL}/inbox/starred/add`, data);
    if (parseInt(res.data.http_status_code) == 201) {
      dispatch({
        type: actionTypes.MARK_ADD_STARRED_MESSAGES,
        payload: data,
      });
    } else {
      console.log(res.data.error.error_message);
      message.error(res.data.error.error_message);
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      message.error(error.response.data.message);
    } else {
      message.error("An error occurred");
    }
  }
};

export const actionMarkRemoveStarred = (data) => async (dispatch, getState) => {
  console.log("actionMarkRemoveStarred calling");
  try {
    let res = await axios.patch(`${BASE_URL}/inbox/starred/remove`, data);

    if (parseInt(res.data.http_status_code) == 201) {
      dispatch({
        type: actionTypes.MARK_REMOVE_STARRED_MESSAGES,
        payload: data,
      });
    } else {
      console.log(res.data.error.error_message);
      message.error(res.data.error.error_message);
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      message.error(error.response.data.message);
    } else {
      message.error("An error occurred");
    }
  }
};

export const actionMarkTrashMessage =
  (data, params, setShowEmailDetailID) => async (dispatch, getState) => {
    console.log("actionMarkTrashMessage calling", params);
    dispatch({ type: actionTypes.MARK_TRASH_LOADER, payload: true });

    try {
      let res = await axios.post(`${BASE_URL}/inbox/trash_recvd_msgs`, data);
      dispatch({
        type: actionTypes.MARK_TRASH_LOADER,
        payload: false,
      });
      if (parseInt(res.data.http_status_code) == 200) {
        setShowEmailDetailID && setShowEmailDetailID("");
        dispatch(
          actionGetReceivedMessages(
            params.offset,
            params.listType ? "YES" : "",
            params.searchText ? params.searchText : ""
          )
        );
        message.success("send to trash successfully.");
      } else {
        console.log(res.data.error.error_message);
        message.error(res.data.error.error_message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } 
      dispatch({
        type: actionTypes.MARK_TRASH_LOADER,
        payload: false,
      });
    }
  };

export const actionGetAllTrashedMessages =
  (offset = 0, q = null, limit = 20) =>
  async (dispatch, getState) => {
    console.log("actionGetAllTrashedMessages calling");
    dispatch({ type: actionTypes.GET_TRASHED_MESSAGES_LOADER, payload: true });

    try {
      const url = `${BASE_URL}/inbox/get-trash-recvd-msgs?limit=${limit}&offset=${offset}${
        q ? `&search=${q}` : ""
      }`;

      let res = await axios.get(url);

      dispatch({
        type: actionTypes.GET_TRASHED_MESSAGES_LOADER,
        payload: false,
      });
      if (parseInt(res.data.http_status_code) == 200) {
        dispatch({
          type: actionTypes.TRASHED_MESSAGES_DATA,
          payload: res.data.result.data,
        });
      } else {
        console.log(res.data.error.error_message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } 

      dispatch({
        type: actionTypes.GET_TRASHED_MESSAGES_LOADER,
        payload: false,
      });
    }
  };

export const actionRemoveMessageFromTrash =
  (data, params, setShowEmailDetailID) => async (dispatch, getState) => {
    console.log("actionRemoveMessageFromTrash calling", params);
    dispatch({ type: actionTypes.REMOVE_FROM_TRASH_LOADER, payload: true });

    try {
      let res = await axios.post(
        `${BASE_URL}/inbox/remove_trash_recvd_msgs`,
        data
      );
      dispatch({
        type: actionTypes.REMOVE_FROM_TRASH_LOADER,
        payload: false,
      });
      if (parseInt(res.data.http_status_code) == 200) {
        setShowEmailDetailID && setShowEmailDetailID("");
        dispatch(
          actionGetAllTrashedMessages(
            params.offset,
            params.searchText ? params.searchText : ""
          )
        );
        message.success("Remove from trash successfully.");
      } else {
        console.log(res.data.error.error_message);
        message.error(res.data.error.error_message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } 
      dispatch({
        type: actionTypes.REMOVE_FROM_TRASH_LOADER,
        payload: false,
      });
    }
  };

export const actionGetMessageDetails =
  (Id, type) => async (dispatch, getState) => {
    console.log("actionGetMessageDetails calling");
    dispatch({ type: actionTypes.GET_DETAIL_MESSAGES_LOADER, payload: true });

    try {
      let url = "";
      if (type == "SENT-FAX") {
        url = `${BASE_URL}/inbox/get-sent-msgs-details?sent_msg_ID=${Id}`;
      } else if (type == "INBOX") {
        url = `${BASE_URL}/inbox/get-recvd-msgs-details?recvd_msg_ID=${Id}`;
      } else {
        url = `${BASE_URL}/inbox/get-trash-msgs-details?trash_msg_ID=${Id}`;
      }

      let res = await axios.get(url);

      dispatch({
        type: actionTypes.GET_DETAIL_MESSAGES_LOADER,
        payload: false,
      });
      if (parseInt(res.data.http_status_code) == 200) {
        dispatch({
          type: actionTypes.DETAIL_MESSAGES_DATA,
          payload: res.data.result.data.message,
        });
      } else {
        console.log(res.data.error.error_message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } 

      dispatch({
        type: actionTypes.GET_DETAIL_MESSAGES_LOADER,
        payload: false,
      });
    }
  };

export const actionInboxDetailPageSetIsRead = (id) => async (dispatch) => {
  dispatch({
    type: actionTypes.MARK_READ_MESSAGES,
    payload: [id],
  });
};

export const actionComposeFax =
  (data, radioType) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.COMPOSE_FAX_LOADER, payload: true });

    try {
      let res = await axios.post(`${BASE_URL}/inbox/insert_sent_msgs`, data);
      dispatch({
        type: actionTypes.COMPOSE_FAX_LOADER,
        payload: false,
      });
      if (parseInt(res.data.http_status_code) == 201) {
        radioType == "SEND_NOW"
          ? message.success("Fax has been sent.")
          : message.success("Fax has been scheduled.");
        return true;
      } else {
        console.log(res.data.error.error_message);
        message.error(res.data.error.error_message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } 
      dispatch({
        type: actionTypes.COMPOSE_FAX_LOADER,
        payload: false,
      });
    }
  };

export const actionSearchFaxDocument = (data) => async (dispatch, getState) => {
  console.log("actionSearchFaxDocument calling", data);
  dispatch({ type: actionTypes.SEARCH_FAX_LOADER, payload: true });

  try {
    let res = await axios.post(
      `${BASE_URL}/fax-document/search-document`,
      data
    );
    dispatch({
      type: actionTypes.SEARCH_FAX_LOADER,
      payload: false,
    });

    if (parseInt(res.status) === 200) {
      // setSearchResults && setSearchResults(res.data);
      dispatch(actionGetOcrRequestAvailability());
      message.success("Search request has been created successfully.");
    } else {
      console.log(res.data.error.error_message);
      message.error(res.data.error.error_message);
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      message.error(error.response.data.message);
    } else {
      message.error("An error occurred");
    }
    dispatch({
      type: actionTypes.SEARCH_FAX_LOADER,
      payload: false,
    });
  }
};

export const actionGetOcrRequestAvailability =
  () => async (dispatch, getState) => {
    console.log("actionGetOcrRequestAvailability calling");
    dispatch({
      type: actionTypes.GET_OCR_REQUEST_AVAILABILITY_LOADER,
      payload: true,
    });

    try {
      let res = await axios.get(
        `${BASE_URL}/fax-document/ocr-request-availability`
      );

      dispatch({
        type: actionTypes.GET_OCR_REQUEST_AVAILABILITY_LOADER,
        payload: false,
      });

      if (parseInt(res.data.http_status_code) == 200) {
        dispatch({
          type: actionTypes.OCR_REQUEST_AVAILABILITY_DATA,
          payload: res.data.result.data,
        });
      } else {
        console.log(res.data.error.error_message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } 

      dispatch({
        type: actionTypes.GET_OCR_REQUEST_AVAILABILITY_LOADER,
        payload: false,
      });
    }
  };

export const actionGetOcrSearchResults =
  (filePath, requestID) => async (dispatch) => {
    console.log("actionGetOcrSearchResults calling");

    dispatch({
      type: actionTypes.GET_OCR_SEARCH_RESULTS_LOADER,
      payload: requestID,
    });

    try {
      const url = `${BASE_URL}/files${filePath}`;
      let res = await axios.get(url);

      dispatch({
        type: actionTypes.GET_OCR_SEARCH_RESULTS_LOADER,
        payload: false,
      });

      if (res.data && res.data.length) {
        dispatch({
          type: actionTypes.OCR_SEARCH_RESULTS_DATA,
          payload: res.data,
        });
      } else {
        message.error("No data found for given query.");
      }
    } catch (error) {
      message.error("something went wrong, please try again latter.");
      dispatch({
        type: actionTypes.GET_OCR_SEARCH_RESULTS_LOADER,
        payload: false,
      });
    }
  };
