import React, { memo, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DefaultView from "../components/DefaultView";
import EmailLeftbar from "../components/EmailLeftbar";
import FaxAlertTableRow from "../components/FaxAlertTableRow";
import { actionGetAlerts } from "./../store/actions/settingAction";
import moment from "moment";

const FaxAlerts = memo(({ actionGetAlerts, user, alertLoader, alertData }) => {
  useEffect(() => {
    if (user) {
      actionGetAlerts();
    }
  }, [actionGetAlerts, user]);

  return (
    <div className="page-content">
      <div className="container-fluid">
        {/* start page title */}
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 main_heading">Fax Cover</h4>
              <div className="page-title-right">
                <DefaultView />
              </div>
            </div>
            {/* <div className="d-flex justify-content-end mb-3">
              <div className="btn-group float-end">
                <button
                  type="button"
                  className="btn btn-sm btn-success waves-effect"
                >
                  <i className="fa fa-chevron-left" />
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-success waves-effect"
                >
                  <i className="fa fa-chevron-right" />
                </button>
              </div>
            </div> */}
          </div>
        </div>
        {/* end page title */}
        <div className="row">
          <div className="col-12">
            {/* Left sidebar */}
            <EmailLeftbar />
            {/* End Left sidebar */}

            {/* Right Sidebar */}
            <div className="email-rightbar mb-3 compose-section">
              <div className="card">
                <div className="btn-toolbar gap-2 p-3" role="toolbar">
                  <div className="right-side-btns ">
                    <div className="btn-group">
                      <Link
                        to="/fax-alerts/create"
                        className="btn btn-primary waves-light waves-effect"
                      >
                        Add
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-editable table-nowrap align-middle table-edits">
                      <thead>
                        <tr style={{ cursor: "pointer" }}>
                          <th>Alert Value</th>
                          <th>Alert For</th>
                          <th>Alert Type</th>
                          <th>Alert Created</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {alertLoader && (
                          <tr>
                            <td colSpan="5">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <p>Loading Alerts Please Wait..</p>
                              </div>
                            </td>
                          </tr>
                        )}
                        {!alertLoader &&
                          alertData.length > 0 &&
                          alertData.map((data) => (
                            <FaxAlertTableRow
                              key={data.alert_ID}
                              id={data.alert_ID}
                              alert_value={data.alert_value}
                              alert_for={data.alert_for}
                              alert_type={data.alert_type}
                              user={user && user.user_ID}
                              alert_added={moment(data.alert_added).format(
                                "YYYY-MM-DD hh:mm A"
                              )}
                            />
                          ))}
                        {!alertLoader &&
                          alertData &&
                          alertData.length === 0 && (
                            <tr style={{ height: 100 }}>
                              <td colSpan="5">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <p>No Alerts Found!</p>
                                </div>
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* card */}
            </div>
            {/* end  Right Sidebar Col-9 */}
          </div>
        </div>
      </div>
    </div>
  );
});

const mapStateToProps = (state) => {
  return {
    alertLoader: state.setting.alertLoader,
    alertData: state.setting.alertData,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {
  actionGetAlerts,
})(FaxAlerts);
