import React, { useState } from "react";
import { actionAddFaxBlacklist } from "../store/actions/settingAction";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

const FaxBlackListForm = ({
  addfaxBlackListLoader,
  user,
  actionAddFaxBlacklist,
}) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    number: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation logic
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = "Name is required";
    }

    if (!formData.number) {
      newErrors.number = "Number is required";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    const d = {
      blacklist_number: parseInt(formData.number),
      blacklist_name: formData.name,
    };
    console.log({ d });
    actionAddFaxBlacklist(d, navigate);
    // If no errors, you can submit the form data here
    // For now, let's just show a success message
  };
  return (
    <div className="email-rightbar mb-3 compose-section">
      <div className="card">
        <div className="card-header">
          <h5>Create Blacklist</h5>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    className={`form-control ${
                      errors.name ? "is-invalid" : ""
                    }`}
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Enter Blacklist Name"
                  />
                  {errors.name && (
                    <div className="text-danger">{errors.name}</div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="number" className="form-label">
                    Number
                  </label>
                  <input
                    className={`form-control ${
                      errors.number ? "is-invalid" : ""
                    }`}
                    type="number"
                    id="number"
                    name="number"
                    value={formData.number}
                    onChange={handleChange}
                    placeholder="Enter Blacklist Number"
                  />
                  {errors.number && (
                    <div className="text-danger">{errors.number}</div>
                  )}
                </div>
              </div>
            </div>

            <div className="right-side-btns d-flex justify-content-end">
              <div className="btn-group">
                {addfaxBlackListLoader ? (
                  <button
                    type="button"
                    className="btn btn-primary waves-light waves-effect"
                  >
                    <i className="fas fa-spinner fa-spin" title="Loading"></i>
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary waves-light waves-effect"
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    addfaxBlackListLoader: state.setting.addfaxBlackListLoader,
    user: state.auth.user,
  };
};
export default connect(mapStateToProps, {
  actionAddFaxBlacklist,
})(FaxBlackListForm);
