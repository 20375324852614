import React, { useState } from "react";
import { actionCreateAlert } from "../store/actions/settingAction";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const FaxAlertForm = ({ actionCreateAlert, createAlertLoader }) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    alertType: "",
    alertFor: "",
    alertValue: "",
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.alertValue.trim()) {
      newErrors.alertName = "Alert Value is required";
    }

    if (formData.alertType == "EMAIL" && formData.alertValue) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.alertValue)) {
        newErrors.alertValue = "Please enter valid alert email";
      }
    }
    if (formData.alertType == "SMS" && formData.alertValue) {
      const numberRegex = /^\d+$/;
      if (!numberRegex.test(formData.alertValue)) {
        newErrors.alertValue = "Please enter valid mobile number";
      }
    }
    if (!formData.alertValue) {
      newErrors.alertValue = `Please enter ${
        formData.alertType == "SMS" ? "Mobile Number" : "Email"
      }`;
    }

    if (!formData.alertType) {
      newErrors.alertType = "Please select alert type";
    }

    if (!formData.alertFor) {
      newErrors.alertFor = "Please select alert for";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      const d = {
        alert_type: formData.alertType,
        alert_for: formData.alertFor,
        alert_value: formData.alertValue,
      };
      console.log({ d });
      actionCreateAlert(d, navigate);
      console.log("Form submitted with data:", formData);
    } else {
      console.log("Form has validation errors.");
      console.log(errors);
    }
  };

  return (
    <div className="email-rightbar mb-3 compose-section">
      <div className="card">
        <div className="card-header">
          <h5>Create Alert</h5>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="alertType" className="form-label">
                  Alert Type
                </label>
                <select
                  className={`form-select ${
                    errors.alertType ? "is-invalid" : ""
                  }`}
                  id="alertType"
                  name="alertType"
                  value={formData.alertType}
                  onChange={handleInputChange}
                >
                  <option value={""}>Please select alert type</option>
                  <option value={"SMS"}>SMS</option>
                  <option value={"EMAIL"}>EMAIL</option>
                </select>
                {errors.alertType && (
                  <div className="text-danger">{errors.alertType}</div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="alertFor" className="form-label">
                  Alert For
                </label>
                <select
                  className={`form-select ${
                    errors.alertFor ? "is-invalid" : ""
                  }`}
                  id="alertFor"
                  name="alertFor"
                  value={formData.alertFor}
                  onChange={handleInputChange}
                >
                  <option value={""}>Please select alert for</option>
                  <option value="SENT">SENT</option>
                  <option value="RECEIVED">RECEIVED</option>
                  <option value="BOTH">BOTH</option>
                </select>
                {errors.alertFor && (
                  <div className="text-danger">{errors.alertFor}</div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <label htmlFor="alertValue" className="form-label">
                  Alert{" "}
                  {formData.alertType == "SMS" ? "Mobile Number" : "Email"}
                </label>
                <input
                  className={`form-control ${
                    errors.alertValue ? "is-invalid" : ""
                  }`}
                  type="text"
                  id="alertValue"
                  name="alertValue"
                  placeholder={"Type here..."}
                  value={formData.alertValue}
                  onChange={handleInputChange}
                />
                {errors.alertValue && (
                  <div className="text-danger">{errors.alertValue}</div>
                )}
              </div>
            </div>
          </div>

          <div className="right-side-btns d-flex justify-content-end">
            <div className="btn-group">
              {createAlertLoader ? (
                <button
                  type="button"
                  // onClick={handleSubmit}
                  disabled
                  className="btn btn-primary waves-light waves-effect"
                >
                  <i className="fas fa-spinner fa-spin" title="Loading"></i>
                </button>
              ) : (
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="btn btn-primary waves-light waves-effect"
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    createAlertLoader: state.setting.createAlertLoader,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, { actionCreateAlert })(FaxAlertForm);
