import moment from "moment";
import React, { useState } from "react";
import {
  actionUpdateContact,
  actionDeleteContact,
} from "../store/actions/authAction";
import { connect } from "react-redux";
import Swal from "sweetalert2";

const ContactTableRow = ({
  id,
  contactId,
  name,
  number,
  email,
  created_at,
  actionUpdateContact,
  updateContactLoader,
  actionDeleteContact,
  deleteContactLoader,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [data, setData] = useState({
    id,
    contactId,
    name,
    number,
    email,
    created_at,
  });

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = (e) => {
    e.preventDefault();
    // You can implement the save functionality here
    // For now, let's just print the updated data to the console

    const request = {
      contact_name: data.name,
      contact_number: data.number,
      contact_email: data.email,
    };
    console.log("Updated Data:", request);
    actionUpdateContact(contactId, request, setIsEditing);
  };

  const handleRowDoubleClick = () => {
    setIsEditing(true);
  };

  const handleDeleteClick = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Delete Action Call Here
        actionDeleteContact(contactId);
      }
    });
  };

  return (
    <tr
      data-id={data.id}
      style={{ cursor: "pointer" }}
      onDoubleClick={handleRowDoubleClick}
    >
      <td data-field="name" width={"10%"}>
        {isEditing ? (
          <input
            className="form-control"
            type="text"
            value={data.name}
            onChange={(e) => setData({ ...data, name: e.target.value })}
          />
        ) : (
          data.name
        )}
      </td>
      <td data-field="number" width={"10%"}>
        {isEditing ? (
          <input
            className="form-control"
            type="text"
            value={data.number}
            onChange={(e) => setData({ ...data, number: e.target.value })}
          />
        ) : (
          data.number
        )}
      </td>
      <td width={"10%"}>
        {isEditing ? (
          <input
            className="form-control"
            type="text"
            value={data.email}
            onChange={(e) => setData({ ...data, email: e.target.value })}
          />
        ) : (
          data.email
        )}
      </td>
      <td width={"10%"}>
        {moment(data.created_at).format("DD MMM - hh:mm A")}
      </td>

      <td width={"10%"}>
        {isEditing ? (
          updateContactLoader == contactId ? (
            <button
              className="btn btn-outline-secondary btn-sm edit disabled"
              title="Save"
              disabled
            >
              <i className="fas fa-spinner fa-spin" title="Loading"></i>
            </button>
          ) : (
            <button
              className="btn btn-outline-secondary btn-sm edit"
              title="Save"
              onClick={handleSaveClick}
            >
              <i className="fas fa-save" title="Save" />
            </button>
          )
        ) : (
          <button
            className="btn btn-outline-secondary btn-sm edit"
            title="Edit"
            onClick={handleEditClick}
          >
            <i className="fas fa-pencil-alt" title="Edit" />
          </button>
        )}

        <button
          className="btn btn-outline-secondary btn-sm delete"
          title="Delete"
          style={{ marginLeft: 5 }}
          onClick={!deleteContactLoader && handleDeleteClick}
        >
          {deleteContactLoader && deleteContactLoader == contactId ? (
            <i className="fas fa-spinner fa-spin" title="Loading"></i>
          ) : (
            <i className="fas fa-trash-alt" title="Delete" />
          )}
        </button>
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => {
  return {
    updateContactLoader: state.auth.updateContactLoader,
    deleteContactLoader: state.auth.deleteContactLoader,
  };
};

export default connect(mapStateToProps, {
  actionUpdateContact,
  actionDeleteContact,
})(ContactTableRow);
