import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  actionDeleteAlert,
  actionUpdateAlert,
} from "../store/actions/settingAction";
import { connect } from "react-redux";
import LoaderGif from "../assets/loader/Rolling.gif";

const FaxAlertTableRow = ({
  id,
  alert_value,
  alert_for,
  alert_type,
  alert_added,
  actionUpdateAlert,
  actionDeleteAlert,
  alertDeleteloder,
  updateAlertLoader,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [updatedAlertValue, setUpdatedAlertValue] = useState(alert_value);
  const [updatedAlertFor, setUpdatedAlertFor] = useState(alert_for);
  const [updatedAlertType, setUpdatedAlertType] = useState(alert_type);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = (e) => {
    e.preventDefault();
    const updatedData = {
      alert_value: updatedAlertValue,
      alert_for: updatedAlertFor,
      alert_type: updatedAlertType,
      //alert_added: alert_added,
    };
    actionUpdateAlert(id, updatedData);

    setIsEditing(false);
  };

  const handleDelete = () => {
    // Display a confirmation modal
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this cover?"
    );

    if (isConfirmed) {
      actionDeleteAlert(id);
    } else {
      console.log("Deletion cancelled");
    }
  };

  const handleRowDoubleClick = () => {
    setIsEditing(true);
  };

  return (
    <tr
      data-id={alert_for}
      style={{ cursor: "pointer" }}
      onDoubleClick={handleRowDoubleClick}
    >
      <td data-field="alert_value">
        {isEditing ? (
          <input
            type="text"
            className="form-control"
            value={updatedAlertValue}
            onChange={(e) => setUpdatedAlertValue(e.target.value)}
          />
        ) : (
          alert_value
        )}
      </td>

      <td data-field="alert_for">
        {isEditing ? (
          <select
            className="form-control"
            value={updatedAlertFor}
            onChange={(e) => setUpdatedAlertFor(e.target.value)}
          >
            <option value="SENT">SENT</option>
            <option value="RECEIVED">RECEIVED</option>
            <option value="BOTH">BOTH</option>
          </select>
        ) : (
          alert_for
        )}
      </td>

      <td data-field="alert_type">
        {isEditing ? (
          <select
            className="form-control"
            value={updatedAlertType}
            onChange={(e) => setUpdatedAlertType(e.target.value)}
          >
            <option value="SMS">SMS</option>
            <option value="EMAIL">EMAIL</option>
          </select>
        ) : (
          alert_type
        )}
      </td>

      <td>{alert_added}</td>

      <td>
        {isEditing ? (
          <Link
            to="/"
            className="btn btn-outline-secondary btn-sm edit"
            title="Save"
            onClick={(e) => handleSaveClick(e)}
          >
            <i className="fas fa-save" title="Save"></i>
          </Link>
        ) : (
          <a
            className="btn btn-outline-secondary btn-sm edit"
            title="Edit"
            onClick={handleEditClick}
          >
            {updateAlertLoader == id ? (
              <img src={LoaderGif} alt="Loading" />
            ) : (
              <i className="fas fa-pencil-alt" title="Edit"></i>
            )}
          </a>
        )}
        <button
          onClick={handleDelete}
          className="btn btn-outline-secondary btn-sm delete"
          title="Delete"
          style={{ marginLeft: 5 }}
        >
          {alertDeleteloder == id ? (
            <img src={LoaderGif} alt="Loading" />
          ) : (
            <i className="fas fa-trash-alt" title="Delete"></i>
          )}
        </button>
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => {
  return {
    alertDeleteloder: state.setting.alertDeleteloder,
    updateAlertLoader: state.setting.updateAlertLoader,
  };
};

export default connect(mapStateToProps, {
  actionDeleteAlert,
  actionUpdateAlert,
})(FaxAlertTableRow);
