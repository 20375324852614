import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { saveAs } from "file-saver"; // Import saveAs function from file-saver library
import { Link } from "react-router-dom";
import DefaultView from "../components/DefaultView";

const OcrRead = () => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [textFromImage, setTextFromImage] = useState("");
  const [message, setMessage] = useState(""); // State variable for success or failure message
  const [loading, setLoading] = useState(false); // State variable for loading status

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      // Redirect to login URL if authToken is not found
      window.location.href = process.env.REACT_APP_LOGIN_URL;
    }
  }, []);

  const onDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const formData = new FormData();
    formData.append("file", file);

    try {
      setLoading(true); // Set loading to true when processing starts

      const authToken = localStorage.getItem("authToken");

      const response = await fetch(
        `${process.env.REACT_APP_OCR_SERVICE_BASE_URL}/ocr/file-to-text`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: authToken ? authToken : "",
          },
          body: formData,
        }
      );

      if (response.ok) {
        const data = await response.json();
        setTextFromImage(data.result.data.text); // Set the extracted text to state
        setMessage("Success: Text extracted successfully."); // Set success message
      } else {
        setMessage("Error: Failed to process the image."); // Set failure message
        console.error("Failed to process the image");
      }
    } catch (error) {
      setMessage("Error: An error occurred while processing the image."); // Set failure message
      console.error("Error occurred while processing the image", error);
    } finally {
      setLoading(false); // Set loading to false when processing finishes
    }

    // Add the uploaded files to the state
    setUploadedFiles(acceptedFiles);
  };

  const downloadText = () => {
    const blob = new Blob([textFromImage], { type: "text/plain" });
    saveAs(blob, "extracted_text.txt"); // Save the text as a downloadable file
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".pdf, .png, .jpg, .jpeg, .doc, .docx", // Define the accepted file types
  });

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 main_heading">Transcript</h4>
                <div className="page-title-right">
                  <DefaultView />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="mb-3 compose-section">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-title">Dropzone</h4>
                            <p className="card-title-desc">
                              DropzoneJS is an open-source library that provides
                              drag’n’drop file uploads with image previews.
                            </p>
                          </div>
                          <div className="card-body">
                            <div>
                              <form
                                action="#"
                                className="dropzone dz-clickable"
                                {...getRootProps()}
                              >
                                {/* Use getInputProps() to add the input element */}
                                <input {...getInputProps()} />
                                <div className="dz-message needsclick">
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bx-cloud-upload" />
                                  </div>
                                  <h5>Drop files here or click to upload.</h5>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xl-12 col-12">
                        <div className="mb-3">
                          <div className="card">
                            <h5 className="card-header bg-transparent border-bottom text-uppercase">
                              Transcription{" - "}
                              {uploadedFiles.length > 0 &&
                                `Uploaded File: ${uploadedFiles[0].name}`}
                            </h5>
                            <div className="card-body textupload">
                              {loading && (
                                <div
                                  className="spinner-border text-primary"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              )}
                              <p className="card-text">{textFromImage}</p>
                              {message && (
                                <div
                                  className={`alert ${
                                    message.startsWith("Error")
                                      ? "alert-danger"
                                      : "alert-success"
                                  }`}
                                  role="alert"
                                >
                                  {message}
                                </div>
                              )}
                              <button
                                className="btn btn-primary"
                                onClick={downloadText}
                              >
                                Download Text
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OcrRead;
