import React from "react";
import { Link } from "react-router-dom";

function DeviceTableRow({ index, id, amount, date, isActive }) {
  return (
    <tr className={index % 2 === 0 ? "even" : "odd"}>
      <td className="dtr-control sorting_1" tabIndex={0}>
        {index + 1}
      </td>
      <td>{id}</td>
      <td>${amount}</td>
      <td>{date}</td>
      <td className="text-center">
        <button
          type="button"
          className={`btn btn-soft-${
            isActive ? "success" : "danger"
          } waves-effect waves-light`}
        >
          {isActive ? "Active" : "Deactive"}
        </button>
      </td>
      <td className="text-center">
        <Link
          to="/fax-devices/configuration"
          className="btn btn-outline-secondary btn-sm"
          title="Edit"
          style={{ marginRight: 5 }}
        >
          <i className="fas fa-pencil-alt" title="Edit" />
        </Link>
        <button
          className="btn btn-outline-secondary btn-sm delete"
          title="Delete"
        >
          <i className="fas fa-trash-alt" title="Delete" />
        </button>
      </td>
    </tr>
  );
}

export default DeviceTableRow;
