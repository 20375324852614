import axios from "axios";
import moment from "moment";

export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = token;
  } else {
    delete axios.defaults.headers.common["Authorization"];
    localStorage.removeItem("Authorization");
  }
};
export const getMsgTime = (dateTime) => {
  const messageTime = moment();
  return messageTime.format("DD-MMM h:mm A");

  const currentDate = moment();

  const isCurrentDate = messageTime.isSame(currentDate, "day");

  let formattedDate;

  if (isCurrentDate) {
    //formattedDate = messageTime.format("h:mm A");
    formattedDate = messageTime.format("DD-MM h:mm A");
  } else {
    formattedDate = messageTime.format("DD-MM h:mm A");
  }
  return formattedDate;
};
